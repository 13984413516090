
const environment = process.env.REACT_APP_CONE_API_ENVIRONMENT

function log(params, mode) {
  if (environment === 'sandbox') {
    console[mode].apply(null, ['[SDB]', ...params])
  }
}

export default {
  log: (...params) => log(params, 'log'),
  error: (...params) => log(params, 'error'),
  warn: (...params) => log(params, 'warn')
}
