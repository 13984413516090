import { useState, useCallback, useEffect } from 'react'
import useZendeskWidget from './useZendeskWidget'

const ZENDESK_CHAT_ID = 'webWidget'

const useZendeskWidgetCustomized = (zendeskKey, defer) => {
  const [state, setState] = useState({ isOpen: false, isLoading: false })
  const zenDeskWidget = useZendeskWidget({ zendeskKey, defer })

  const hideButton = () => {
    if (!state.isOpen) {
      (function hide (atemps = 0) {
        if (atemps >= 30) throw new Error('zenDeskWidget is not defined!')
        try { window.zE.hide() } catch (e) { setTimeout(() => hide(atemps + 1), 1000) }
      })(0)
    }
  }

  const open = useCallback(() => {
    const doOpen = () => {
      setState({ ...state, isLoading: true })

      let atemps = 0
      const interval = setInterval(() => {
        if (atemps >= 30) throw new Error('zenDeskWidget is not defined!')

        try {
          if (window.zE?.activate) {
            window.zE.activate()

            if (document.getElementById(ZENDESK_CHAT_ID)) {
              setState({ ...state, isLoading: false, isOpen: true })
              clearInterval(interval)
            }
          }
        } catch (e) {
          console.log('[ERROR] useZenDeskWidgetCustomized:', e.stack())
        }

        atemps++
      }, 1000)
    }

    zenDeskWidget.isCompleted ? doOpen() : zenDeskWidget.load({ onLoad: doOpen })
  }, [zenDeskWidget])

  const close = useCallback(() => {
    zenDeskWidget.load({
      onLoad: () => {
        (function close (atemps = 0) {
          if (atemps >= 30) throw new Error('zenDeskWidget is not defined!')
          try {
            window.zE.hide()
            setState({ ...state, isOpen: false })
          } catch (e) { setTimeout(() => close(atemps + 1), 1000) }
        })(0)
      }
    })
  }, [zenDeskWidget])

  const addEventListener = useCallback(() => {
    const body = document.body
    const handleZenDeskState = (e) => {
      const widgedWasRemoved = e.some((item) => item?.removedNodes[0]?.id === ZENDESK_CHAT_ID)
      if (widgedWasRemoved) setState({ ...state, isOpen: false })
    }
    const zenDeskStateOberserve = new MutationObserver(handleZenDeskState)
    zenDeskStateOberserve.observe(body, {
      attributeOldValue: true,
      attributes: true,
      characterData: true,
      characterDataOldValue: true,
      childList: true,
      subtree: true
    })
  }, [state, setState])

  useEffect(() => { addEventListener() }, [addEventListener])

  return {
    ...zenDeskWidget,
    isOpen: state.isOpen,
    isLoading: state.isLoading,
    open,
    close,
    hideButton
  }
}

export default useZendeskWidgetCustomized
