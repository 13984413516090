import React, { memo } from 'react'
import {
  CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFooter, CNav, CNavItem, CNavLink,
} from '@coreui/react'

import Icon from 'components/icon'
import useSettings from 'templates/context/session-state/useSettings'

const Footer: React.FC = () => {
  const { settings: { whitelabelSettings } } = useSettings()

  const footerLinks = [
    {
      ...(whitelabelSettings?.linkTitle ? {
        title: whitelabelSettings.linkTitle,
        href: whitelabelSettings.linkUrl,
        target: '_blank'
      } : {}),
    },

    { title: 'Documentação da API',
      href: 'https://api-doc.contabilone.com',
      target: '_blank' },

    { title: 'Documentação de uso',
      href: 'https://contabilone.zendesk.com/hc/pt-br',
      target: '_blank' },
  ]

  const { target, title, href } = (footerLinks.length && footerLinks[0]) || {}

  return (
    <CFooter>
      <CNav>
        <CNavItem className="d-sm-none">
          <CDropdown>
            <CDropdownToggle color="ghost">
              <Icon name="cil-hamburger-menu" />
            </CDropdownToggle>
            <CDropdownMenu placement="top">
              {footerLinks
                .filter((_, index) => index > 0)
                .map(({ title, href, target }) => (
                  <CDropdownItem key={`${title}_${href}`} href={href} target={target}>{title}</CDropdownItem>
                ))}
            </CDropdownMenu>
          </CDropdown>
        </CNavItem>

        <CNavItem className="d-sm-none">
          <CNavLink href={href} target={target}>{title}</CNavLink>
        </CNavItem>

        {footerLinks.map(({ title, href, target }) => (
          <CNavItem key={`${title}_${href}`} className="d-none d-sm-block">
            <CNavLink href={href} target={target}>{title}</CNavLink>
          </CNavItem>
        ))}
      </CNav>
    </CFooter>
  )
}

export default memo(Footer)
