import React from 'react'

const SignInConfirmationRedirect: React.FC = () => {
  const params = new URLSearchParams(window.location.search)
  const domain = params.get('state')?.replace('confirmation', 'confirmation-service')
  document.location.href = `${domain}?code=${params.get('code')}`
  return null
}

export default SignInConfirmationRedirect
