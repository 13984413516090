import validators from 'commons/helpers/validations'
import Input from './Input'
import InputTag from './InputTags'

interface IInputEmail {
  multiple?: boolean
  name?: string
  label?: string
  control?: any
  setValue?: any
  placeholder?: string
  messages?: any
  rules?: any
  noLabel?: boolean
}

const InputEmail: React.FC<IInputEmail & any> = ({
  multiple, name, label, control, setValue, placeholder, messages, rules, noLabel, ...rest
}): any => {
  return (
    multiple ? (
      <InputTag
        name={name || 'email'}
        label={label}
        control={control}
        setValue={setValue}
        placeholder={placeholder || 'Digite seu e-mail'}
        messages={messages || ['Digite e-mails separados por: vírgula ou ponto-e-vírgula ou espaço']}
        tags={[]}
        rules={{
          ...rules,
          validate: {
            validations: (emails: Array<string>) => {
              const errors = emails.map((email) => {
                const error = validators.email(email);

                if (error) {
                  return `${error}: "${email}"`;
                }
                return null;
              });

              const setError = errors.find((error) => error);

              return setError;
            }
          }
        }}
        {...rest}
      />
    ) : (
      <Input
        name={name || 'email'}
        label={label}
        type="email"
        control={control}
        placeholder={placeholder || 'Digite seu e-mail'}
        rules={{
          ...rules,
          validate: {
            validations: validators.email
          }
        }}
        {...rest}
      />
    )
  )
}

export default InputEmail
