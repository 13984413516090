import removeCurrencyFormat from '../../../commons/formatApiCurency';
import { checkEmptyObject, removeEmptyValue } from './functionsInvoiceAdapter';

const pagInvoiceFormAdapter = (
  pag: IPagForm
): any => {
  const detPagFormatted = pag?.detPag?.map(({ vPag, tPag, card,
    vTroco, xPag, CNPJPag, ...rest }: any): any => {
    const { CNPJ, CNPJReceb, ...restCard } = card

    const removeCNPJMask = (value: string): string => {
      return value?.replace(/[^0-9]/g, '')
    }
    const cardFormatted = {
      ...(CNPJ !== '' && CNPJ !== undefined ? { CNPJ: removeCNPJMask(CNPJ) } : {}),
      ...(CNPJReceb !== '' && CNPJReceb !== undefined ? { CNPJReceb: removeCNPJMask(CNPJReceb) } : {}),
      ...removeEmptyValue(restCard)
    }

    return {
      tPag,
      ...(tPag === '99' ? { xPag } : {}),
      ...(CNPJPag !== '' && CNPJPag !== undefined ? { CNPJPag: removeCNPJMask(CNPJPag) } : {}),
      ...(removeCurrencyFormat(vPag) !== '' && removeCurrencyFormat(vPag) !== undefined ? { vPag: removeCurrencyFormat(vPag) } : {}),
      ...(!checkEmptyObject(cardFormatted) ? { card: cardFormatted } : {}),
      ...removeEmptyValue(rest)
    }
  });
  return {
    ...(detPagFormatted?.length !== 0 ? { detPag: detPagFormatted } : {}),
  }
}

export default pagInvoiceFormAdapter
