import React from 'react'
import { CLabel } from '@coreui/react'

const Label: React.FC<ILabel> = ({
  children, className, text, after, variant, htmlFor
}) => {
  return (
    <>
      {!after && text && (
        <CLabel className={className} variant={variant} htmlFor={htmlFor}>{text}</CLabel>
      )}
      {children}
      {after && text && (
        <CLabel className={`m-0 ${className}`} variant={variant} htmlFor={htmlFor}>{text}</CLabel>
      )}
    </>
  )
}

export default Label
