import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import useApi from 'templates/context/api-state/useApi'
import useSession from 'templates/context/session-state/useSession'
import LoadingApplication from 'components/loading/LoadingApplication'

const SignOut: React.FC = () => {
  const api = useApi()
  const { clean: cleanSession } = useSession()
  const history = useHistory()

  const doLogout: any = () => api.auth.doLogout()
  const { mutate, data, isSuccess, isError } = useMutation(doLogout)

  useEffect(() => {
    mutate(data as any)
  }, [])

  useEffect(() => {
    if (isSuccess || isError) {
      cleanSession()
      history.push(makeRoute({ path: routePaths.SIGN_IN }))
    }
  }, [isSuccess, isError])

  return <LoadingApplication />
}
export default SignOut
