import React, { memo } from 'react'
import { useHistory, useParams } from 'react-router'

const TemplateModalsContent: React.FC<ITemplateModalContent> = ({ goBack, modals }) => {
  const history = useHistory()
  const { action }: any = useParams()

  const currentModal = modals.find((item: any) => (action === item.action))
  if (!currentModal) return null

  const { component: Component } = currentModal

  const onClose = (): void => history.push(goBack)

  return (
    <Component onClose={onClose} />
  )
}

export default memo(TemplateModalsContent)
