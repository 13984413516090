function isValid(dateObject: Date): boolean {
  return dateObject && dateObject.toString() !== 'Invalid Date'
}

const formatDate = (date: any, format: 'iso' | 'full' | 'date' | 'hour' | 'field' | 'yyyy-mm-dd' |'dd/mm/yyyy' | 'dd-mm' | 'YYYY-MM-DDThh:mm:ss' | 'dd/mm/yyyy - hh:mm:ss' | 'dd-mm-yyyy'): string => {
  if (!isValid(date)) return ''

  switch (format) {
    case 'iso':
      return isValid(date) ? date.toISOString() : ''
    case 'full':
      return isValid(date) ? date.toLocaleString('pt-BR') : ''
    case 'date':
      return isValid(date) ? date.toLocaleString('pt-BR').split(' ')[0] : ''
    case 'hour':
      return isValid(date) ? date.toLocaleString('pt-BR').split(' ')[1] : ''
    case 'field':
    case 'yyyy-mm-dd':
      return isValid(date)
        ? ''
          .concat(date.getFullYear())
          .concat('-')
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
          .concat('-')
          .concat('0'.concat(date.getDate()).slice(-2))
        : ''
    case 'dd/mm/yyyy':
      return isValid(date)
        ? ''
          .concat('0'.concat(date.getDate()).slice(-2))
          .concat('/')
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
          .concat('/')
          .concat(date.getFullYear())
        : ''
    case 'dd-mm-yyyy':
      return isValid(date)
        ? ''
          .concat('0'.concat(date.getDate()).slice(-2))
          .concat('-')
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
          .concat('-')
          .concat(date.getFullYear())
        : ''
    case 'dd-mm':
      return isValid(date)
        ? ''
          .concat('0'.concat(date.getDate()).slice(-2))
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
        : ''
    case 'YYYY-MM-DDThh:mm:ss':
      return isValid(date)
        ? ''
          .concat(date.getFullYear())
          .concat('-')
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
          .concat('-')
          .concat('0'.concat(date.getDate()).slice(-2))
          .concat('T')
          .concat('0'.concat(date.getHours()).slice(-2))
          .concat(':')
          .concat('0'.concat(date.getMinutes()).slice(-2))
          .concat(':')
          .concat('0'.concat(date.getSeconds()).slice(-2))
        : ''

    case 'dd/mm/yyyy - hh:mm:ss':
      return isValid(date)
        ? ''
          .concat('0'.concat(date.getDate()).slice(-2))
          .concat('/')
          .concat('0'.concat(date.getMonth() + 1).slice(-2))
          .concat('/')
          .concat(date.getFullYear())
          .concat(' - ')
          .concat('0'.concat(date.getHours()).slice(-2))
          .concat(':')
          .concat('0'.concat(date.getMinutes()).slice(-2))
          .concat(':')
          .concat('0'.concat(date.getSeconds()).slice(-2))
        : ''

    default: break
  }

  return date.toString() || ''
}

const fieldToDate = (value: string): Date | null => {
  return value ? new Date(value) : null
}

const fieldToISO = (value: string): string => {
  return value ? (new Date(value.concat('+00:00'))).toISOString() : ''
}

// 2020-09-09T09:09:10+03:00
const fieldToGMT = (value: any): string | undefined => {
  const normalizeDate = new Date(value)
  if (!normalizeDate) {
    return undefined
  }

  const currentDate = new Date().toString()
  const GMTRegex = /GMT[+|-]\d{0,4}/
  const currentGMT = currentDate?.match(GMTRegex)?.[0]?.replace('GMT', '')
  const foramttedDate = formatDate(normalizeDate, 'YYYY-MM-DDThh:mm:ss')
  const splitedGMT = currentGMT?.split('')
  splitedGMT?.splice(3, 0, ':')
  const formattedCurrentGMT = splitedGMT?.join('')
  return `${foramttedDate}${formattedCurrentGMT}`
}

export default formatDate
export {
  fieldToDate,
  fieldToISO,
  fieldToGMT
}
