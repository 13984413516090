import parseApiDate from '../../commons/parseApiDate'

import deviceAdapter from '../devices/deviceAdapter'
import serieAdapter from '../series/serieAdapter'
import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'

const configurationAdapter = (data: any): any => {
  const {
    createdAt, updatedAt,
    subsidiary,
    device,
    contingencySerie,
    ...rest
  } = data

  // @todo verify if API send "createdAt" and "updatedAt" by default
  return {
    subsidiary: subsidiary && subsidiaryAdapter(subsidiary),
    device: device && deviceAdapter(device),
    contingencySerie: contingencySerie && serieAdapter(contingencySerie),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default configurationAdapter
