import { checkEmptyObject, DocumentFormatted, removeEmptyValue } from './functionsInvoiceAdapter'

const destInvoiceFormAdapter = (
  data: IDest
): any => {
  const { CNPJ, CPF, indIEDest,
    IE, enderDest, ISUF,
    IM, email, xNome, xFant, idEstrangeiro } = data || {}
  const Document = CNPJ || CPF

  delete data?.enderDest?.stateId
  delete data?.enderDest?.countryId

  const DestFormatted = {
    indIEDest,
    ISUF,
    IM,
    email,
    xNome,
    xFant,
    ...DocumentFormatted(Document),
    ...((indIEDest !== '9' && indIEDest !== '2') && { IE }),
    ...(idEstrangeiro && { idEstrangeiro })
  }

  const dest = { ...removeEmptyValue(DestFormatted),
    ...(!checkEmptyObject(removeEmptyValue(enderDest))
      ? { enderDest: removeEmptyValue(enderDest) } : {}) }

  return Object.keys(dest)?.length === 1 ? {} : dest
}

export default destInvoiceFormAdapter
