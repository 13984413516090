const accountingCodeUpdateAdapter = (data: IAccountingCodes): any => {
  const {
    ...rest
  } = data

  const payload = {
    ...rest,
  }

  return payload
}

export default accountingCodeUpdateAdapter
