import React, { useCallback } from 'react'
import { useController } from 'react-hook-form'

import PasswordInputField from './fields/PasswordInputField'

const InputPassword: React.FC<IController & IInputPasswordField> = ({
  control,
  rules,
  name,
  onChange,
  defaultValue,
  ...props
}) => {
  const { field: { ref, ...field }, fieldState, formState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || ''
  })

  const handleChange = useCallback((event: any): void => {
    field.onChange(event)
    onChange && onChange(event)
  }, [field, onChange])

  return (
    <div>
      <PasswordInputField
        valid={formState.isValid}
        error={fieldState.error?.message}
        innerRef={ref}
        {...field}
        {...props}
        onChange={handleChange}
      />
    </div>
  )
}

export default InputPassword
