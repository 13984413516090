const deviceFormAdapter = (data: IDeviceForm): any => {
  const formattedData = Object.entries(data)?.reduce((acc, item: any) => {
    const [key, value] = item

    if (!value) {
      return acc
    }
    return { ...acc, [key]: value }
  }, {})

  return {
    ...formattedData,
  }
}

export default deviceFormAdapter
