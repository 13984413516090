import config from '../../@config'
import RequestApi from '../../services/api'
import businessAccountsList from '../lists/businessAccountsList'
import accountingCodeUpdateAdapter from './accountingCodeUpdateAdapter'
import businessAccountsAdapter from './businessAccountsAdapter'
import accountingCodeFormAdapter from './accountingCodeFormAdapter'

const endpoints = config.ENDPOINTS.accountingCodes
const companyAccountingCodes = config.ENDPOINTS.diaries

const BusinessAccounts = (api: IAPI): IBusinessAccountsAPI => {
  function adapter(response: any): IAccountingCode {
    return businessAccountsAdapter(response)
  }

  async function getList(accountPlanID: string, params: any): Promise<IList<IAccountingCode>> {
    return RequestApi.listAdapter<IAccountingCode>(
      (
        await api.doRequest('get', endpoints.accountingCodes.replace('{ACCOUNTING_CHART}', accountPlanID), params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getReferentialList(params: any): Promise<IList<IAccountingCode>> {
    return RequestApi.listAdapter<IAccountingCode>(
      (
        await api.doRequest('get', endpoints.referentialAccountingCode, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getListByCompanyId(params: any): Promise<IList<IAccountingCode>> {
    return RequestApi.listAdapter<IAccountingCode>(
      (
        await api.doRequest('get', companyAccountingCodes.accountingCodes, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IAccountingCode> {
    return RequestApi.itemAdapter<IAccountingCode>(
      await api.doRequest('get', `${endpoints.accountingCode.replace('{ACCOUNTING_CODE_ID}', id)}`),
      (data: any) => adapter(data)
    )
  }

  async function create(accountPlanID: string, data: IAccountingCodes): Promise<string> {
    return (await api.doRequest('post', endpoints.accountingCodes.replace('{ACCOUNTING_CHART}', accountPlanID),
      accountingCodeFormAdapter(data))).message
  }

  async function update(id: string, data: IAccountingCodes): Promise<IAccountingCode> {
    return RequestApi.itemAdapter<IAccountingCode>(
      await api.doRequest('patch', `${endpoints.accountingCode.replace('{ACCOUNTING_CODE_ID}', id)}`, accountingCodeUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IAccountingCode> {
    return RequestApi.itemAdapter<IAccountingCode>(
      await api.doRequest('patch', `${endpoints.accountingCode.replace('{ACCOUNTING_CODE_ID}', id)}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.accountingCode.replace('{ACCOUNTING_CODE_ID}', id)}`)).message
  }

  return {
    getList,
    getById,
    getReferentialList,
    getListByCompanyId,
    create,
    update,
    updateEnabled,
    remove,
    lists: businessAccountsList(api)

  }
}

export default BusinessAccounts
