import cleanNumber from '../../commons/cleanNumber'

const subsidiaryAddressFormAdapter = (
  data: ISubsidiaryAddressForm
): ISubsidiaryAddressForm => {
  const {
    address: {
      cep,
      ...rest
    }
  } = data

  return {
    address: {
      cep: cleanNumber(cep, /\d+/g),
      ...rest
    }
  }
}

export default subsidiaryAddressFormAdapter
