import React from 'react'

interface ISpinner {
  color?: string
  className?: string
  style?: any
}

const Spinner: React.FC<ISpinner> = ({ color, className, style }) => {
  return (
    <div
      className={`sk-flow d-flex align-items-center ${className}`}
      style={{ width: '20px', height: '20px', ...style }}
    >
      <div className={`sk-flow-dot ${color ? `bg-${color}` : ''}`} />
      <div className={`sk-flow-dot ${color ? `bg-${color}` : ''}`} />
      <div className={`sk-flow-dot ${color ? `bg-${color}` : ''}`} />
    </div>
  )
}

export default Spinner
