import { useState, useCallback } from 'react'
import { scriptLoad } from './helpers'

const ZENDESK_SCRIPT_SRC = 'https://static.zdassets.com/ekr/snippet.js?key={{key}}'
const ZENDESK_SCRIPT_ID = 'ze-snippet'

window.useZendeskWidgetState = { loadCalled: false, completed: false, error: false }

const useZendeskWidget = ({ zendeskKey, defer }) => {
  const [state, setState] = useState(window.useZendeskWidgetState)

  const handleSetState = useCallback((values) => {
    window.useZendeskWidgetState = { ...state, ...values }
    setState(window.useZendeskWidgetState)
  }, [state, setState])

  const load = useCallback(({ onLoad }) => {
    if (window.useZendeskWidgetState.loadCalled) {
      onLoad(state)
      return
    }

    handleSetState({ loadCalled: true })

    const src = ZENDESK_SCRIPT_SRC.replace('{{key}}', zendeskKey)

    scriptLoad({ id: ZENDESK_SCRIPT_ID, src, defer })
      .then(() => {
        window.useZendeskWidgetState = { ...state, completed: true }
        handleSetState(window.useZendeskWidgetState)
        onLoad(state)
      })
      .catch(() => handleSetState({ completed: true, error: true }))
  }, [state, defer, zendeskKey, handleSetState])

  return {
    isCompleted: state.completed,
    isError: state.error,
    load
  }
}

export default useZendeskWidget
