import config from '../../@config'
import RequestApi from '../../services/api'
import accountAdapter from './accountAdapter'
import accountFormAdapter from './accountFormAdapter'
import accountFormUpdateAdapter from './accountFormUpdateAdapter'

const endpoints = config.ENDPOINTS.accounts

const Accounts = (api: IAPI): IAccountsAPI => {
  function adapter(response: any): IAccount {
    return accountAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IAccount>> {
    const accParams = { ...params, sort: 'asc', orderBy: ['name'] }
    return RequestApi.listAdapter<IAccount>(
      (
        await api.doRequest('get', endpoints.accounts, accParams)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IAccount> {
    return RequestApi.itemAdapter<IAccount>(
      await api.doRequest('get', `${endpoints.accounts}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IAccountForm): Promise<string> {
    return (await api.doRequest('post', endpoints.accounts, accountFormAdapter(data))).message
  }

  async function update(id: string, data: IAccountForm): Promise<IAccount> {
    return RequestApi.itemAdapter<IAccount>(
      await api.doRequest('patch', `${endpoints.accounts}/${id}`, accountFormUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IAccount> {
    return RequestApi.itemAdapter<IAccount>(
      await api.doRequest('patch', `${endpoints.accounts}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.accounts}/${id}`)).message
  }

  async function generateToken(data: {accountId: string}): Promise<string> {
    return (await api.doRequest('post', endpoints.generateToken, data)).message
  }

  async function activateToken(id: string, token: string): Promise<string> {
    return (await api.doRequest('patch', `${endpoints.activateToken.replace('{ID}', id)}`, ({ newToken: token }))).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove,
    generateToken,
    activateToken
  }
}

export default Accounts
