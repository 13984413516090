import React from 'react'
import { CSidebarNavTitle, CSidebarNavItem, CSidebarNavDropdown, CButton } from '@coreui/react'
import Icon from 'components/icon'

interface IBadge {
  size: string
  color: string
  text?: string
}
interface IItem {
  name?: string
  to?: any
  icon?: any
  badge?: IBadge
  childrens?: any
  disabled?: boolean
}
interface IMenuItem {
  items: Array<IItem>
  handleClick?: any
  isChildren?: boolean
}

const Menuitems: React.FC<IMenuItem> = ({ items, handleClick, isChildren }) => (
  <>
    {items.map((item) => {
      return (
        (item.childrens && (
          <CSidebarNavDropdown className="c-sidebar-nav-dropdown" key={`${item.name}_${item.to}`} {... item}>
            <Menuitems isChildren items={item.childrens} />
          </CSidebarNavDropdown>
        ))
        || (item.to && item.icon && !isChildren && (
          <CButton key={`${item.name}_${item.to}`} variant="ghost" className={`p-0 m-0 ${!item.to?.length && 'navLink-disabled'}`} style={{ minHeight: '48px' }} onClick={() => { handleClick() }}>
            <CSidebarNavItem
              className="text-truncate"
              key={`${item.name}_${item.to}`}
              {...item}
              icon={<Icon name={item.icon} customClasses="c-sidebar-nav-icon" />}
            />
          </CButton>
        ))
        || (item.to && item.icon && isChildren && (
          <CSidebarNavItem
            className="text-truncate"
            key={`${item.name}_${item.to}`}
            icon={<Icon name={item.icon} customClasses="c-sidebar-nav-icon" />}
            {...item}
          />
        )
        ) || (
          item.to && (
          <CSidebarNavItem
            className={`text-truncate ${!item.to?.length && 'navLink-disabled'}`}
            key={`${item.name}_${item.to}`}
            {...item}
          />
          )
        ) || (
        <CSidebarNavTitle
          key={`${item.name}_${item.to}`}
          className="text-truncate mt-2 pb-1"
        >{item.name}
        </CSidebarNavTitle>
        )
      )
    })}
  </>
)

export default Menuitems
