import parseApiDate from '../../../commons/parseApiDate'

const merchantTypeAdapter = (data: any): IMerchantType => {
  const { updatedAt, createdAt, ...rest } = data

  return {
    ...rest,
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
  }
}

export default merchantTypeAdapter
