import parseApiDate from '../../../commons/parseApiDate'

import countyAdapter from './countyAdapter'
import taxInfoAdapter from './taxInfoAdapter'

const countyTaxAdapter = (data: any): ICountyTax => {
  const {
    updatedAt, createdAt, startDate, endDate, county,
    typeTaxPayer, taxPayer, status,
    ...rest
  } = data

  return {
    ...rest,
    taxPayer: taxPayer && taxInfoAdapter(taxPayer),
    typeTaxPayer: typeTaxPayer && taxInfoAdapter(typeTaxPayer),
    status: status && taxInfoAdapter(status),
    county: county && countyAdapter(county),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default countyTaxAdapter
