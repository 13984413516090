import { fieldToISO } from 'commons/helpers/formatter/formatDate'

const diaryFormAdapter = (data: IDiaryForm): IDiaryForm => {
  const { bookkeepingType, cnpj, accountId, companyId, startDate, endDate, fiscalYearEndDate,
    diaryNumber, signatoryIds, signatoriesSelected, ...rest } = data

  return {
    ...rest,
    ...(companyId ? { companyId } : { accountId }),
    ...(cnpj ? { cnpj } : { }),
    startDate: fieldToISO(startDate),
    endDate: fieldToISO(endDate),
    fiscalYearEndDate: fieldToISO(fiscalYearEndDate),
    bookkeepingType: String(bookkeepingType),
    diaryNumber: typeof diaryNumber === 'string' ? parseFloat(diaryNumber) : diaryNumber,
    ...(signatoryIds?.length ? { signatoryIds } : []),
  }
}

export default diaryFormAdapter
