import parseApiDate from '../../commons/parseApiDate'

import whitelabelAdapter from '../whitelabels/whitelabelAdapter'
import companyAdapter from '../companies/companyAdapter'
// import userAdapter from '../users/userAdapter'

const accountAdapter = (data: any): IAccount => {
  const {
    createdAt, updatedAt,
    companies,
    whitelabel,
    ...rest
  } = data

  return {
    whitelabel: whitelabel && whitelabelAdapter(whitelabel),
    companies: companies && companies.map(companyAdapter),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default accountAdapter
