import { checkEmptyObject } from './form/functionsInvoiceAdapter'
import ideInvoiceFormAdapter from './form/ideInvoiceFormAdapter'
import pagInvoiceFormAdapter from './form/pagInvoiceFormAdapter'
import emitInvoiceFormAdapter from './form/emitInvoiceFormAdapter'
import cobrInvoiceFormAdapter from './form/cobrInvoiceFormAdapter'
import destInvoiceFormAdapter from './form/destInvoiceFormAdapter'
import totalInvoiceFormAdapter from './form/totalInvoiceFormAdapter'
import autXMLInvoiceFormAdapter from './form/autXMLInvoiceFormAdapter'
import transpInvoiceFormAdapter from './form/transpInvoiceFormAdapter'
import compraInvoiceFormAdapter from './form/compraInvoiceFormAdapter'
import entregaInvoiceFormAdapter from './form/entregaInvoiceFormAdapter'
import productInvoiceFormAdapter from './form/productInvoiceFormAdapter'
import infAdicInvoiceFormAdapter from './form/infAdicInvoiceFormAdapter'
import retiradaInvoiceFormAdapter from './form/retiradaInvoiceFormAdapter'
import infIntermedInvoiceFormAdapter from './form/infIntermedInvoiceFormAdapter'
import exportaInvoiceFormAdapter from './form/exportaInvoiceFormAdapter'

const invoiceFormAdapter = (data: any): IInvoiceForm => {
  const retiradaFormatted = retiradaInvoiceFormAdapter(data?.retirada)
  const entregaFormatted = entregaInvoiceFormAdapter(data?.entrega)
  const infIntermedFormatted = infIntermedInvoiceFormAdapter(data?.infIntermed)
  const pagFormatted = pagInvoiceFormAdapter(data?.pag)
  const infAdicFormatted = infAdicInvoiceFormAdapter(data?.infAdic)
  const cobrFormatted = cobrInvoiceFormAdapter(data?.cobr)
  const emitFormatted = emitInvoiceFormAdapter(data?.emit)
  const destFormatted = destInvoiceFormAdapter(data?.dest)
  const totalFormatted = totalInvoiceFormAdapter(data?.total)
  const transpFormatted = transpInvoiceFormAdapter(data?.transp)
  const exportaFormatted = exportaInvoiceFormAdapter(data?.exporta)

  const payload: IInvoiceForm = {
    ...(data?.emit?.accountId !== '' ? { accountId: data?.emit?.accountId } : {}),
    ...(data?.rulesEngine !== '' ? { rulesEngine: data?.rulesEngine } : {}),
    ...(data?.remoteId ? { remoteId: data?.remoteId } : {}),
    NFe: {
      infNFe: {
        ide: ideInvoiceFormAdapter(data?.ide),
        ...(!checkEmptyObject(emitFormatted) ? { emit: emitFormatted } : {}),
        ...(!checkEmptyObject(destFormatted) ? { dest: destFormatted } : {}),
        ...(!checkEmptyObject(retiradaFormatted) ? { retirada: retiradaFormatted } : {}),
        ...(!checkEmptyObject(entregaFormatted) ? { entrega: entregaFormatted } : {}),
        ...(data?.autXML?.length !== 0 ? { autXML: autXMLInvoiceFormAdapter(data?.autXML) } : {}),
        ...(!checkEmptyObject(pagFormatted) ? { pag: pagFormatted } : {}),
        ...(!checkEmptyObject(transpFormatted) ? { transp: transpFormatted } : {}),
        ...(!checkEmptyObject(infIntermedFormatted)
          ? { infIntermed: infIntermedFormatted } : {}),
        ...(!checkEmptyObject(infAdicFormatted) ? { infAdic: infAdicFormatted } : {}),
        ...(!checkEmptyObject(cobrFormatted) ? { cobr: cobrFormatted } : {}),
        ...(productInvoiceFormAdapter(data?.det).length > 0 ? {
          det: productInvoiceFormAdapter(data?.det) } : {}),
        ...(!checkEmptyObject(totalFormatted) ? { total: totalFormatted } : {}),
        ...(compraInvoiceFormAdapter(data?.compra)),
        ...(!checkEmptyObject(exportaFormatted) ? { exporta: exportaFormatted } : {})
      },
    }
  }
  return payload
}

export default invoiceFormAdapter
