import config from '@api/src/@config'
import RequestApi from '../../services/api'
import signatoryAdapter from './signatoryAdapter'

const endpoints = config.ENDPOINTS.signatory

const Signatories = (api: IAPI): ISignatoryAPI => {
  function adapter(response: any): ISignatory {
    return signatoryAdapter(response)
  }

  async function create(params: ISignatory): Promise<string> {
    return (await api.doRequest('post', endpoints.list, params)).message
  }

  async function list(params: ISignatoriesListsParams): Promise<IList<ISignatory>> {
    return RequestApi.listAdapter<ISignatory>(
      await api.doRequest('get', endpoints.list, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ISignatory> {
    return RequestApi.itemAdapter<ISignatory>(
      await api.doRequest('get', `${endpoints.list}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (
      await api.doRequest('remove', `${endpoints.list}/${id}`)
    ).message
  }

  async function update(id: string, data: any): Promise<ISignatory> {
    return RequestApi.itemAdapter<ISignatory>(
      await api.doRequest('patch', `${endpoints.list}/${id}`, data),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ISignatory> {
    return RequestApi.itemAdapter<ISignatory>(
      await api.doRequest('patch', `${endpoints.list}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  return {
    list,
    getById,
    remove,
    update,
    create,
    updateEnabled
  }
}

export default Signatories
