import parseApiDate from '../../../commons/parseApiDate'

const acquirerParticipantAdapter = (data: any): IAcquirerParticipant => {
  const { updatedAt, createdAt, ...rest } = data

  return {
    ...rest,
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default acquirerParticipantAdapter
