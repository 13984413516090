import config from '../../@config'
import RequestApi from '../../services/api'
import participantAdapter from './participantAdapter'
import participantFormAdapter from './participantFormAdapter'

const endpoints = config.ENDPOINTS.participants

const Participants = (api: IAPI): IParticipantsAPI => {
  function adapter(response: any): IParticipant {
    return participantAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IParticipant>> {
    const subParams = { ...params, sort: 'asc', orderBy: ['cnpj', 'ie'] }
    return RequestApi.listAdapter<IParticipant>(
      await api.doRequest('get', endpoints.participants, subParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IParticipant> {
    return RequestApi.itemAdapter<IParticipant>(
      await api.doRequest('get', `${endpoints.participants}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IParticipantForm): Promise<{message: string, id: string}> {
    delete data.tmp
    const result = (await api.doRequest('post', endpoints.participants, participantFormAdapter(data)))

    return { message: result.message, id: result.id }
  }

  async function update(id: string, data: IParticipantForm): Promise<IParticipant> {
    const payload = participantFormAdapter(data, true)

    delete payload.companyId
    return RequestApi.itemAdapter<IParticipant>(
      await api.doRequest('patch', `${endpoints.participants}/${id}`, payload),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IParticipant> {
    return RequestApi.itemAdapter<IParticipant>(
      await api.doRequest('patch', `${endpoints.participants}/${id}`, { identification: { enabled } }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.participants}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Participants
