import performRequest from './performRequest'

export default class Request {
  static get(url: string, data?: any, headers?: { [key: string]: string }): Promise<any> {
    const queryString = new URLSearchParams(data).toString()
    const getUrl = `${url}${queryString ? '?' : ''}${queryString}`

    return performRequest(getUrl, { method: 'GET', headers })
  }

  static post(url: string, data?: any, headers?: { [key: string]: string }): Promise<any> {
    return performRequest(url, { method: 'POST', headers, body: JSON.stringify(data) })
  }

  static put(url: string, data?: any, headers?: { [key: string]: string }): Promise<any> {
    return performRequest(url, { method: 'PUT', headers, body: JSON.stringify(data) })
  }

  static patch(url: string, data?: any, headers?: { [key: string]: string }): Promise<any> {
    return performRequest(url, { method: 'PATCH', headers, body: JSON.stringify(data) })
  }

  static remove(url: string, data?: any, headers?: { [key: string]: string }): Promise<any> {
    return performRequest(url, { method: 'DELETE', headers })
  }
}
