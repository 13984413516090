import RequestApi from '@api/src/services/api'
import config from '../../@config'

const endpoints = config.ENDPOINTS.ruleBase

const RuleBase = (api: IAPI): IRuleBaseAPI => {
  async function create(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.ruleBase, data)).message
  }

  async function update(currentAccountId: string, ruleBaseId: string, data: any): Promise<any> {
    const { whitelabelId, accountId, companyId, subsidiaryId, ...rest } = data
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.ruleBase}/${currentAccountId}/${ruleBaseId}`, rest),
      (data: any) => data
    )
  }

  async function remove(currentAccountId: string, ruleBaseId: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.ruleBase}/${currentAccountId}/${ruleBaseId}`)).message
  }

  return {
    create,
    update,
    remove
  }
}

export default RuleBase
