const removeEmptyValue = (obj: any): any => {
  if (!obj) {
    return {}
  }
  return Object.fromEntries(Object.entries(obj)?.filter(([_, value]: any) => {
    return !!value?.length || ((typeof value === 'object' && value !== null) && Object.keys(value).length !== 0)
  }))
}

const removeSpecificProdEmptyValue = (obj: any): any => {
  if (!obj || (Array.isArray(obj) && !obj?.length)) {
    return {}
  }
  return Object.fromEntries(Object.entries(obj)?.filter(([_, value]: any) => {
    if (!value || (Array.isArray(value) && !value?.length)) return false
    return !!value || (typeof value === 'object' && Object.keys(value).length !== 0)
  }))
}

const checkEmptyObject = (obj: any): boolean => {
  if (!obj) return true
  if (obj && Object.keys(obj)?.length > 0) {
    return !Object.values(obj).some((item) => item !== undefined && item !== null)
  }
  return obj && Object.keys(obj)?.length === 0
}

const DocumentFormatted = (value: string): any => {
  const documentValue = value ? value?.replace(/[^0-9]/g, '') : ''
  return documentValue?.length >= 14 ? { CNPJ: documentValue } : { CPF: documentValue }
}

const formatvPMCValue = (value: any): any => {
  if (!value) return ''
  if (typeof value === 'string') {
    return value?.replace(',', '.')
  }
  return value
}

export {
  removeEmptyValue,
  checkEmptyObject,
  DocumentFormatted,
  formatvPMCValue,
  removeSpecificProdEmptyValue,
}
