import config from '@api/src/@config'
import RequestApi from '../../services/api'
import legalNatureDescAdapter from './adapters/legalNatureDescAdapter'

const endpoints = config.ENDPOINTS.list.fiscalLists

export default (api: IAPI): IFiscalListsAPI => {
  async function getLegalNatureList(search?: string): Promise<IList<ILegalNatureDesc>> {
    const params = { page: 1, limit: 20, ...(search ? { q: search } : {}) }
    return RequestApi.listAdapter<ILegalNatureDesc>(
      await api.doRequest('get', endpoints.legalNature, params) as IAPIResponseList,
      legalNatureDescAdapter
    )
  }

  async function getCnaes(params: ICnaeFilterParams): Promise<IList<ITaxInfo>> {
    const pagedParams = { ...params, page: 1, limit: 20 }

    return RequestApi.listAdapter<ITaxInfo>(
      await api.doRequest('get', endpoints.cnaes, pagedParams) as IAPIResponseList,
      (data: any) => data
    )
  }

  return {
    getCnaes,
    getLegalNatureList
  }
}
