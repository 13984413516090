import config from '../../@config'
import userAdapter from './userAdapter'
import userFormAdapter from './userFormAdapter'
import RequestApi from '../../services/api'

const endpoints = config.ENDPOINTS.users

const Users = (api: IAPI): IUsersAPI => {
  function adapter(response: any): IUser {
    return userAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IUser>> {
    const uParams = { ...params, sort: 'asc', orderBy: ['email'] }
    return RequestApi.listAdapter<IUser>(
      await api.doRequest('get', endpoints.users, uParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IUser> {
    return RequestApi.itemAdapter<IUser>(
      await api.doRequest('get', `${endpoints.users}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function invite(data: IInviteUserForm): Promise<string> {
    return (
      await api.doRequest('post', `${endpoints.invite}`, data)
    ).message
  }

  async function update(id: string, data: any): Promise<IUser> {
    return RequestApi.itemAdapter<IUser>(
      await api.doRequest('patch', `${endpoints.users}/${id}`, userFormAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IUser> {
    return RequestApi.itemAdapter<IUser>(
      await api.doRequest('patch', `${endpoints.users}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function updateImage(id: string, picture: string): Promise<IUpdateImageResponse> {
    return RequestApi.itemAdapter<IUpdateImageResponse>(
      await api.doRequest('patch', `${endpoints.updatePicture}/${id}`, {
        pictureBase64: picture
      }),
      (message: any, data: IUpdateImageResponse): IUpdateImageResponse => (data)
    )
  }

  async function updatePassword(
    id: string, password: string, newPassword: string
  ): Promise<string> {
    return (
      await api.doRequest('patch', `${endpoints.updatePassword}`, {
        password,
        newPassword
      })
    ).message
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.users}/${id}`)).message
  }

  async function removeMembership(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.membership}/${id}`)).message
  }

  return {
    getList,
    getById,
    invite,
    update,
    updateEnabled,
    remove,
    removeMembership,
    updatePassword,
    updateImage
  }
}

export default Users
