import parseApiDate from '../../commons/parseApiDate'

import companyAdapter from '../companies/companyAdapter'
import addressAdapter from '../lists/adapters/addressAdapter'
import economicActivityAdapter from '../lists/adapters/economicActivityAdapter'
import legalNatureAdapter from '../lists/adapters/legalNatureAdapter'
import stateTaxAdapter from '../lists/adapters/stateTaxAdapter'
import countryTaxAdapter from '../lists/adapters/countryTaxAdapter'
import countyTaxAdapter from '../lists/adapters/countyTaxAdapter'
import taxInfoAdapter from '../lists/adapters/taxInfoAdapter'

const participantAdapter = (dataParticipant: any): IParticipant => {
  const data = Array.isArray(dataParticipant) ? dataParticipant[0] : dataParticipant

  const {
    address, participant, openingDate, closingDate,
    cnaes, legalNature, sitEspecial, partScp,
    countryTaxes,
    stateTaxes, countyTaxes, sit, specialSituationDate,
    company, createdAt, updatedAt, model, ...rest
  } = data

  return data?.id && {
    ...rest,
    participant,
    company: company && companyAdapter(company),
    address: address && addressAdapter(address),

    cnaes: cnaes
      ? cnaes.map(economicActivityAdapter)
      : [],
    legalNature: legalNature
      ? legalNature.map(legalNatureAdapter)
      : [],
    countryTaxes: countryTaxes
      ? countryTaxes.map(countryTaxAdapter)
      : [],
    stateTaxes: stateTaxes
      ? stateTaxes.map(stateTaxAdapter)
      : [],
    countyTaxes: countyTaxes
      ? countyTaxes.map(countyTaxAdapter)
      : [],
    sitEspecial: sitEspecial && taxInfoAdapter(sitEspecial),
    partScp: partScp && taxInfoAdapter(partScp),
    specialSituationDate: parseApiDate(specialSituationDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    openingDate: parseApiDate(openingDate),
    closingDate: parseApiDate(closingDate)
  }
}

export default participantAdapter
