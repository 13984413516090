import React from 'react'
import { CImg } from '@coreui/react'

const Avatar: React.FC<IAvatar> = ({
  picture, alt, paddingSize, className, style
}: any): any => {
  const sizeClass = paddingSize ? `avatar-padding-${paddingSize}` : ''

  return (
    <CImg
      style={style}
      className={`img-thumbnail rounded-circle c-avatar-img ${sizeClass} ${className || ''}`}
      src={picture}
      alt={alt}
    />
  )
}

export default Avatar
