import { useState } from 'react'
import ToasterStateContext from './ToasterStateContext'

const ToasterStateProvider: any = ({ children }: any) => {
  const [toaster, setToaster] = useState<Array<IToast>>([])

  const addToasts = (toasts: IToast): void => {
    if (toasts) {
      setToaster([])
      const randomId = new Date().getTime()
      const dataWithId = { id: randomId, ...toasts }
      setToaster((old) => [...old, dataWithId])
    }
  }

  const clean = (): void => {
    setToaster([])
  }

  const contextValue: IToasterStateContext = {
    toaster,
    addToasts,
    clean
  }

  return (
    <ToasterStateContext.Provider value={contextValue}>
      {children}
    </ToasterStateContext.Provider>
  )
}

export default ToasterStateProvider
