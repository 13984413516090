import CheckDigit from '../check-digit'

export default function validateCNPJ (value) {
  const invalid = '00.000.000/0000-00'
  const length = 14
  const digit = value.replace(/[^\d]+/g, '')
  const checkDigit = new CheckDigit()
  if (value === invalid){
    return false
  }
  return digit.length === length && checkDigit.isValid(digit)
}
