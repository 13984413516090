const exportaInvoiceFormAdapter = (
  exporta: {
    UFSaidaPais?: string
    xLocExporta?: string
    xLocDespacho?: string
  }
): any => {
  const isEmptyObject = Object.values(exporta)?.every((item) => !item)
  if (isEmptyObject) {
    return {}
  }

  return exporta
}

export default exportaInvoiceFormAdapter
