import { CFormGroup } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'

import IconWithTooltip from 'components/icon-with-tooltip'
import Label from '../Label'
import Message from '../Message'

import Text from '../Text'
import { groupBadgeStyles, groupStyles, styles } from './select-styles'
import getTheme from './select-theme'

const formatGroupLabel = (data: any): any => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span data-style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const getValues = (
  value: string
    | ISelectOption
    | Array<string>
    | Array<ISelectOption>,
  options: Array<ISelectOption>
): Array<any> => {
  const theValue = Array.isArray(value) ? value : [value]

  if (theValue.length && (theValue[0] as ISelectOption)?.label) {
    return theValue
  }

  return options?.reduce((acc, option: ISelectOption) => {
    if (option.options) {
      return [...acc, ...getValues(value, option.options)]
    }

    if (option.value && (theValue as Array<string>).includes(option.value)) {
      return [...acc, option]
    }

    return acc
  }, [] as Array<ISelectOption>)
}

const SelectField: React.FC<ISelectField> = ({
  noMargin, label, name, value, invalid, multiple, disabled,
  options = [], messages, error, onChange, placeholder, inputValue, filterOption,
  isOptionDisabled, classNameDisabled, noMarginDisabled, onInputChange, isClearSelect,
  tooltipContent, menuPlacement = 'auto', classNameLabel, ...inputProps
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>([])
  const [currentValue, setCurrentValue] = useState<any>('')
  const [alreadyUseClear, setAlreadyUseClear] = useState(false)

  const handleChange = (data: any, action: any): void => {
    const { action: currentAction } = action
    if (currentAction === 'clear') {
      setCurrentValue('')
      setAlreadyUseClear(true)
    } else {
      setCurrentValue(data)
    }

    if (currentAction === 'select-option') {
      setSelectedOptions(data)
    }

    const event = {
      target: {
        name,
        value: multiple
          ? data.map(({ value }: any) => value)
          : data?.value
      },
      ...action,
    }

    onChange(event)
  }

  const mergedOptions = Array.isArray(selectedOptions)
    ? [...options, ...selectedOptions] : [...options, selectedOptions]

  const mergedOptionsWithGroup = mergedOptions.reduce((unique, item) => {
    if (item.options) {
      return [...unique, ...item.options]
    }
    return [...unique, item]
  }, [])

  const uniqueOptions = mergedOptionsWithGroup.reduce((unique : any, item : any) => {
    if (unique.some(({ value }: any) => value === item.value)) {
      return unique
    }
    return [...unique, item]
  }, [])

  const theValue = currentValue ? getValues(currentValue, uniqueOptions) : [currentValue]

  const theValueText = Array.isArray(theValue) ? theValue[0] : theValue

  const noOptionsMessage = inputProps?.noOptionsMessage || (() => 'Nenhuma opção encontrada')
  const isLoadingMessage = (): string | null => (inputProps.isLoading ? 'Carregando...' : null)

  useEffect(() => {
    if (value !== undefined && value !== null && !alreadyUseClear) {
      setCurrentValue(value)
    }
  }, [value])

  useEffect(() => {
    if (isClearSelect) {
      setCurrentValue('')
      setAlreadyUseClear(true)
    }
  }, [onChange])

  return (
    <CFormGroup className={`${noMargin ? 'm-0' : ''}`}>
      <Label text={label} htmlFor={name} className={classNameLabel}>
        {tooltipContent && <IconWithTooltip content={tooltipContent} />}
        {disabled && (
          <>
            <Text
              noMargin={noMarginDisabled}
              classNameFormGroup={classNameDisabled}
              value={theValueText?.label || theValueText}
            />
            {error && <small className="text-danger">{error}</small>}
          </>
        )}
        {!disabled && (
          <ReactSelect
            name={name}
            isClearable
            inputValue={inputValue}
            placeholder={placeholder || 'Selecione'}
            isDisabled={disabled}
            value={multiple ? theValue : theValue[0]}
            isMulti={multiple}
            options={options}
            styles={styles(invalid)}
            formatGroupLabel={formatGroupLabel}
            theme={(theme: any) => ({ ...theme, colors: getTheme(theme.colors) })}
            className={invalid ? 'is-invalid' : ''}
            onChange={handleChange}
            filterOption={filterOption}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={isLoadingMessage}
            isOptionDisabled={isOptionDisabled}
            onInputChange={(e) => {
              setAlreadyUseClear(false)
              onInputChange && onInputChange(e)
            }}
            menuPlacement={menuPlacement}
            {...inputProps}
          />
        )}
      </Label>
      <Message messages={messages} error={error} />
    </CFormGroup>
  )
}

export default SelectField
