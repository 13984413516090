import STATISTICS_INVOICE from '../../@mocks/statistics-invoice.mock'
import STATISTICS_AMOUNTS from '../../@mocks/statistics-amounts.mock'

const Statistics = (api: IAPI): IStatsAPI => {
  async function getWorkspaceAmounts(params: IFilterParams): Promise<IStatsWorkspaceAmounts> {
    return STATISTICS_AMOUNTS
  }

  async function getInvoiceStats(params: IInvoiceStatisticsParams): Promise<IStatsInvoice> {
    return STATISTICS_INVOICE(params)
  }

  return {
    getInvoiceStats,
    getWorkspaceAmounts
  }
}

export default Statistics
