import React from 'react'
import { CInvalidFeedback } from '@coreui/react'

const Message: React.FC<IMessage> = ({ error, messages }) => {
  return (
    <>
      {messages?.map((message) => (
        <div key={`${message}`} className="mb-0"><small>{message}</small></div>
      ))}
      <CInvalidFeedback>{error}</CInvalidFeedback>
    </>
  )
}

export default Message
