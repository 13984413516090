import React from 'react'
import { CBreadcrumb } from '@coreui/react'

import Button from 'components/form/button'
import BreadcrumbItem from './BreadcrumbItem'

interface IBreadcrumb {
  items: Array<{
    to?: string
    className?: string
    title?: string
    strong?: boolean
  }>
  onClickHome?:React.MouseEventHandler<HTMLElement>
}

const prodEnviroment = process.env.REACT_APP_CONE_API_ENVIRONMENT === 'production'

const Breadcrumb: React.FC<IBreadcrumb> = ({ items, onClickHome }) => {
  return (
    <CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">
      <BreadcrumbItem>
        <Button
          tooltip="Início"
          color="ghost"
          icon="cil-home"
          to={prodEnviroment ? '/invoices' : '/dashboard'}
          onClick={onClickHome}
        />
      </BreadcrumbItem>
      {items?.[0]?.title && items.map((crumb: IBreadcrumbItem, index: any) => (
        <BreadcrumbItem key={index} {...crumb} />
      ))}
    </CBreadcrumb>
  )
}

export default Breadcrumb
