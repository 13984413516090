import COneAPIClient from '@api'
import { useContext } from 'react'
import { ToasterStateContext } from 'templates/context/toaster-state'

const endpoints = COneAPIClient.ENDPOINTS

const useApiSuccessHandler: any = () => {
  const { addToasts } = useContext(ToasterStateContext)

  const onSuccess = (
    responseData: any,
    requestData: {method: IAPIMethod, endpoint: string}
  ): void => {
    const isPost = requestData.method === 'post'
    const isInvoice = [
      endpoints.invoices.createinvoice,
      endpoints.invoices.importXML,
      endpoints.invoices.cancel,
      endpoints.invoices.substitutionCancel,
      endpoints.invoices.correction,
      endpoints.invoices.manifestation.aware,
      endpoints.invoices.manifestation.realized,
      endpoints.invoices.manifestation.unknown,
      endpoints.invoices.manifestation.unrealized,
    ].includes(requestData.endpoint)

    const isLogout = requestData.endpoint === endpoints.auth.logout

    if (responseData.message.indexOf('http://') === 0 || responseData.message.indexOf('https://') === 0 || isLogout) {
      return
    }

    const getMessage = (): any => {
      if (isPost) {
        if (isInvoice) {
          if (Array.isArray(responseData.requestIds)) {
            const requestIds = responseData.requestIds.length
            return ({ message: `${responseData.message}\nTotal items: ${requestIds}` })
          }
          return ({ message: `${responseData.message}\n${responseData?.requestId || ''}` })
        }
        if (Array.isArray(responseData.ids) && responseData.ids.length === 1) {
          return ({ message: `${responseData.message}\n${responseData?.ids[0]}` })
        }
        if (Array.isArray(responseData.ids)) {
          const ids = responseData.ids.length
          return ({ message: `${responseData.message}\nTotal items: ${ids}` })
        }
        return ({ message: `${responseData.message}\n${responseData?.id || ''}` })
      }
      return { message: responseData.message, timeout: 7000 }
    }

    responseData?.message && addToasts({
      title: 'Success',
      color: 'success',
      ...getMessage()
    })
  }

  return onSuccess
}

export default useApiSuccessHandler
