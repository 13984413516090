const masks = {
  numberLast: '000.000.000',
  cep: '00000-000',
  cnpj: '00.000.000/0000-00',
  cnpjBase: '00.000.000',
  cpf: '000.000.000-00',
  crc: 'aa-000000/a-0',
  cashierNumber: '000',
  document: [
    { mask: '00.000.000/0000-00' }
  ],
  ip: [
    { mask: '***.***.***.***'  },
  ],
  phone: '(00) 0000-0000',
  cellPhone: '(00) 0 0000-0000',
  servicePhone: '0000 000 0000',
  invoice: '00–0000–00000000000000–00–000–000000000–0–00000000–0'
}

export default masks
