import { removeEmptyValue, checkEmptyObject } from './functionsInvoiceAdapter';

const transpInvoiceFormAdapter = (
  data: ITranspForm
): any => {
  const { modFrete, transporta, retTransp, veicTransp,
    reboque, vagao, balsa, ...transp } = data || {}
  const volFormatted = transp?.vol?.map((volume: any): any => {
    if (!volume?.lacres) {
      return {
        ...volume
      }
    }
    const lacres = volume?.lacres?.map((item: any): any => {
      return {
        nLacre: item
      }
    });
    return {
      ...volume,
      lacres
    }
  });

  const transportaObj = {
    ...transporta,
    ...(transporta?.CNPJ !== '' && transporta?.CNPJ !== undefined ? { CNPJ: transporta?.CNPJ?.replace(/[^0-9]/g, '') } : {}),
  }

  const transportaFormatted = transporta && removeEmptyValue(transportaObj)
  const retTranspFormatted = retTransp && removeEmptyValue(retTransp)
  const veicTranspFormatted = veicTransp && removeEmptyValue(veicTransp)

  const reboqueFormatted = reboque && reboque?.map((item) => {
    return item && removeEmptyValue(item)
  })

  return {
    ...(modFrete !== '' ? { modFrete } : {}),
    ...(!checkEmptyObject(transportaFormatted) ? { transporta: transportaFormatted } : {}),
    ...(!checkEmptyObject(retTranspFormatted) ? { retTransp: retTranspFormatted } : {}),
    ...(!checkEmptyObject(veicTranspFormatted) ? { veicTransp: veicTranspFormatted } : {}),
    ...(volFormatted?.length !== 0 ? { vol: volFormatted } : {}),
    ...(reboqueFormatted?.length !== 0 ? { reboque: reboqueFormatted } : {}),
    ...(vagao?.length !== 0 ? { vagao } : {}),
    ...(balsa?.length !== 0 ? { balsa } : {}),
  }
}

export default transpInvoiceFormAdapter
