import config from '../../@config'
import RequestApi from '../../services/api'
import serieAdapter from './serieAdapter'
import serieFormAdapter from './serieFormAdapter'
import serieUpdateFormAdapter from './serieUpdateFormAdapter'

const endpoints = config.ENDPOINTS.series

const Series = (api: IAPI): ISeriesAPI => {
  function adapter(response: any): ISerie {
    return serieAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ISerie>> {
    const serieParams = { ...params, sort: 'asc', orderBy: ['serieNumber'] }
    return RequestApi.listAdapter<ISerie>(
      await api.doRequest('get', endpoints.series, serieParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getContingencyList(subsidiaryId: string, params?: any): Promise<IList<ISerie>> {
    const list = await api.doRequest(
      'get',
      `${endpoints.contingency}/${subsidiaryId}`,
      params
    ) as IAPIResponse

    return {
      items: list.message.map((data: any) => adapter(data)),
      totalItems: list.message.length
    }
  }

  async function getById(id: string): Promise<ISerie> {
    return RequestApi.itemAdapter<ISerie>(
      await api.doRequest('get', `${endpoints.series}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: ISerieForm): Promise<string> {
    return (await api.doRequest('post', endpoints.series, serieFormAdapter(data))).message
  }

  async function update(id: string, data: ISerieForm): Promise<ISerie> {
    return RequestApi.itemAdapter<ISerie>(
      await api.doRequest('patch', `${endpoints.series}/${id}`, serieUpdateFormAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean):
  Promise<ISerie> {
    return RequestApi.itemAdapter<ISerie>(
      await api.doRequest('patch', `${endpoints.series}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.series}/${id}`)).message
  }

  return {
    getById,
    getList,
    getContingencyList,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Series
