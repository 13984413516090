import config from '../../@config'
import RequestApi from '../../services/api'
import devicesLists from '../lists/devicesLists'
import deviceAdapter from './deviceAdapter'
import deviceFormAdapter from './deviceFormAdapter'
import deviceFormUpdateAdapter from './deviceFormUpdateAdapter'

const endpoints = config.ENDPOINTS.devices

const Devices = (api: IAPI): IDevicesAPI => {
  function adapter(response: any): IDevice {
    return deviceAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IDevice>> {
    const devParams = { ...params, sort: 'asc', orderBy: ['deviceSerialNumber'] }
    return RequestApi.listAdapter<IDevice>(
      await api.doRequest('get', endpoints.devices, devParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IDevice> {
    return RequestApi.itemAdapter<IDevice>(
      await api.doRequest('get', `${endpoints.devices}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(terminalId: string, data: IDeviceForm): Promise<string> {
    return (await api.doRequest('post', `${endpoints.terminals}/${terminalId}/devices`, deviceFormAdapter(data))).message
  }

  async function update(ids: any, data: IDeviceForm): Promise<IDevice> {
    return RequestApi.itemAdapter<IDevice>(
      await api.doRequest('patch', `${endpoints.terminals}/${ids?.terminalId}/devices/${ids?.deviceId}`, deviceFormUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IDevice> {
    return RequestApi.itemAdapter<IDevice>(
      await api.doRequest('patch', `${endpoints.devices}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(ids: any): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.terminals}/${ids?.terminalId}/devices/${ids?.deviceId}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove,
    lists: devicesLists(api)
  }
}

export default Devices
