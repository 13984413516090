import isString from '../../commons/isString'
import config from '../../@config'
import Request from '../request'
import APIException from './APIException'

interface IRequestApiParams {
  clientKey: string
  environment?: 'production' | 'sandbox' | 'homologation'
  onError?(error?: IAPIException): void
  onSuccess?(message?: string): void
}

class RequestApi implements IAPI {
  baseUrl: string

  clientKey: string

  authorization: string

  onError: any

  onSuccess: any

  constructor({ clientKey, onError, onSuccess }: IRequestApiParams) {
    this.baseUrl = config.BASE_URL as string
    this.clientKey = clientKey
    this.authorization = ''
    this.onError = onError as any
    this.onSuccess = onSuccess as any
  }

  public setAuthorization(authorization: string): void {
    this.authorization = authorization
  }

  public async doRequest(
    method: IAPIMethod,
    endpoint: string,
    data?: { [key: string]: any },
    options?: { noAuthorization: boolean, finallyCallbackParam: any }
  ): Promise<IAPIResponse | IAPIResponseList> {
    const url = `${this.baseUrl}${endpoint}`
    return Request[method](url, data, this.getHeader(options?.noAuthorization || false))
      .then((response: any) => {
        if (isString(response.message)) {
          this.onSuccess && this.onSuccess(response, { method, endpoint })
        }

        return response
      })
      .catch((e: any) => {
        const error = new APIException(e)
        this.onError && this.onError(error, { method, endpoint })
        throw error
      })
  }

  public static listAdapter<T>(
    response: IAPIResponseList,
    externalAdapter: any
  ): IList<T> {
    if (response.message.items) {
      return {
        items: response.message.items.map(externalAdapter),
        totalCredit: response.message.totalCredit,
        totalDebit: response.message.totalDebit,
        totalItems: (response as IAPIResponseList).totalItems
      }
    }
    return {
      items: response.message.map(externalAdapter),
      totalItems: (response as IAPIResponseList).totalItems
    }
  }

  public static itemAdapter<T>(
    response: IAPIResponse,
    adapter?: any
  ): T {
    return adapter ? adapter(response.message, response) : response.message
  }

  private getHeader(noAuthorization: boolean): { [key: string]: string } {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-api-key': this.clientKey,
      ...(!noAuthorization && this.authorization ? { authorization: this.authorization } : {})
    }
  }
}

export default RequestApi
