import React, { memo, useContext } from 'react'
import { CSubheader } from '@coreui/react'

import { HeaderStateContext } from 'templates/context/header-state'

const SubHeader: React.FC = () => {
  const { header } = useContext(HeaderStateContext)

  return header
    ? (
      <CSubheader className="py-3 px-4 d-block border-top-1 cone-sub-header shadow-sm">
        {header}
      </CSubheader>
    )
    : null
}

export default memo(SubHeader)
