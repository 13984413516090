import parseApiDate from '../../../commons/parseApiDate'

import taxInfoAdapter from './taxInfoAdapter'

const countryTaxAdapter = (data: any): ICountryTax => {
  const {
    crt, crtEspeciais, indRecReceita, regimeTributario,
    period, mesBalRed, regimesEspeciais,
    updatedAt, createdAt, startDate, endDate,
    ...rest
  } = data

  return {
    ...rest,
    crt: crt && taxInfoAdapter(crt),
    mesBalRed: mesBalRed && taxInfoAdapter(mesBalRed),
    crtEspeciais: crtEspeciais && crtEspeciais.map(taxInfoAdapter),
    period: period && taxInfoAdapter(period),
    indRecReceita: indRecReceita && taxInfoAdapter(indRecReceita),
    regimeTributario: regimeTributario && taxInfoAdapter(regimeTributario),
    regimesEspeciais: regimesEspeciais && taxInfoAdapter(regimesEspeciais),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default countryTaxAdapter
