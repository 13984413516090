import { createContext } from 'react'

const props: IHeaderStateContext = {
  breadcrumb: {},
  newBreadcrumb: [],
  header: null,
  tab: '',
  title: '',
  setTab: (): any => ({}),
  setBreadcrumb: (): any => ({}),
  setNewBreadcrumb: (): any => ({}),
  setHeader: (): any => ({}),
  setTitle: (title: string): void => {
    document.title = title ? `${title} - Contabilone` : 'Contabilone'
  }
}

const HeaderStateContext = createContext(props)

export default HeaderStateContext
