import makeRoute from 'commons/helpers/route/makeRoute'
import routePaths from 'routes/routes-paths'

const environment = process.env.REACT_APP_CONE_API_ENVIRONMENT

const subdomain = environment === 'production' ? 'app' : `app-${environment}`

const getRedirect = (service, domain) => {
  return (
    `${domain ? domain : `https://${subdomain}.contabilone.com`}${routePaths.SIGN_IN_CONFIRMATION_REDIRECT.replace(':service', service)}`
  )
}

const O_AUTH_URL =  {
  GOOGLE: makeRoute({
    path: process.env.REACT_APP_AUTH_GOOGLE_URL,
    search: {
      client_id: process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID,
      redirect_uri: getRedirect('google'),
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      state: getRedirect('google', document.location.origin)
    }
  }),

  LINKEDIN: makeRoute({
    path: process.env.REACT_APP_AUTH_LINKEDIN_URL,
    search: {
      client_id: process.env.REACT_APP_AUTH_LINKEDIN_CLIENT_ID,
      redirect_uri: getRedirect('linkedin'),
      response_type: 'code',
      scope: 'r_liteprofile r_emailaddress',
      state: getRedirect('linkedin', document.location.origin)
    }
  }),

  NEXXAS_ID: makeRoute({
    path: process.env.REACT_APP_AUTH_NEXAAS_ID_URL,
    search: {
      client_id: process.env.REACT_APP_AUTH_NEXAAS_ID_CLIENT_ID,
      redirect_uri: getRedirect('nexaas-id'),
      response_type: 'code',
      scope: 'profile',
      state: getRedirect('nexaas-id', document.location.origin)
    }
  })
}

export default O_AUTH_URL
