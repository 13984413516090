import React, { useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { CCard, CAlert } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import useSession from 'templates/context/session-state/useSession'
import useSettings from 'templates/context/session-state/useSettings'
import { Spinner } from 'components/spinner'
import CardBody from 'components/card/CardBody'
import CardHeaderLogo from './CardHeaderLogo'

const RegisterConfirmation: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const { token }: any = useParams()
  const { setSession, clean } = useSession()
  const { settings, isContabilone } = useSettings()
  const api = useApi()
  const history = useHistory()

  const registerConfirmation: any = (token: string) => api.auth.doLoginByToken(token)

  const {
    mutate, data, isLoading, isSuccess, isError
  } = useMutation(registerConfirmation)

  const onboardPath = makeRoute({ path: routePaths.ONBOARD })
  const mainPath = makeRoute({ path: routePaths.MAIN })
  const signInPath = makeRoute({ path: routePaths.SIGN_IN })

  useEffect((): void => {
    clean()
    mutate(token)
  }, [])
  useEffect((): void => {
    if (data) {
      const typedData = data as IAuth

      setSession({
        session: typedData?.session,
        user: typedData?.user,
        settings: {
          ...settings,
          whitelabelSettings: typedData.settings || {}
        }
      })

      setTimeout(() => history.push(isContabilone ? onboardPath : mainPath), 2000)
    }
  }, [data])

  useEffect(() => {
    setTitle('Confirmação de e-mail')
  }, [])

  useEffect(() => {
    if (isError) {
      history.push(signInPath)
    }
  }, [isError])

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />
      <CardBody>
        {!isSuccess && (
          <h5 className="text-center mb-3">Confirmação de e-mail</h5>
        )}

        {isLoading && (
          <CAlert fade show color="secondary" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Buscando seus dados
            </h4>
            <Spinner className="m-auto" />
            <p>Validando token de acesso</p>
          </CAlert>
        )}

        {isSuccess && (
          <CAlert fade show color="success" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Pronto
            </h4>
            <Spinner className="m-auto" />
            <p>Redirecionando para o sistema</p>
          </CAlert>
        )}
      </CardBody>
    </CCard>
  )
}

export default RegisterConfirmation
