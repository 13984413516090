import React from 'react'

import {
  cidSyncDisabled,
  cilBuildingBusiness,
  cilCheckDouble,
  cilGlobe,
  cilMail,
  cilInfoCircle,
  cidFileImage,
  cisFilter,
  cisCheck,
  cisX,
  cisMail,
  cisLoop,
  cilExclamationCircle,
  cisReload,
  cilEye,
  cilDonate,
  cilLockMinus,
  cisCircle,
  cilKey
} from '@coreui/icons-pro'

import {
  cibGoogle,
  cibLinkedin,
  cifBr,
  cifEs,
  cifUs,
  cilAccountLogout,
  cilActionUndo,
  cilArrowLeft,
  cilArrowRight,
  cilBan,
  cilBarcode,
  cilBasket,
  cilBell,
  cilBlur,
  cilBook,
  cilBriefcase,
  cilCalculator,
  cilCalendar,
  cilChartLine,
  cilChartPie,
  cilChatBubble,
  cilCheck,
  cilCheckAlt,
  cilChevronDoubleUp,
  cilChevronDoubleDown,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronCircleRightAlt,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilClearAll,
  cilCloudDownload,
  cilCloudUpload,
  cilCommentBubble,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilDescription,
  cilDevices,
  cilDinner,
  cilDollar,
  cilEnvelopeClosed,
  cilExcerpt,
  cilExposure,
  cilExternalLink,
  cilFactory,
  cilFeaturedPlaylist,
  cilFilter,
  cilFilterSquare,
  cilFolderOpen,
  cilFork,
  cilFindInPage,
  cilFullscreen,
  cilFullscreenExit,
  cilGroup,
  cilHamburgerMenu,
  cilHistory,
  cilHome,
  cilInfo,
  cilJustifyLeft,
  cilLan,
  cilLibrary,
  cilLibraryBuilding,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilLowVision,
  cilMediaPlay,
  cilMenu,
  cilMinus,
  cilMobile,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPen,
  cilPenAlt,
  cilPencil,
  cilPeople,
  cilPlaylistAdd,
  cilPlus,
  cilQrCode,
  cilReload,
  cilRouter,
  cilRss,
  cilSearch,
  cilSettings,
  cilShareAlt,
  cilSortAscending,
  cilSortDescending,
  cilSpeech,
  cilSpeedometer,
  cilSun,
  cilTask,
  cilText,
  cilTrash,
  cilUser,
  cilUserFollow,
  cilUserUnfollow,
  cilWarning,
  cilWindowRestore,
  cilX,
  cilXCircle,
  cilZoom,
  cilZoomIn,
  cilClone,
  cilInputPower,
  cilPrint,
  cilShare,
  cilArrowCircleLeft,
  cilArrowCircleRight
} from '@coreui/icons'

React.icons = {
  cilCheckDouble,
  cidSyncDisabled,
  cilHome,
  cilGlobe,
  cilClearAll,
  cibGoogle,
  cibLinkedin,
  cifBr,
  cifEs,
  cifUs,
  cilActionUndo,
  cilAccountLogout,
  cilArrowLeft,
  cilArrowRight,
  cilBan,
  cilBarcode,
  cilBasket,
  cilBell,
  cilBlur,
  cilBriefcase,
  cilCalculator,
  cilCalendar,
  cilChartLine,
  cilChartPie,
  cilChatBubble,
  cilCheck,
  cilCheckAlt,
  cilChevronDoubleUp,
  cilChevronDoubleDown,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronCircleRightAlt,
  cilChevronTop,
  cilCloudDownload,
  cilCloudUpload,
  cilCommentBubble,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilDescription,
  cilDevices,
  cilDinner,
  cilDollar,
  cilEnvelopeClosed,
  cilExcerpt,
  cilExposure,
  cilExternalLink,
  cilFactory,
  cilFilterSquare,
  cilFindInPage,
  cilFolderOpen,
  cilFork,
  cilFullscreen,
  cilFullscreenExit,
  cilGroup,
  cilHamburgerMenu,
  cilHistory,
  cilInfo,
  cilJustifyLeft,
  cilLan,
  cilLibrary,
  cilLibraryBuilding,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilLowVision,
  cilMediaPlay,
  cilMenu,
  cilMinus,
  cilMobile,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPen,
  cilPenAlt,
  cilPencil,
  cilPeople,
  cilPlaylistAdd,
  cilPlus,
  cilQrCode,
  cilReload,
  cilRouter,
  cilSearch,
  cilSettings,
  cilShareAlt,
  cilSortAscending,
  cilSortDescending,
  cilSpeech,
  cilSpeedometer,
  cilSun,
  cilTask,
  cilText,
  cilTrash,
  cilUser,
  cilUserFollow,
  cilUserUnfollow,
  cilWarning,
  cilWindowRestore,
  cilX,
  cilXCircle,
  cilZoom,
  cilZoomIn,
  cilFeaturedPlaylist,
  cilRss,
  cilBook,
  cilBuildingBusiness,
  cilMail,
  cilClone,
  cilInfoCircle,
  cidFileImage,
  cilFilter,
  cisFilter,
  cisCheck,
  cisMail,
  cisLoop,
  cisX,
  cilInputPower,
  cilExclamationCircle,
  cisReload,
  cilPrint,
  cilShare,
  cilEye,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilDonate,
  cilLockMinus,
  cisCircle,
  cilKey
}
