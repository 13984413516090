import { checkEmptyObject, removeEmptyValue } from './functionsInvoiceAdapter';

interface ICompraForm {
  xNEmp: string
  xPed: string
  xCont: string
}

const compraInvoiceFormAdapter = (
  data: ICompraForm
): any => {
  const compraFormatted = data && removeEmptyValue(data)
  return !checkEmptyObject(compraFormatted) ? { compra: compraFormatted } : {}
}
export default compraInvoiceFormAdapter
