import { fieldToISO } from 'commons/helpers/formatter/formatDate'

const diaryUpdateAdapter = (data: IDiaryForm): any => {
  const { bookkeepingType, companyId, accountId, startDate, endDate, fiscalYearEndDate,
    diaryNumber, signatoryIds, signatories, ...rest } = data

  return {
    ...rest,
    ...(companyId ? { companyId } : { accountId }),
    ...(startDate ? { startDate: fieldToISO(startDate) } : []),
    ...(endDate ? { endDate: fieldToISO(endDate) } : []),
    ...(fiscalYearEndDate ? { fiscalYearEndDate: fieldToISO(fiscalYearEndDate) } : []),
    ...(bookkeepingType ? { bookkeepingType: String(bookkeepingType) } : []),
    ...(diaryNumber ? { diaryNumber: typeof diaryNumber === 'string' ? parseFloat(diaryNumber) : diaryNumber } : []),
    ...(signatoryIds?.length === 0 ? { signatoryIds: null } : { signatoryIds })
  }
}

export default diaryUpdateAdapter
