import { QueryClient, QueryClientProvider } from 'react-query'
import reactQuery from '@config/react-query'

import { ApiProvider } from 'templates/context/api-state'
import { SessionStateProvider } from 'templates/context/session-state'
import HelpChat from 'components/help-chat'
import SmallScreenLocker from 'components/small-screen-locker'
import ToasterStateProvider from 'templates/context/toaster-state/ToasterStateProvider'

import AppRouter from './AppRouter'

const queryClient = new QueryClient(reactQuery)

const App: React.FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SessionStateProvider>
          <ToasterStateProvider>
            <ApiProvider>
              <AppRouter />
            </ApiProvider>
          </ToasterStateProvider>
        </SessionStateProvider>
      </QueryClientProvider>

      <SmallScreenLocker />
      <HelpChat />
    </>
  )
}

export default App
