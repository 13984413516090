import parseApiDate from '../../../commons/parseApiDate'

const countryAdapter = (data: any): ICountry => {
  const { updatedAt, createdAt, startDate, endDate, ...rest } = data

  return {
    ...rest,
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default countryAdapter
