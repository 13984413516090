const armaFormAdapter = (product: any): any => {
  const { arma, tpArma, nSerie, nCano, descr } = product as any || {}
  if (arma?.length) {
    return arma[0]
  }
  const arrayValue = [tpArma, nSerie, nCano, descr]
  if (arrayValue.every((value) => !value)) {
    return []
  }
  return {
    tpArma,
    nSerie,
    nCano,
    descr
  }
}

export default armaFormAdapter
