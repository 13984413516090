import React, { useContext, useEffect } from 'react'
import { CCard } from '@coreui/react'
import { HeaderStateContext } from 'templates/context/header-state'
import routePaths from 'routes/routes-paths'
import CardBody from 'components/card/CardBody'
import Button from 'components/form/button'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const RecoverAccount: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)

  useEffect(() => {
    setTitle('Não consegue entrar?')
  }, [])

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />
      <CardBody>
        <h5 className="text-center mb-3">Não consegue entrar?</h5>
        <Button
          to={routePaths.RECOVER_PASSWORD}
          color="info"
          className="d-flex align-items-center justify-content-center mt-2 gap-2"
          icon="cil-lock-minus"
          iconSize="sm"
          block
        >
          <span className="ml-2">Recuperar senha</span>
        </Button>
        <Button
          to={routePaths.EMAIL_CONFIRMATION}
          color="info"
          className="d-flex align-items-center justify-content-center mt-2"
          icon="cil-envelope-closed"
          iconSize="sm"
          block
        >
          <span className="ml-2">Confirmar e-mail</span>
        </Button>
      </CardBody>
      <Button
        to={routePaths.SIGN_IN}
        id="btn-recover-password"
        size="md"
        color="link"
        className="d-flex justify-content-center"
        title="Voltar para a página inicial"
        block
      />
      <CardFooterLink />
    </CCard>
  )
}

export default RecoverAccount
