import masks from '@config/masks'
import { validateCNPJ, validateSUFRAMA, validateIE, validateCPF } from 'commons/lib/js-brazilian-validator'

import formatNumberMask from '../formatter/formatNumberMask'

const env = process.env.REACT_APP_CONE_API_ENVIRONMENT

const number = /^[-]?\d+$/
const string = /^[a-zA-Z0-9]+$/;
const codeRegex = /^([0-9]+(\.{1}[0-9]+)*?)$/
const emailRegex = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i // eslint-disable-line
const crcRegex = /([A-Z]{2})-(\d{6})\/(O|P|T)-(\d)/
const domainRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/ // eslint-disable-line
const urlRegex = /^(?:(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?)?(?:(?:(?!(?:10|127)?)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/ // eslint-disable-line
const cnpjBaseRegex = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3})/ // eslint-disable-line
const dateRegex = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/
const phoneRegex = /^(\+\d{1,3}\s?)?(\d{1,2}\s?)?([1-9][1-9]|0[1-9][1-9])(\d{4,5})(\d{4})$|^0800\s\d{3}\s\d{4}$/;

const validators = {
  cnpjBase: (value) =>{
    return (!!value && !cnpjBaseRegex.test(value) && 'Inválido')|| null
  },
  cnpj: (value) => (!!value && !validateCNPJ(value) && 'Inválido') || null,
  code: (value) => (!!value && !codeRegex.test(value) && 'Inválido. Utilize apenas números e [.] como separador.') || null,
  cpf: (value) => (!!value && !validateCPF(value) && 'Inválido') || null,
  crc: (value) => (!!value && !crcRegex.test(value.toUpperCase()) && 'Inválido') || null,
  date: (value) => (!!value && !dateRegex.test(value) && 'Formato de data inválido. Utlize DD/MM/YYYY') || null,
  domain: (value) => env === 'sandbox' && value === 'localhost:3000' ? null : (!!value && !domainRegex.test(value.toLowerCase()) && 'Inválido') || null,
  url: (value) => env === 'sandbox' && value === 'localhost:3000' ? null : (!!value && !urlRegex.test(value.toLowerCase()) && 'Inválido') || null,
  email: (value) => {
    if (!!value && (typeof value !== 'string' || !emailRegex.test(value.toLowerCase()))) {
      return  'E-mail inválido'
    } 
    if(value.length > 60) {
      return  'Máximo 60 caracteres'
    } 
    return null
  },
  ie: (value, uf) => (!!uf && !!value && !validateIE(uf, value) && 'Inválido') || null,
  confirm: (value1, value2) => (value1 !== value2 && 'Senhas digitadas não conferem') || null,
  number: (value) => (!number.test(value) && 'Inválido') || null,
  string: (value) => (!string.test(value) && 'Inválido') || null,
  phone: (value) => (!!value && !phoneRegex.test(value) && `Telefone inválido: ${value}`) || null,
  maxSize: (value, length) => (value?.length && value?.length > length && `Máximo ${length} caracteres`) || null,
  minSize: (value, length) => (value?.length && value?.length < length && `Mínimo ${length} caracteres`) || null,
  suframa: (value) => (!!value && !validateSUFRAMA(value) && 'Inválido') || null,
  mandatory: (value) => (
    (!value
    || (Array.isArray(value) && value.length === 0)
    || (!Array.isArray(value) && typeof value === 'object' && value !== null)
  ) && 'Obrigatório') || null,
  document: (value) => {
    const cpf = validators.cpf(value)
    const cnpj = validators.cnpj(value)

    return [cpf, cnpj].includes(null) ? null : cpf || cnpj
  },
  cnpjBaseCPF: (value) => {
    if(value.length>=11){
      return validators.cpf(value)
    }
    return validators.cnpjBase(value)
  },
  headquarters:(value) => (value?.headquarters !== true && 'Selecione um estabelecimento matriz' || null),
  ruleMaxString: (value, max) => {
    const formatToNumber = value ? Number(value) : 0
    if (formatToNumber > max) {
      return `Valor deve ser até ${max}`
    }
    return null
  },
  ruleMinString: (value, min) => {
    const formatToNumber = value ? Number(value) : 0
    if (formatToNumber < min) {
      return `Valor deve ser maior que ${min}`
    }
    return null
  },
  rule3v4: (value, min) => {
    if (value?.toString()?.split('.')[0]?.length > 3) {
      return 'Valor deve ser composto por até 3 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 4) {
      return 'Valor deve ser composto por até 4 casas decimais'
    }
    if(min > value?.toString()?.split('.')[1]?.length || value?.toString()?.split('.')[1] === undefined ) {
      return 'Valor deve ser composto por no mínimo 2 casas decimais'
    }
    return null
  },
  rule8v3: (value) => {
    if (value?.toString()?.split('.')[0]?.length > 8) {
      return 'Valor deve ser composto por até 8 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 3) {
      return 'Valor deve ser composto por até 3 casas decimais'
    }
    return null
  },
  rule9v4: (value) => {
    if (value?.toString()?.split('.')[0]?.length > 9) {
      return 'Valor deve ser composto por até 9 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 4) {
      return 'Valor deve ser composto por até 4 casas decimais'
    }
    return null
  },
  rule11v10: (value) => {
    if (value?.toString()?.split('.')[0]?.length > 11) {
      return 'Valor deve ser composto por até 11 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 10) {
      return 'Valor deve ser composto por até 10 casas decimais'
    }
    return null
  },
  rule11v4: (value) => {
    if (value?.toString()?.split('.')[0]?.length > 11) {
      return 'Valor deve ser composto por até 11 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 4) {
      return 'Valor deve ser composto por até 4 casas decimais'
    }
    return null
  },
  rule12v4: (value) => {
    console.log(value)
    if (value?.toString()?.split('.')[0]?.length > 12) {
      return 'Valor deve ser composto por até 12 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 4) {
      return 'Valor deve ser composto por até 4 casas decimais'
    }
    return null
  },
  rule13v2: (value) => {
    if (value?.toString()?.split('.')[0]?.length > 13) {
      return 'Valor deve ser composto por até 13 inteiros'
    }
    if (value?.toString()?.split('.')[1]?.length > 2) {
      return 'Valor deve ser composto por até 2 casas decimais'
    }
    return null
  }
}

export default validators
