import React from 'react'
import { CSpinner } from '@coreui/react'

const LoadingData: React.FC<ILoadingData> = ({ title, className, style, shouldCentralize }) => {
  return (
    shouldCentralize
      ? (
        <div className="divCentralized">
          <div
            className={`text-center ${className}`}
            style={style}
          >
            <CSpinner color="dark" />
            <p>{title || 'Carregando...'}</p>
          </div>
        </div>
      )
      : (
        <div
          className={`text-center ${className}`}
          style={style}
        >
          <CSpinner color="dark" />
          <p>{title || 'Carregando...'}</p>
        </div>
      )
  )
}

export default LoadingData
