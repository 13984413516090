import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import { CAlert, CCard } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import validators from 'commons/helpers/validations'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import { Spinner } from 'components/spinner'
import Button from 'components/form/button'
import CardBody from 'components/card/CardBody'
import InputPassword from 'components/form/InputPassword'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const RecoverPasswordConfirmation: React.FC = () => {
  const api = useApi()
  const history = useHistory()
  const { setTitle } = useContext(HeaderStateContext)
  const [password, setPassword] = useState('')
  const { token }: any = useParams()

  const { mutate, data, isLoading, isSuccess, isError, error } = useMutation(
    (password: string) => api.auth.createPassword(token, password)
  )

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })
  const onSubmit: SubmitHandler<IRecoverPasswordConfirmation> = (
    data: IRecoverPasswordConfirmation
  ) => {
    mutate(data.password)
  }

  useEffect((): void => {
    if (data) {
      setTimeout(() => history.push(makeRoute({ path: routePaths.MAIN })), 2000)
    }
  }, [data])

  useEffect((): void => {
    setTitle('Atualizar senha')
  }, [])

  const errors = error ? (error as IAPIException).errors as Array<any> : []
  const errorMessage = errors.length && errors[0].messages.join('')

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />

      <CardBody>
        {!isSuccess && (
          <h5 className="text-center mb-3">Atualizar senha</h5>
        )}

        {!isError && !isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputPassword
              name="password"
              autoComplete="on"
              placeholder="Nova senha"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: {
                  validations: (password: string) => (
                    validators.minSize(password, 6)
                    || null
                  )
                }
              }}
              onChange={(e: any) => setPassword(e.target.value)}
              messages={[(
                <PasswordStrengthBar
                  password={password}
                  minLength={6}
                  scoreWords={['Fraca', 'Razoável', 'Boa', 'Forte', 'Muito forte']}
                  shortScoreWord={(password && 'Senha curta') || ''}
                />
              )]}
            />

            <InputPassword
              name="confirmPassword"
              autoComplete="on"
              placeholder="Confirmar senha"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: {
                  validations: (password: string) => (
                    validators.confirm(password, getValues('password'))
                    || null
                  )
                }
              }}
            />

            <Button
              isLoading={isLoading}
              title="Atualizar senha"
              size="sm"
              color="info"
              type="submit"
              block
            />
          </form>
        )}

        {isSuccess && (
          <CAlert fade color="success" className="text-center m-0">
            <h4 className="alert-heading mb-1">Pronto</h4>
            <p>Sua senha foi atualizada com sucesso!</p>
            <p>Agora você será redirecionado para o login</p>
            <p className="mb-0"><Spinner className="m-auto" /></p>
          </CAlert>
        )}

        {isError && (
          <CAlert fade show color="warning" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Ocorreu algum erro
            </h4>
            <p>{errorMessage}</p>
          </CAlert>
        )}
        <div className="d-flex justify-content-center">
          {isError && (
            <Button
              size="sm"
              color="link"
              className="px-0 float-right"
              title="Voltar para a página inicial"
              to={makeRoute({ path: routePaths.SIGN_IN })}
            />
          )}
        </div>
      </CardBody>

      <div className="d-flex justify-content-center">
        {isError && (
          <Button
            size="sm"
            color="link"
            className="px-0 float-right"
            title="Voltar para página inicial"
            to={makeRoute({ path: routePaths.SIGN_IN })}
          />
        )}
      </div>
      <CardFooterLink />
    </CCard>
  )
}

export default RecoverPasswordConfirmation
