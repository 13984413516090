import { removeEmptyValue } from './form/functionsInvoiceAdapter'

const manifestationFormAdapter = (data: any): any => {
  const { type, xJust, subsidiary, subsidiaryId, chNFe, cnpj, ie, ...dataRest } = data || {}

  const chNFeFormatted = (chNFe && !Array.isArray(chNFe)) ? chNFe?.replace(/\D/g, '') : chNFe

  const dataFormatted = {
    ...dataRest,
    ...(subsidiaryId ? { subsidiaryId } : { cnpj, ie }),
    chNFe: chNFeFormatted,
    ...((type === 'unknown' || type === 'unrealized') && { xJust })
  }

  return removeEmptyValue(dataFormatted)
}

export default manifestationFormAdapter
