import { useCallback, useContext } from 'react'
import userAdapter from '@api/src/domains/users/userAdapter'

import SessionStateContext from './SessionStateContext'

export default function useLoggedUser(): IUseLoggedUser {
  const { data, setData } = useContext<ISessionStateContext>(SessionStateContext)

  const setUser = useCallback((user: IUser): void => setData({ ...data, user }), [data, setData])

  const loggedUser = userAdapter(data?.user)

  return {
    loggedUser,
    setUser
  }
}
