import React from 'react'

const Title: React.FC <IBreadcrumbItem> = ({ title, strong, label }) => {
  return (
    <div>
      {strong ? (
        <strong
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >{title}
        </strong>
      ) : (
        <>
          {title}
        </>
      )}
    </div>
  )
}

export default Title
