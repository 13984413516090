import { CTooltip } from '@coreui/react'
import Icon from 'components/icon'

interface IIconWithTooltip {
  iconName?: string
  content?: string
  className?: string
}

const IconWithTooltip = ({ content, iconName = 'cil-exclamation-circle', className }: IIconWithTooltip): any => {
  return (
    <CTooltip content={content}>
      <Icon
        className={`icon-tooltip ${className}`}
        name={iconName}
      />
    </CTooltip>
  )
}

export default IconWithTooltip
