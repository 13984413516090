import parseApiDateString from './parseApiDateString'

export default (date: any): IDateAPI | null => {
  if (!date) return null

  const stringToDate = parseApiDateString(date)

  if (stringToDate) return stringToDate

  const { epoch, formatted } = date

  const dateObject = (new Date(epoch * 1000)) as IDateAPI
  dateObject.epoch = epoch
  dateObject.formatted = formatted

  return dateObject
}
