import React, { useState } from 'react'

import MobileRotate from 'assets/images/mobile-rotate-rotation.svg'

const w = window

const SmallScreenLocker: React.FC = () => {
  const [show, setShow] = useState(window.innerWidth < 500)
  w.onresize = (e: any) => {
    setShow(window.innerWidth < 500)
  }

  return (show && (
    <div className="small-screen-popup">
      <div className="small-screen-popup-background" />
      <div className="small-screen-popup-content">
        <img src={MobileRotate} alt="Mobile rotate" />
        <p>Gire o celular para ter uma melhor experiência em nossa plataforma</p>
      </div>
    </div>
  )) || null
}

export default SmallScreenLocker
