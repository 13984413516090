import config from '../../@config'
import RequestApi from '../../services/api'
import cancelFormAdapter from './cancelFormAdapter'
import correctionFormAdapter from './correctionFormAdapter'
import disablementFormAdapter from './disablementFormAdapter'
import { checkEmptyObject } from './form/functionsInvoiceAdapter'
import invoiceAdapter from './invoiceAdapter'
import invoiceFormAdapter from './invoiceFormAdapter'
import invoiceListAdapter from './invoiceListAdapter'
import manifestationFormAdapter from './manifestationFormAdapter'

const endpoints = config.ENDPOINTS.invoices

const Invoices = (api: IAPI): IInvoicesAPI => {
  function adapter(response: any): IInvoice {
    return invoiceAdapter(response)
  }
  function adapterList(response: any): IInvoiceList {
    return invoiceListAdapter(response)
  }

  async function create(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.createinvoice, invoiceFormAdapter(data))).message
  }

  async function cancel(data: any): Promise<string> {
    if (data?.chNFeRef) {
      return (await api.doRequest('post', endpoints.substitutionCancel, cancelFormAdapter(data))).message
    }
    return (await api.doRequest('post', endpoints.cancel, cancelFormAdapter(data))).message
  }

  async function manifestation(data: any): Promise<string> {
    const { type } = data || {}

    if (type === 'aware') {
      return (await api.doRequest('post', endpoints.manifestation.aware, manifestationFormAdapter(data))).message
    }
    if (type === 'realized') {
      return (await api.doRequest('post', endpoints.manifestation.realized, manifestationFormAdapter(data))).message
    }
    if (type === 'unknown') {
      return (await api.doRequest('post', endpoints.manifestation.unknown, manifestationFormAdapter(data))).message
    }
    return (await api.doRequest('post', endpoints.manifestation.unrealized, manifestationFormAdapter(data))).message
  }

  async function correction(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.correction, correctionFormAdapter(data))).message
  }

  async function disablement(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.disablement, disablementFormAdapter(data))).message
  }

  async function getList(params: IPagedFilterParams, data?: any): Promise<IList<IInvoiceList>> {
    const hasData = data?.length !== 0 && !checkEmptyObject(data[0])
    return RequestApi.listAdapter<IInvoiceList>(
      (
        await api.doRequest('post', endpoints.invoices, { ...params, count: false, ...(hasData && { filters: data }) })
      ) as IAPIResponseList,
      (data: any) => adapterList(data)
    )
  }

  async function getTotalItems(params: IPagedFilterParams, data?: any): Promise<IList<any>> {
    const hasData = data?.length !== 0 && !checkEmptyObject(data[0])
    return RequestApi.listAdapter<IInvoiceList>(
      (
        await api.doRequest('post', endpoints.invoices, { ...params, onlyCount: true, ...(hasData && { filters: data }) })
      ) as IAPIResponseList,
      (data: any) => adapterList(data)
    )
  }

  async function getById(requestId : string, tpEmis: string, whitelabelId: string):
   Promise<IInvoice> {
    const endpoint = tpEmis === '1' ? endpoints.authorization : endpoints.contingencyauthorization
    return RequestApi.itemAdapter<IInvoice>(
      await api.doRequest('get', endpoint.replace('{REQUEST_ID}', requestId), { whitelabelId }),
      (data: any) => adapter(data)
    )
  }

  async function importXML(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.importXML, data)).message
  }

  return {
    create,
    cancel,
    manifestation,
    correction,
    disablement,
    getList,
    getTotalItems,
    getById,
    endpoints,
    importXML
  }
}

export default Invoices
