import validateIEAC from './validate-ie-ac'
import validateIEAL from './validate-ie-al'
import validateIEAP from './validate-ie-ap'
import validateIEAM from './validate-ie-am'
import validateIEBA from './validate-ie-ba'
import validateIECE from './validate-ie-ce'
import validateIEDF from './validate-ie-df'
import validateIEES from './validate-ie-es'
import validateIEGO from './validate-ie-go'
import validateIEMA from './validate-ie-ma'
import validateIEMT from './validate-ie-mt'
import validateIEMS from './validate-ie-ms'
import validateIEMG from './validate-ie-mg'
import validateIEPA from './validate-ie-pa'
import validateIEPB from './validate-ie-pb'
import validateIEPR from './validate-ie-pr'
import validateIEPE from './validate-ie-pe'
import validateIEPI from './validate-ie-pi'
import validateIERJ from './validate-ie-rj'
import validateIERN from './validate-ie-rn'
import validateIERS from './validate-ie-rs'
import validateIERO from './validate-ie-ro'
import validateIERR from './validate-ie-rr'
import validateIESC from './validate-ie-sc'
import validateIESP from './validate-ie-sp'
import validateIESE from './validate-ie-se'
import validateIETO from './validate-ie-to'

const validators = [
  { labels: ['AC', '12'], validator: validateIEAC },
  { labels: ['AL', '27'], validator: validateIEAL },
  { labels: ['AP', '16'], validator: validateIEAP },
  { labels: ['AM', '13'], validator: validateIEAM },
  { labels: ['BA', '29'], validator: validateIEBA },
  { labels: ['CE', '23'], validator: validateIECE },
  { labels: ['DF', '53'], validator: validateIEDF },
  { labels: ['ES', '32'], validator: validateIEES },
  { labels: ['GO', '52'], validator: validateIEGO },
  { labels: ['MA', '21'], validator: validateIEMA },
  { labels: ['MT', '51'], validator: validateIEMT },
  { labels: ['MS', '50'], validator: validateIEMS },
  { labels: ['MG', '31'], validator: validateIEMG },
  { labels: ['PA', '15'], validator: validateIEPA },
  { labels: ['PB', '25'], validator: validateIEPB },
  { labels: ['PR', '41'], validator: validateIEPR },
  { labels: ['PE', '26'], validator: validateIEPE },
  { labels: ['PI', '22'], validator: validateIEPI },
  { labels: ['RJ', '33'], validator: validateIERJ },
  { labels: ['RN', '24'], validator: validateIERN },
  { labels: ['RS', '43'], validator: validateIERS },
  { labels: ['RO', '11'], validator: validateIERO },
  { labels: ['RR', '14'], validator: validateIERR },
  { labels: ['SC', '42'], validator: validateIESC },
  { labels: ['SP', '35'], validator: validateIESP },
  { labels: ['SE', '28'], validator: validateIESE },
  { labels: ['TO', '17'], validator: validateIETO }
]

export default function validateIE(state, document) {
  const { validator } = validators.find(
    ({ labels }) => labels.includes(state.toString())
  )

  return validator(document)
}
