import ieBase from '../ieBase'

import isCheckDigitValid from '../validate-ie-go/isCheckDigitValid'
import isInitialCharsValid from '../validate-ie-go/isInitialCharsValid'


export default function validateIEDF (value) {
  const number = value.replace(/[^\d]+/g, '')
  const initialChars = ['07', '08']

  return ieBase(value, {
    numDigits: 1,
    valueLength: 13, 
    customConditions: isCheckDigitValid(number) && isInitialCharsValid(number, initialChars),
    ignoresCheckDigit: true
  })
}

