import parseApiDate from '../../commons/parseApiDate'

const webhookDeliveriesAdapter = (data: any): IWebhookDeliveriesList => {
  const {
    requestedAt,
    responsedAt,
    webhook,
    ...rest
  } = data

  const { createdAt, updatedAt, account } = webhook

  return {
    requestedAt: parseApiDate(requestedAt),
    responsedAt: parseApiDate(responsedAt),
    webhook: {
      createdAt: parseApiDate(createdAt),
      updatedAt: parseApiDate(updatedAt),
      account: {
        createdAt: parseApiDate(account?.createdAt),
        updatedAt: parseApiDate(account?.updatedAt),
        ...account
      },
      ...webhook
    },
    ...rest
  }
}

export default webhookDeliveriesAdapter
