import React from 'react'
import { CCardBody } from '@coreui/react'
import LoadingData from 'components/loading/LoadingData'

interface ICardBody extends CCardBody {
  isLoading?: boolean
  onChange?: () => void
  isFetching?: boolean
}

const CardBody: React.FC<ICardBody> = ({
  children, isLoading, onChange, isFetching, ...props
}) => {
  return (
    <CCardBody onChange={onChange} {...props}>
      {(isLoading || isFetching) ? <LoadingData /> : children}
    </CCardBody>
  )
}

export default CardBody
