import logger from 'commons/helpers/logger'
import { useContext } from 'react'
import useSession from 'templates/context/session-state/useSession'
import { ToasterStateContext } from 'templates/context/toaster-state'

const useApiErrorHandler: any = () => {
  const { clean } = useSession()
  const { addToasts } = useContext(ToasterStateContext)

  const onError = (e: IAPIException,
    requestData: {method: IAPIMethod, endpoint: string}): void => {
    logger.log('API ERROR HANDLER: code: ', e.code)
    logger.log('API ERROR HANDLER: name: ', e.name)
    logger.log('API ERROR HANDLER: payload: ', e.payload)

    if (e.code) {
      if (e.code === 401) clean()

      const getErrorMessages = (): Array<string> => {
        const fields = e.getFields()

        if (fields?.length) {
          const { messages } = e?.errors?.[0] || [] as any
          if (typeof messages === 'object') {
            return fields?.map(({ field, messages }: any) => {
              return field
                ? (`${field}, ${messages?.[0]}`)
                : messages?.[0]
            }) || []
          }
          return e?.errors?.map(({ field, messages }: any) => {
            return field
              ? (`${field}, ${messages?.[0]}`)
              : messages?.[0]
          }) || []
        }
        return e.getMessages()
      }

      const isTerminalHistory = requestData.endpoint.includes('/history/terminals/')

      if (!(isTerminalHistory && e.code === 404)) {
        const errors = [...getErrorMessages()]

        errors.length && addToasts({
          title: 'Error',
          message: errors.length === 1
            ? errors[0]
            : (
              <ul className="m-0 p-0 list-group list-group-flush">
                {errors.slice(0, 3).map(((message, key) => (
                  <li key={`${message + key}`} className="list-group-item">{message}</li>
                )))}
              </ul>
            ),
          color: 'danger',
          timeout: 7000
        })

        // @todo Make error handler here
        if (e.message === 'Failed to fetch') {
          logger.log('[APIHandler requestMethod]', e)
        }
      }
    } else {
      navigator?.onLine && addToasts({
        title: 'Error',
        message: 'An error has occurred, please try again.',
        color: 'danger',
        timeout: 7000
      })
    }
  }

  return onError
}

export default useApiErrorHandler
