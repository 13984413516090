import cleanNumber from '../../commons/cleanNumber'

const participantFormIdentificationAdapter = (
  data: IParticipantIdentificationForm, isUpdate?: boolean
): IParticipantIdentificationForm => {
  const {
    identification: {
      cpf, cnpj, fone, idEstrangeiro, ...rest
    }
  } = data

  const cleanCPF = cpf?.replaceAll(/[^0-9]/g, '')
  const cleanCNPJ = cnpj?.replaceAll(/[^0-9]/g, '')
  const number = cleanNumber(fone, /\d+/g)

  const foneData = (): any => {
    if (!isUpdate && !number) {
      return { fone: '' }
    }
    if (fone === undefined) {
      return {}
    }

    return { fone: number }
  }

  return {
    identification: {
      ...(cnpj ? { cnpj: cleanCNPJ } : {}),
      ...(cpf ? { cpf: cleanCPF } : {}),
      ...(idEstrangeiro ? { idEstrangeiro } : {}),
      ...(foneData()),
      ...rest,
    }
  }
}

export default participantFormIdentificationAdapter
