import config from '../../@config'
import RequestApi from '../../services/api'
import timelineAdapter from './timelineAdapter'

const endpoints = config.ENDPOINTS.timelines

const Timelines = (api: IAPI): ITimelinesAPI => {
  function adapter(response: any): ITimeline {
    return timelineAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ITimeline>> {
    return RequestApi.listAdapter<ITimeline>(
      await api.doRequest('get', endpoints.timeline, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ITimeline> {
    return RequestApi.itemAdapter<ITimeline>(
      await api.doRequest('get', `${endpoints.timeline}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: ITimelineForm): Promise<string> {
    return (await api.doRequest('post', endpoints.timeline, data)).message
  }

  async function update(id: string, data: ITimelineForm): Promise<ITimeline> {
    return RequestApi.itemAdapter<ITimeline>(
      await api.doRequest('patch', `${endpoints.timeline}/${id}`, data),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ITimeline> {
    return RequestApi.itemAdapter<ITimeline>(
      await api.doRequest('patch', `${endpoints.timeline}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.timeline}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Timelines
