import parseApiDate from '../../commons/parseApiDate'

import whitelabelAdapter from '../whitelabels/whitelabelAdapter'
import companyAdapter from '../companies/companyAdapter'
import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'

const accountPlanAdapter = (data: any): IAccountingChart => {
  const {
    createdAt, updatedAt, users,
    subsidiaries,
    company,
    whitelabel,
    ...rest
  } = data

  return {
    whitelabel: whitelabel && whitelabelAdapter(whitelabel),
    company: company && companyAdapter(company),
    subsidiaries: subsidiaries && subsidiaries.map(subsidiaryAdapter),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default accountPlanAdapter
