const invoiceAdapter = (data: any): IInvoice => {
  const {
    NFe,
    ...rest
  } = data

  const NFE = NFe?.infNFe
  return {
    ...NFE,
    ...rest
  }
}
export default invoiceAdapter
