import React, { useCallback, useState } from 'react'
import { useController } from 'react-hook-form'

import SelectField from './fields/SelectField'

const InputTag: React.FC<Omit<IController & ISelectField, 'options'|'multiple'> & {
  tags?: Array<ISelectOption>
  setValue: any
  acceptCompoundWord?: boolean
  clearEmptyError?: () => void
  maskRegex?: any
}> = ({
  control,
  rules,
  name,
  placeholder,
  onChange,
  clearEmptyError,
  setValue,
  acceptCompoundWord,
  maskRegex,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')
  const { field: { ref, ...field }, fieldState } = useController({
    name,
    control,
    rules
  })

  const getValues = (): any => {
    if (!field?.value) {
      return []
    }
    if (Array.isArray(field?.value)) {
      return field?.value
    }
    if (typeof field?.value === 'object') {
      const values = Object?.values(field?.value)?.length ? [field?.value] : []
      return values
    }
    return []
  }
  const values = getValues()

  const handleSetValue = (value: string): void => {
    const wordBreaker = acceptCompoundWord ? /[,;]+/ : /[\s,;]+/
    const formattedValue = maskRegex ? maskRegex(value) : value

    setValue(name, [
      ...(values || []),
      ...formattedValue.split(wordBreaker)
    ].filter((value) => value !== ''))
  }

  const handleChange = useCallback((event: any): void => {
    field.onChange(event)
    onChange && onChange(event)
    clearEmptyError && clearEmptyError()
  }, [field, onChange, values])

  const handleBlur = useCallback((event: any): void => {
    handleSetValue(inputValue)
    setInputValue('')
  }, [field, onChange])

  const handleInputChange = (value: string): any => {
    setInputValue(value)

    if (value.endsWith(';') || value.endsWith(',') || (!acceptCompoundWord && value.endsWith(' '))) {
      handleSetValue(value)
      setInputValue('')
    }
    clearEmptyError && clearEmptyError()
  }

  const emailsOptions: Array<ISelectOption> = values?.map((label: string) => (
    { value: label, label }
  ))

  return (
    <div>
      <SelectField
        multiple
        inputValue={inputValue}
        menuIsOpen={false}
        invalid={fieldState.invalid}
        placeholder={placeholder || 'Digite'}
        error={fieldState.error?.message}
        onInputChange={handleInputChange}
        options={emailsOptions}
        {...field}
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  )
}

export default InputTag
