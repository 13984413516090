import cleanNumber from '../../commons/cleanNumber'

const subsidiaryFormIdentificationAdapter = (
  data: ISubsidiaryIdentificationForm
): ISubsidiaryIdentificationForm => {
  const {
    identification: {
      document, cpf, cnpj, fone, indPerfil, ...rest
    }
  } = data

  const doc = cleanNumber(document, /\d+/g)

  const theCpf = (doc.length === 11 && doc) || cpf || ''
  const theCnpj = (doc.length === 14 && doc) || cnpj || ''

  return {
    identification: {
      cnpj: theCnpj,
      cpf: theCpf,
      fone: cleanNumber(fone, /\d+/g),
      indPerfil: indPerfil || null,
      ...rest
    }
  }
}

export default subsidiaryFormIdentificationAdapter
