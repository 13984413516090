import config from '@api/src/@config'
import RequestApi from '../../services/api'
import rulesEngineAdapter from './rulesEngineAdapter'
import rulesEngineEvaluateFormAdapter from './rulesEngineEvaluateFormAdapter'

const endpoints = config.ENDPOINTS.rulesEngine

const RulesEngines = (api: IAPI): IRulesEnginesAPI => {
  function adapter(response: any): IRulesEngine {
    return rulesEngineAdapter(response)
  }

  async function list(params: IRulesEngineListParams): Promise<IList<IRulesEngine>> {
    return RequestApi.listAdapter<IRulesEngine>(
      await api.doRequest('post', endpoints.list, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function search(params: IPagedFilterParams): Promise<IList<IRulesEngine>> {
    return RequestApi.listAdapter<IRulesEngine>(
      await api.doRequest('post', endpoints.searchRule, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getAttributes(params: IPagedFilterParams): Promise<IList<IRulesEngineAttribute>> {
    return RequestApi.listAdapter<IRulesEngineAttribute>(
      await api.doRequest('get', `${endpoints.attributeGroups}`, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function create(data: IRulesEngineForm): Promise<string> {
    return (await api.doRequest('post', endpoints.rulesEngine, data)).message
  }

  async function update(
    accountId: string,
    id: string,
    data: IRulesEngineForm
  ): Promise<IRulesEngine> {
    return RequestApi.itemAdapter<IRulesEngine>(
      await api.doRequest('patch', `${endpoints.rulesEngine}/${accountId}/${id}`, data),
      (data: any) => adapter(data)
    )
  }

  async function remove(accountId: string, id: string): Promise<string> {
    return (
      await api.doRequest('remove', `${endpoints.rulesEngine}/${accountId}/${id}`)
    ).message
  }

  async function getById(accountId: string, id: string): Promise<IRulesEngine> {
    return RequestApi.itemAdapter<IRulesEngine>(
      await api.doRequest('get', `${endpoints.rulesEngine}/${accountId}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function addSubscription(
    id: string, companies: Array<string>
  ): Promise<string> {
    return RequestApi.itemAdapter<string>(
      await api.doRequest('patch', `${endpoints.subscription}/${id}`, { companies }),
      (data: any) => adapter(data)
    )
  }

  async function removeSubscription(ruleId: string, companies: Array<string>): Promise<string> {
    return (
      await api.doRequest('patch', `${endpoints.removeSubscription}/${ruleId}`, { companies })
    ).message
  }

  async function updateEnabled(data: IRulesEngineUpdateSubscribeStatus): Promise<string> {
    const { ruleId, enabled, accountId } = data

    return RequestApi.itemAdapter<string>(
      await api.doRequest('patch', `${endpoints.rulesEngine}/${accountId}/${ruleId}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function evaluate(data: IRulesEngineEvaluateForm): Promise<Array<IRulesEngineEvaluate>> {
    const formatted = rulesEngineEvaluateFormAdapter(data)
    return (await api.doRequest('post', `${endpoints.evaluate}`, formatted)).message
  }

  async function getHistory(
    ruleId: string,
  ): Promise<Array<IRulesEngineHistory>> {
    return (await api.doRequest('get', `${endpoints.history}/${ruleId}`)).message
  }

  async function getHistoryDetail(
    ruleId: string,
    timeStamp: number,
    params: IPagedFilterParams
  ): Promise<any> {
    return (await api.doRequest('get', `${endpoints.history}/${ruleId}/${timeStamp}`, params)).message
  }

  return {
    list,
    getAttributes,
    search,
    getById,
    create,
    update,
    remove,
    addSubscription,
    removeSubscription,
    updateEnabled,
    evaluate,
    getHistory,
    getHistoryDetail
  }
}

export default RulesEngines
