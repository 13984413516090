import config from './@config'
import adapters from './adapters'
import API from './services/api'
import Auth from './domains/auth'
import Accounts from './domains/accounts'
import AccountsPlans from './domains/accountPlans'
import Acquirers from './domains/acquirers'
import BusinessAccounts from './domains/businessAccounts'
import Companies from './domains/companies'
import Categories from './domains/categories'
import Devices from './domains/devices'
import Departments from './domains/departments'
import Diaries from './domains/diaries'
import Invoices from './domains/invoice'
import Lists from './domains/lists'
import Products from './domains/products'
import Participants from './domains/participants'
import RulesEngines from './domains/rules-engines'
import Series from './domains/series'
import Statistics from './domains/statistics'
import Subsidiaries from './domains/subsidiaries'
import TaxMenu from './domains/tax-menu'
import Terminals from './domains/terminals'
import Timelines from './domains/timelines'
import Users from './domains/users'
import Whitelabels from './domains/whitelabels'
import Signatories from './domains/signatories'
import Webhooks from './domains/webhooks'
import Configurations from './domains/configuration'
import RuleBase from './domains/rule-base'

export default class COneAPIClient implements ICOneAPIClient {
  public static readonly ENDPOINTS = config.ENDPOINTS

  api: IAPI

  auth: IAuthAPI

  users: IUsersAPI

  whitelabels: IWhitelabelsAPI

  webhooks: IWebhooksAPI

  accounts: IAccountsAPI

  companies: ICompaniesAPI

  subsidiaries: ISubsidiariesAPI

  signatories: ISignatoryAPI

  rulesEngine: IRulesEnginesAPI

  invoices: IInvoicesAPI

  devices: IDevicesAPI

  departments: IDepartmentsAPI

  diaries: IDiariesAPI

  series: ISeriesAPI

  taxMenu: ITaxMenuAPI

  terminals: ITerminalsAPI

  timelines: ITimelinesAPI

  acquirers: IAcquirersAPI

  statistics: IStatsAPI

  lists: IListsAPI

  products: IProductsAPI

  participants: IParticipantsAPI

  accountsPlans: IAccountsPlansAPI

  categories: ICategoryAPI

  businessAccounts: IBusinessAccountsAPI

  configurations: IConfigurationAPI

  ruleBase: IRuleBaseAPI

  constructor(
    clientKey: string,
    options?: ICOneAPIClientOptions
  ) {
    const { onError, onSuccess } = options || {}

    this.api = new API({
      clientKey,
      onError,
      onSuccess
    })

    this.lists = Lists(this.api)
    this.auth = Auth(this.api)
    this.users = Users(this.api)
    this.whitelabels = Whitelabels(this.api)
    this.accounts = Accounts(this.api)
    this.companies = Companies(this.api)
    this.subsidiaries = Subsidiaries(this.api)
    this.signatories = Signatories(this.api)
    this.invoices = Invoices(this.api)
    this.rulesEngine = RulesEngines(this.api)
    this.devices = Devices(this.api)
    this.departments = Departments(this.api)
    this.diaries = Diaries(this.api)
    this.series = Series(this.api)
    this.taxMenu = TaxMenu(this.api)
    this.terminals = Terminals(this.api)
    this.timelines = Timelines(this.api)
    this.acquirers = Acquirers(this.api)
    this.products = Products(this.api)
    this.participants = Participants(this.api)
    this.accountsPlans = AccountsPlans(this.api)
    this.categories = Categories(this.api)
    this.businessAccounts = BusinessAccounts(this.api)
    this.statistics = Statistics(this.api)
    this.webhooks = Webhooks(this.api)
    this.configurations = Configurations(this.api)
    this.ruleBase = RuleBase(this.api)

    if (options && options.authorization) {
      this.auth.setAuthorization(options.authorization)
    }
  }

  getUrl(): string {
    return this.api.baseUrl
  }
}

export { adapters }
