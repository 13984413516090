const accountFormAdapter = (data: IAccountingChartForm): any => {
  const {
    ...rest
  } = data

  return {
    ...rest,
  }
}

export default accountFormAdapter
