import parseApiDate from '../../commons/parseApiDate'

import accountPlanAdapter from '../accountPlans/accountPlanAdapter'

const businessAccountAdapter = (data: any): IAccountingCode => {
  const {
    createdAt,
    updatedAt,
    accountPlan,
    ...rest
  } = data

  return {
    accountPlan: accountPlan && accountPlanAdapter(accountPlan),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default businessAccountAdapter
