import isString from './isString'

export default (date: any): IDateAPI | null => {
  if (isString(date)) {
    const dateObject = (new Date(date as string)) as IDateAPI
    dateObject.epoch = dateObject.getTime() / 1000
    dateObject.formatted = ''
      .concat('0'.concat(`${dateObject.getDate()}`).slice(-2))
      .concat('/')
      .concat('0'.concat(`${dateObject.getMonth() + 1}`).slice(-2))
      .concat('/')
      .concat(`${dateObject.getFullYear()}`)
      .concat(' ')
      .concat('0'.concat(`${dateObject.getHours()}`).slice(-2))
      .concat(':')
      .concat('0'.concat(`${dateObject.getMinutes()}`).slice(-2))
      .concat(':')
      .concat('0'.concat(`${dateObject.getSeconds()}`).slice(-2))
    return dateObject
  }

  return null
}
