import { conditionsFilter } from 'pages/rules-engines/config/listItems'
import validateFirstDigit from './validateFirstDigit'
import validateSecondDigit from './validateSecondDigit'

export default function validateIEMG(value) {
  const number = value.replace(/[^\d]+/g, '')
  const validLengths = [13, 12]

  if (!validLengths.includes(number?.length)) {
    return false
  }

  return (validateFirstDigit(number) && validateSecondDigit(number)) || false
}
