import status from 'http-status'

export default class RequestException extends Error {
  code: number

  payload: any

  constructor(code: number, message: string, payload: any) {
    super((status as any)[code] as string)

    this.name = 'RequestException'
    this.message = message
    this.code = code
    this.payload = payload
  }
}
