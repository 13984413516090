import config from '../../@config'
import RequestApi from '../../services/api'
import accountPlanNatureAdapter from './adapters/accountPlanNatureAdapter'

const endpoints = config.ENDPOINTS.list.resultsCenterList

export default (api: IAPI): IBusinessAccountsListsAPI => {
  async function getNatures(): Promise<IList<ITaxInfo>> {
    const params = { page: 1, limit: 100000 }
    return RequestApi.listAdapter<ITaxInfo>(
      await api.doRequest('get', endpoints.natures, params) as IAPIResponseList,
      accountPlanNatureAdapter
    )
  }

  async function getTypes(): Promise<IList<ITaxInfo>> {
    const params = { page: 1, limit: 100000 }
    return RequestApi.listAdapter<ITaxInfo>(
        await api.doRequest('get', endpoints.types, params) as IAPIResponseList,
        accountPlanNatureAdapter
    )
  }

  return {
    getNatures,
    getTypes,
  }
}
