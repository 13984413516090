import removeEmptyValue from '@api/src/commons/removeEmptyValue'
import formatDate, { fieldToISO } from 'commons/helpers/formatter/formatDate'

const rastroFormAdapter = (rastro: any): any => {
  return rastro?.map((item: any): any => {
    const rastroFormatted = {
      ...item,
      dFab: fieldToISO(formatDate(new Date(`${item?.dFab}T00:00`), 'YYYY-MM-DDThh:mm:ss')),
      dVal: fieldToISO(formatDate(new Date(`${item?.dVal}T00:00`), 'YYYY-MM-DDThh:mm:ss'))
    }
    return rastroFormatted && removeEmptyValue(rastroFormatted)
  })
}

export default rastroFormAdapter
