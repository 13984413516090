import config from '../../@config'
import RequestApi from '../../services/api'
import diaryAdapter from './diaryAdapter'
import diaryFormAdapter from './diaryFormAdapter'
import diaryUpdateAdapter from './diaryUpdateAdapter'
import entriesAdapter from './entriesAdapter'

interface IDiariesParams extends IPagedFilterParams {
  accountId?: string
  companyId?: string
}

interface IBalanceParams extends IPagedFilterParams {
  period?: string
  companyId: string
}
interface IBalanceEntriesParams extends IPagedFilterParams {
  period?: string
  accountingCodeId: string
}

const endpoints = config.ENDPOINTS.diaries
const sortByCreatedParams = { sort: 'desc', orderBy: 'createdAt' }

const Diaries = (api: IAPI): IDiariesAPI => {
  function adapter(response: any): IDiary {
    return diaryAdapter(response)
  }

  // Diary
  async function create(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.diaries, diaryFormAdapter(data))).message
  }

  async function update(id: string, data: any): Promise<any> {
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.diaries}/${id}`, diaryUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IDiary> {
    return RequestApi.itemAdapter<IDiary>(
      await api.doRequest('patch', `${endpoints.diaries}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }
  async function getList(params: IDiariesParams): Promise<IList<IDiary>> {
    return RequestApi.listAdapter<IDiary>(
      await api.doRequest('get', endpoints.diaries, { ...sortByCreatedParams, ...params }) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IDiary> {
    return RequestApi.itemAdapter<IDiary>(
      await api.doRequest('get', `${endpoints.diaries}/${id}`) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.diaries}/${id}`)).message
  }

  // Diary Reopen and Close functions
  async function close(id: string): Promise<string> {
    return (await api.doRequest('post', endpoints.diary.close.replace('{DIARY_ID}', id)))?.message
  }

  async function closeConfirmation(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.diary.closeConfirmation.replace('{DIARY_ID}', id), data)).message
  }

  async function reopen(id: string): Promise<string> {
    return (await api.doRequest('post', endpoints.diary.reopen.replace('{DIARY_ID}', id))).message
  }

  async function reopenConfirmation(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.diary.reopenConfirmation.replace('{DIARY_ID}', id), data)).message
  }

  // Diary Reopen and Close functions with Periods
  async function closePeriod(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.period.close.replace('{DIARY_ID}', id), data)).message
  }

  async function closeConfirmationPeriod(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.period.closeConfirmation.replace('{DIARY_ID}', id), data)).message
  }

  async function reopenPeriod(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.period.reopen.replace('{DIARY_ID}', id), data)).message
  }

  async function reopenConfirmationPeriod(id: string, data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.period.reopenConfirmation.replace('{DIARY_ID}', id), data)).message
  }

  // Entries
  async function createEntries(data: any, id: string): Promise<string> {
    return (await api.doRequest('post', endpoints.entries.entries.replace('{DIARY_ID}', id), data)).message
  }

  async function updateEntry(id: string, data: any): Promise<any> {
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.entries.entry}/${id}`, (data)),
      (data: any) => (data)
    )
  }

  async function getEntriesList(params: IPagedFilterParams, id: string): Promise<IList<IEntry>> {
    return RequestApi.listAdapter<IEntry>(
      await api.doRequest('get', endpoints.entries.entries.replace('{DIARY_ID}', id), { ...sortByCreatedParams, ...params }) as IAPIResponseList,
      (data: any) => (data)
    )
  }
  async function getByEntryId(id: string): Promise<IEntry> {
    return RequestApi.itemAdapter<IEntry>(
      await api.doRequest('get', `${endpoints.entries.entry}/${id}`) as IAPIResponseList,
      (data: any) => entriesAdapter(data)
    )
  }
  async function removeEntry(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.entries.entry}/${id}`)).message
  }

  // Items

  async function createItems(data: any, id: string): Promise<string> {
    return (await api.doRequest('post', endpoints.items.items.replace('{ENTRY_ID}', id), data)).message
  }

  async function updateItem(id: string, data: any): Promise<any> {
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.items.item}/${id}`, (data)),
      (data: any) => (data)
    )
  }

  async function getItemsList(params: IPagedFilterParams, id: string): Promise<IList<IEntry>> {
    return RequestApi.listAdapter<IEntry>(
      await api.doRequest('get', endpoints.items.items.replace('{ENTRY_ID}', id), params) as IAPIResponseList,
      (data: any) => (data)
    )
  }
  async function getByItemId(id: string): Promise<IItems> {
    return RequestApi.itemAdapter<IItems>(
      await api.doRequest('get', `${endpoints.items.item}/${id}`) as IAPIResponseList,
      (data: any) => (data)
    )
  }
  async function removeItem(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.items.item}/${id}`)).message
  }

  // Get values

  async function getDiaryAccountingCode(params: IDiariesParams): Promise<IList<any>> {
    return RequestApi.listAdapter<IEntry>(
      await api.doRequest('get', endpoints.accountingCodes, params) as IAPIResponseList,
      (data: any) => (data)
    )
  }

  async function getDiaryCategories(params: IDiariesParams): Promise<IList<any>> {
    return RequestApi.listAdapter<IEntry>(
      await api.doRequest('get', endpoints.categories, params) as IAPIResponseList,
      (data: any) => (data)
    )
  }

  async function getListTrialBalance(params: IBalanceParams): Promise<IList<IBalance>> {
    return RequestApi.listAdapter<IBalance>(
      await api.doRequest('get', endpoints.balance.trialBalance, params) as IAPIResponseList,
      (data: any) => (data)
    )
  }
  async function getListTrialBalanceCompanies(params: IBalanceParams): Promise<IList<IBalance>> {
    return RequestApi.listAdapter<IBalance>(
      await api.doRequest('get', endpoints.balance.trialBalanceCompanies, { ...sortByCreatedParams, ...params, groupBy: 'year' }) as IAPIResponseList,
      (data: any) => (data)
    )
  }

  async function getListTrialBalanceEntries(
    params: IBalanceEntriesParams
  ): Promise<IList<any>> {
    return RequestApi.listAdapter<IBalance>(
      await api.doRequest('get', endpoints.balance.trialBalanceEntries, params) as IAPIResponseList,
      (data: any) => (data)
    )
  }

  return {
    create,
    update,
    getList,
    getById,
    updateEnabled,
    remove,
    getListTrialBalance,
    getListTrialBalanceCompanies,
    getListTrialBalanceEntries,
    createEntries,
    getEntriesList,
    getByEntryId,
    removeEntry,
    updateEntry,
    createItems,
    updateItem,
    getItemsList,
    getByItemId,
    removeItem,
    getDiaryAccountingCode,
    getDiaryCategories,
    close,
    closeConfirmation,
    reopen,
    reopenConfirmation,
    closePeriod,
    closeConfirmationPeriod,
    reopenPeriod,
    reopenConfirmationPeriod
  }
}

export default Diaries
