const periods: any = {
  minute: ['02 Jul 2021 01:00:00 GMT', '02 jul 2021 01:59:59 GMT'],
  hour: ['02 Jul 2021 01:00:00 GMT', '02 jul 2021 23:59:59 GMT'],
  day: ['02 Jul 2021 01:00:00 GMT', '31 jul 2021 00:00:00 GMT'],
  month: ['02 Jul 2020 01:00:00 GMT', '31 jul 2021 00:00:00 GMT'],
  year: ['02 Jul 2015 01:00:00 GMT', '31 dec 2021 00:00:00 GMT']
}

const makeRand = (valueType: 'amount' | 'price', percent: number): number => {
  return valueType === 'amount'
    ? Math.floor(Math.random() * (5 * percent))
    : parseFloat((Math.random() * (500 * percent)).toFixed(2))
}

const STATISTICS_INVOICE = (params: IInvoiceStatisticsParams): IStatsInvoice => {
  const items = [
    'Emitidos', 'Processando', 'Cancelados', 'Rejeitados', 'Denegados',
    'Cancelamento rejeitado', 'Erro', 'Timeout no servidor'
  ]
  const itemsMultiply = [100, 10, 5, 10, 40, 1, 1, 1, 1]

  const data = {
    periodFrom: (new Date(periods[params?.groupBy || 'day'][0])) as IDateAPI,
    periodTo: (new Date(periods[params.groupBy || 'day'][1])) as IDateAPI,
    invoices: items.map((status, priority) => {
      const checkpoints = Array.from(
        Array(params.periodSize), (_, i): StatsInvoiceCheckpoint => ({
          checkpoint: (
            (params.groupBy === 'year' && `${2015 + i + 1}`)
            || (params.groupBy === 'month' && (
              new Date(2009, i + 1, 10))
              .toLocaleString('default', { month: 'short' })
              .split('.').join('')
            ) || `${i + 1}`
          ),
          amount: makeRand('amount', itemsMultiply[priority]),
          value: makeRand('price', itemsMultiply[priority])
        })
      )

      const invoice = {
        priority,
        status,
        total: checkpoints.reduce((acc, item) => acc + item.value, 0),
        checkpoints
      }

      return invoice
    })
  }
  return {
    ...data
  }
}

export default STATISTICS_INVOICE
