import React, { useContext, useEffect } from 'react'
import { CCard, CCardBody, CCardHeader, CLink } from '@coreui/react'

import { HeaderStateContext } from 'templates/context/header-state'

import LogoContabilOneColor from 'assets/images/logo-contabilone-color.svg'

const ErrorServerUnavailable: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)

  useEffect(() => {
    setTitle('O servidor está indisponível')
  }, [])

  return (
    <CCard className="p-sm-4">
      <CCardHeader>
        <div className="cone-brand-logo text-center">
          <CLink to="/">
            <img src={LogoContabilOneColor} className="cone-brand-logo" alt="Contabilone Logo" />
          </CLink>
        </div>
      </CCardHeader>

      <CCardBody>
        Servidor inacessível
      </CCardBody>
    </CCard>
  )
}

export default ErrorServerUnavailable
