const companyFormUpdateAdapter = (data: any): any => {
  const { cnpjBase, certificateFile, ...rest } = data

  return {
    ...(cnpjBase && ({ cnpjBase: (cnpjBase?.match(/\d+/g) || []).join('') })),
    ...rest
  }
}

export default companyFormUpdateAdapter
