const accountingCodeFormAdapter = (data: IAccountingCodes): any => {
  const {
    superiorCode,
    ...rest
  } = data

  return {
    accountingCodes: [
      {
        ...rest,
        ...(superiorCode === '' ? {} : { superiorCode })
      }
    ]
  }
}

export default accountingCodeFormAdapter
