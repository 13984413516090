const styles = (invalid) => {
  return ({
    control: (provided) => ({
      ...provided,
      ...((!invalid && {}) || {
        borderColor: '#e55353',
        color: '#e55353',
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e\")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
        backgroundPosition: 'right calc(0.375em + 3rem) center'
      })
    }),
    placeholder: (provided) => ({
      ...provided,
      color: (invalid && '#e55353') || provided.color
    }),
    dropdownIndicatorStyles: (provided) => ({
      ...provided,
      ...((!invalid && {}) || {
        color: '#e55353'
      })
    }),
    multiValueLabel: (provider) => ({
      ...provider,
    whiteSpace: 'normal',
    })
  })
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export {
  styles,
  groupStyles,
  groupBadgeStyles
}
