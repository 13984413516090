import { DocumentFormatted } from './functionsInvoiceAdapter';

const autXMLInvoiceFormAdapter = (
  data: any
): any => {
  const autXMLFormatted = data?.map((item: any): any => {
    return DocumentFormatted(item)
  });

  return autXMLFormatted
}

export default autXMLInvoiceFormAdapter
