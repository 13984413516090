import React from 'react'
import { CCardTitle } from '@coreui/react'

interface ICardTitle {
  title?: React.ReactNode | string,
  className?: string
}
const CardTitle: React.FC<ICardTitle> = ({
  title, className, children, ...props
}) => {
  return (
    <CCardTitle className={`${className || ''} h4 mb-0`} {...props}>
      {title}
      {children}
    </CCardTitle>
  )
}

export default CardTitle
