import Onboard from 'pages/auth/Onboard'
import routePaths from './routes-paths'

const routesGeneralFullsize: Array<IRoute> = [
  {
    component: Onboard,
    path: routePaths.ONBOARD,
    exact: false
  }
]

export default routesGeneralFullsize
