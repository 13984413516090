import React, { useContext } from 'react'

import { HeaderStateContext } from 'templates/context/header-state'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import useBreadcrumb from './useBreadcrumb'

const BreadCrumb: React.FC = () => {
  const { tab, title } = useContext(HeaderStateContext)
  const { breadcrumb, clearBreadcrumb } = useBreadcrumb()

  const items = [
    ...breadcrumb,
    ...(tab ? [{ title: tab, strong: true }] : [{ title, strong: true }]),
  ]

  return (<Breadcrumb items={items} onClickHome={clearBreadcrumb} />)
}

export default React.memo(BreadCrumb)
