import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CAlert, CCard } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import { emailValidation } from 'commons/helpers/validations/validators-rules'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import Button from 'components/form/button'
import CardBody from 'components/card/CardBody'
import InputEmail from 'components/form/InputEmail'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const RecoverPassword: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const api = useApi()
  const history = useHistory()

  const queryParams = new URLSearchParams(history.location.search)

  const { control, handleSubmit } = useForm<IRecoverPasswordForm>({
    defaultValues: { email: queryParams.get('email') || '' }
  })

  const recoverPassword: any = (email: string) => api.auth.recoverPassword(email)

  const {
    mutate, isLoading, isSuccess
  } = useMutation(recoverPassword)

  const onSubmit: SubmitHandler<IRecoverPasswordForm> = async (data: IRecoverPasswordForm) => {
    mutate(data.email as any)
  }

  const routeTo = (path: string): void => {
    history.push(makeRoute({ path }))
  }

  useEffect(() => {
    setTitle('Recuperar Conta')
  }, [])

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />

      <CardBody>
        {(!isSuccess) && (
        <>
          <h6 className="mb-3">Enviaremos um link de recuperação para o seu e-mail.</h6>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              control={control}
              rules={emailValidation}
            />
            <Button
              isLoading={isLoading}
              className="d-flex align-items-center justify-content-center mt-2 gap-2"
              icon="cil-lock-minus"
              iconSize="sm"
              size="sm"
              color="info"
              type="submit"
              block
            >
              <span className="ml-2">Recuperar senha</span>
            </Button>
          </form>
        </>
        )}

        {isSuccess && (
        <CAlert fade color="success" className="text-center mb-0">
          <h4 className="alert-heading mb-1">Pronto!</h4>
          <p className="mb-0">
            Você receberá um e-mail com as instruções para recuperação de senha.
          </p>
        </CAlert>
        )}

      </CardBody>

      <Button
        id="btn-recover-password"
        size="md"
        color="link"
        className="d-flex justify-content-center"
        title="Voltar para a página anterior"
        onClick={() => routeTo(routePaths.RECOVER_ACCOUNT)}
        block
      />
      <CardFooterLink />
    </CCard>
  )
}

export default RecoverPassword
