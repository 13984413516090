const STATISTICS_AMOUNTS: IStatsWorkspaceAmounts = {
  companies: Math.floor(Math.random() * 5),
  accounts: Math.floor(Math.random() * 10),
  subsidiaries: Math.floor(Math.random() * 50),
  terminals: Math.floor(Math.random() * 500),
  userAdmins: Math.floor(Math.random() * 15),
  userOperation: Math.floor(Math.random() * 20)
}

export default STATISTICS_AMOUNTS
