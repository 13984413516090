import parseApiDate from '../../commons/parseApiDate'

import companyAdapter from '../companies/companyAdapter'
import terminalAdapter from '../terminals/terminalAdapter'
import deviceFactoryAdapter from '../lists/adapters/deviceFactoryAdapter'
import deviceModelAdapter from '../lists/adapters/deviceModelAdapter'

const deviceAdapter = (data: any): IDevice => {
  const {
    createdAt,
    updatedAt,
    company,
    terminal,
    factory,
    model,
    terminals,
    cFeLayout,
    ...rest
  } = data

  return {
    factory: factory && deviceFactoryAdapter(factory),
    model: model && deviceModelAdapter(model),
    company: company && companyAdapter(company),
    terminals: terminals && terminals.map((terminal: any) => terminalAdapter(terminal)),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default deviceAdapter
