const webhookUpdateAdapter = (data: any): IWebhook => {
  const { accounts, accountId, whitelabelId, invoiceDistributionEvent,
    invoiceDownloadPackageEvent,
    invoiceDuplicateEvent, invoiceEvent, ...rest } = data

  return {
    ...rest,
    invoiceDownloadPackageEvent,
    invoiceDistributionEvent,
    invoiceDuplicateEvent,
    invoiceEvent
  }
}

export default webhookUpdateAdapter
