import acquirersLists from './acquirersLists'
import devicesLists from './devicesLists'
import fiscalLists from './fiscalLists'
import locationLists from './locationLists'
import taxInfo from './taxInfo'

const Lists = (api: IAPI): IListsAPI => {
  return {
    acquirers: acquirersLists(api),
    devices: devicesLists(api),
    location: locationLists(api),
    fiscal: fiscalLists(api),
    taxInfo: taxInfo(api)
  }
}

export default Lists
