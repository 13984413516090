import sessionAdapter from './domains/auth/sessionAdapter'
import userAdapter from './domains/users/userAdapter'
import whitelabelAdapter from './domains/whitelabels/whitelabelAdapter'
import accountAdapter from './domains/accounts/accountAdapter'
import companyAdapter from './domains/companies/companyAdapter'
import subsidiaryAdapter from './domains/subsidiaries/subsidiaryAdapter'
import serieAdapter from './domains/series/serieAdapter'
import deviceAdapter from './domains/devices/deviceAdapter'
import terminalAdapter from './domains/terminals/terminalAdapter'
import acquirerAdapter from './domains/acquirers/acquirerAdapter'

const adapters = {
  sessionAdapter,
  userAdapter,
  whitelabelAdapter,
  accountAdapter,
  companyAdapter,
  subsidiaryAdapter,
  serieAdapter,
  deviceAdapter,
  terminalAdapter,
  acquirerAdapter
}

export default adapters
