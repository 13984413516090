import React, { memo, Suspense } from 'react'
import {
  Route, Switch, useHistory, useLocation
} from 'react-router-dom'

import routePaths from 'routes/routes-paths'
import RoutesAuthenticated from 'routes/routes-authenticated'
import useSession from 'templates/context/session-state/useSession'
import useProfile from 'templates/context/session-state/useProfile'
import makeRoute from 'commons/helpers/route/makeRoute'
import LoadingApplication from 'components/loading/LoadingApplication'
import SignOut from 'pages/auth/SignOut'

import useSettings from './context/session-state/useSettings'
import { HeaderStateProvider } from './context/header-state'
import Footer from './footer'
import Sidebar from './sidebar'
import Header from './header'

const TemplateAuthenticated: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { hasExpired } = useSession()
  const { settings, isContabilone } = useSettings()
  const profile = useProfile()
  const { search: s, pathname } = location

  if (routePaths.SIGN_OUT === pathname) {
    return <SignOut />
  }

  if (hasExpired()) {
    const redirectTo = (pathname === '') ? undefined : { redirect: `${pathname}${s}` }

    history.push(makeRoute({
      path: routePaths.SIGN_IN,
      search: redirectTo
    }))

    return null
  }

  const onboardPath = makeRoute({ path: routePaths.ONBOARD })
  if (isContabilone && document.location.pathname !== onboardPath && !profile.isMember()) {
    history.push(onboardPath)
    return null
  }

  return (
    <div className={`c-app cone-containers-authenticated ${settings.theme === 'dark' ? 'c-dark-theme' : ''}`}>
      <Sidebar />
      <div className="c-wrapper">
        <HeaderStateProvider>
          <Header />
          <div className="c-body">
            <main className="c-main px-4">
              <Suspense fallback={<LoadingApplication />}>
                <Switch>
                  {RoutesAuthenticated.map((item, i) => {
                    const Component = item.component

                    return (
                      <Route
                        key={`${item.path + i}`}
                        exact={item.exact}
                        path={item.path}
                      >
                        <Component />
                      </Route>
                    )
                  })}
                </Switch>
              </Suspense>
            </main>
          </div>
        </HeaderStateProvider>
        <Footer />
      </div>
    </div>
  )
}

export default memo(TemplateAuthenticated)
