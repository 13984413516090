import parseApiDate from '../../commons/parseApiDate'

const userAdapter = (data: any): IUser => {
  const {
    createdAt, lastLogin, updatedAt, profiles, ...user
  } = data

  return {
    profiles: profiles || [],
    lastLogin: parseApiDate(lastLogin),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...user
  }
}

export default userAdapter
