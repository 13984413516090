import config from '../../@config'
import RequestApi from '../../services/api'
import configurationAdapter from './configurationAdapter'
import configurationFormAdapter, { configurationActionGroupFormAdapter } from './configurationFormAdapter'

const endpoints = config.ENDPOINTS.configuration

const Configurations = (api: IAPI): IConfigurationAPI => {
  function adapter(response: any): any {
    return configurationAdapter(response)
  }

  async function getDefinitions(params: IPagedFilterParams): Promise<IList<any>> {
    const accParams = { ...params, sort: 'asc', orderBy: ['name'] }
    return RequestApi.listAdapter<any>(
      await api.doRequest('get', endpoints.definitions, accParams) as IAPIResponseList,
      (data: any) => (data)
    )
  }

  async function create(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.settings, configurationFormAdapter(data))).message
  }

  async function getList(params: IPagedFilterParams): Promise<IList<any>> {
    return RequestApi.listAdapter<any>(
      await api.doRequest('get', endpoints.settings, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }
  async function getHistory(id: string): Promise<IList<any>> {
    return RequestApi.listAdapter<any>(
      await api.doRequest('get', endpoints.history.replace('{ID}', id)) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<any> {
    return RequestApi.itemAdapter<any>(
      await api.doRequest('get', `${endpoints.settings}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function update(id: string, data: any): Promise<any> {
    const { whitelabelId, accountId, companyId, subsidiaryId, ...rest } = data
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.settings}/${id}`, configurationFormAdapter(rest)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<any> {
    return RequestApi.itemAdapter<any>(
      await api.doRequest('patch', `${endpoints.settings}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.settings}/${id}`)).message
  }

  async function removeGroup(data: any): Promise<any> {
    return (await api.doRequest('post', endpoints.deleteGroup, configurationActionGroupFormAdapter(data))).message
  }

  async function editGroup(data: any): Promise<any> {
    return (await api.doRequest('patch', endpoints.editGroup, configurationActionGroupFormAdapter(data))).message
  }

  return {
    getHistory,
    getDefinitions,
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove,
    removeGroup,
    editGroup
  }
}

export default Configurations
