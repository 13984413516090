import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useMutation, useQuery } from 'react-query'
import { CCard, CCardFooter, CCol, CRow } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import lazyWithRetry from 'commons/react/lazyWithRetry'
import makeRoute from 'commons/helpers/route/makeRoute'
import useRoute from 'commons/helpers/route/useRoute'
import userViewAdapter from 'commons/adapters/userViewAdapter'
import { HeaderStateContext } from 'templates/context/header-state'
import TemplateModals from 'templates/TemplateModals'
import useApi from 'templates/context/api-state/useApi'
import useLoggedUser from 'templates/context/session-state/useLoggedUser'
import useProfile from 'templates/context/session-state/useProfile'
import useSession from 'templates/context/session-state/useSession'
import useSettings from 'templates/context/session-state/useSettings'
import { Spinner } from 'components/spinner'
import Button from 'components/form/button'
import CardBody from 'components/card/CardBody'
import CardHeader from 'components/card/CardHeader'

const WhitelabelForm: any = lazyWithRetry(() => import('pages/whitelabels/modals/WhitelabelForm'))
const AccountForm: any = lazyWithRetry(() => import('pages/accounts/modals/AccountForm'))

const Onboard: React.FC = () => {
  const api = useApi()
  const { setTitle } = useContext(HeaderStateContext)
  const [isWhitelabel, setWhitelabel] = useState(false)
  const history = useHistory()
  const { hasExpired, setSession } = useSession()
  const profile = useProfile()
  const { settings } = useSettings()
  const { routeAction } = useRoute()
  const user = userViewAdapter(useLoggedUser().loggedUser)

  const getThemeSettings = (): Promise<IWhitelabelSettings> => api.whitelabels.getThemeSettings()
  const query = useQuery(['whitelabels.getThemeSettings'], getThemeSettings, { retry: false })
  const { data: settingsData, isSuccess: isSuccessTheme } = query

  const refreshToken = async (): Promise<IAuth | null> => api.auth.refreshToken()

  const {
    data, mutate, isLoading, isSuccess, isError, error
  } = useMutation<IAuth | null>(refreshToken)

  const mainRoute = makeRoute({ path: routePaths.MAIN })

  useEffect(() => {
    if (profile.isMember()) {
      history.push(mainRoute)
    }
  }, [])

  useEffect(() => {
    setTitle('Onboard')
    if (hasExpired()) history.push(makeRoute({ path: routePaths.SIGN_IN }))
  }, [])

  useEffect(() => {
    if (isSuccess && isSuccessTheme) {
      const typedData = data as IAuth

      setSession({
        session: typedData?.session,
        user: typedData?.user,
        settings: {
          ...settings,
          whitelabelSettings:
          settingsData || settings?.whitelabelSettings || {}
        }
      })

      history.push(mainRoute)
    }
  }, [isSuccess, isSuccessTheme])

  const accountSubmitHandle = (): any => {
    mutate()
  }

  const whitelabelSubmitHandle = (): any => {
    setWhitelabel(true)
    document.location.href = makeRoute({ path: routePaths.ONBOARD_FINISH })
  }

  const modals = [
    {
      action: 'account',
      component: (props: IModalForm) => (
        <AccountForm onboard onOnboardSubmit={accountSubmitHandle} {...props} />
      )
    },
    {
      action: 'whitelabel',
      component: (props: IModalForm) => (
        <WhitelabelForm onboard onOnboardSubmit={whitelabelSubmitHandle} {...props} />
      )
    }
  ]

  return (
    <>
      <TemplateModals modals={modals} />

      <CCard className="p-2 p-md-4 ">
        <CardHeader
          className="text-center"
          classNameTitle="display-4"
          title={<>Seja bem-vindo a Plataforma Contabilone, <strong>{user.getName()}</strong>!</>}
        />
        <CardBody>
          <div className="text-center mb-5">
            <p className="h5">Seu registro foi concluído com sucesso.</p>
            <p className="h5">Por favor, selecione o perfil de uso mais adequado para o seu negócio.</p>
          </div>

          <CRow className="mb-5">
            <CCol md="6" className="text-center">
              <Button
                className="mb-4 text-white"
                iconSize="lg"
                size="lg"
                icon="cil-library-building"
                title="Registrar uma Conta"
                color="warning"
                to={routeAction('account')}
                disabled={isLoading || isWhitelabel}
              />
            </CCol>

            <CCol md="6" className="text-center">
              <Button
                className="text-white"
                icon="cil-blur"
                iconSize="lg"
                size="lg"
                title="Configurar meu próprio Domínio"
                color="info"
                disabled={isLoading || isWhitelabel}
                to={routeAction('whitelabel')}
              />
            </CCol>
          </CRow>

          <CCardFooter>
            {isLoading && (
            <span className="d-flex justify-content-end align-items-center">
              Redirecionando <Spinner className="ml-3" />
            </span>
            )}
            {isError && (
            <span className="text-danger d-flex justify-content-end align-items-center">
              {(error as IAPIException)?.message}
            </span>
            )}
          </CCardFooter>
        </CardBody>
      </CCard>
    </>
  )
}

export default Onboard
