import config from '../../@config'
import RequestApi from '../../services/api'
import departmentsAdapter from './departmentsAdapter'

const endpoints = config.ENDPOINTS.departments

const Departments = (api: IAPI): IDepartmentsAPI => {
  function adapter(response: any): IDepartment {
    return departmentsAdapter(response)
  }

  async function getList(
    params: IPagedFilterParams
  ): Promise<IList<IDepartment>> {
    return RequestApi.listAdapter<IDepartment>(
      await api.doRequest('get', endpoints.departments, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IDepartment> {
    return RequestApi.itemAdapter<IDepartment>(
      await api.doRequest('get', `${endpoints.departments}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IDepartmentForm): Promise<string> {
    return (await api.doRequest('post', endpoints.departments, data)).message
  }

  async function update(id: string, data: IDepartmentForm): Promise<IDepartment> {
    return RequestApi.itemAdapter<IDepartment>(
      await api.doRequest('patch', `${endpoints.departments}/${id}`, data),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IDepartment> {
    return RequestApi.itemAdapter<IDepartment>(
      await api.doRequest('patch', `${endpoints.departments}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.departments}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Departments
