import { useContext, useEffect, useState } from 'react'
import useRoute from 'commons/helpers/route/useRoute'
import { useHistory, useParams } from 'react-router'
import routePaths from 'routes/routes-paths'
import { HeaderStateContext } from 'templates/context/header-state'
import { formatCompanyLabel, formatSubsidiaryLabel } from './breadcrumbLabelView'

const useBreadcrumb: any = () => {
  const {
    newBreadcrumb,
    setNewBreadcrumb,
  } = useContext(HeaderStateContext)
  const { location } = useHistory()
  const { slug }: any = useParams()
  const breadcrumbSession = sessionStorage.getItem('breadcrumb') || ''
  const currentBreadcrumb: any = breadcrumbSession ? JSON.parse(breadcrumbSession) : []
  const slugIsEqual = slug && currentBreadcrumb.some(({ id }: any) => id === slug)
  const isInvoice = location.pathname.includes('invoice/')
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (slugIsEqual || isInvoice || update) {
      setNewBreadcrumb(currentBreadcrumb)
      setUpdate(false)
    }
    if (!breadcrumbSession?.length && !isInvoice) {
      setNewBreadcrumb([])
    }
  }, [breadcrumbSession, isInvoice])

  const addBreadcrumb: any = (value: Array<IBreadcrumbItem>) => {
    sessionStorage.setItem('breadcrumb', JSON.stringify(value))
    setUpdate(true)
  }
  const { routeTab, makeRoute } = useRoute()

  const addBreadcrumbSubsidiary: any = (node: any, subsidiary: any, companyValue?: any) => {
    const company = subsidiary?.company || companyValue
    const { account } = company || {}
    const { whitelabel } = account || {}

    const route = company?.id && routeTab({ path: makeRoute({ path: routePaths.COMPANY, params: { slug: company?.id } }), tab: 'subsidiaries' })

    const breadcrumb: Array<IBreadcrumbItem> = [
      ...(whitelabel ? [{ type: 'whitelabel', title: whitelabel?.name, id: whitelabel?.id }] : []),
      ...(account ? [{ type: 'account', title: account?.name, id: account?.id }] : []),
      ...(company ? [{ type: 'company', title: formatCompanyLabel(company?.name, company?.cnpjBase), id: company?.id }] : []),
      ...(subsidiary ? [
        { type: 'subsidiaries', title: 'Estabelecimentos', to: route },
        { type: 'subsidiary',
          title: formatSubsidiaryLabel(subsidiary?.cnpj, subsidiary?.ie, subsidiary?.xFant),
          id: subsidiary?.id },
      ] : []),
      ...node
    ]
    sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb))
    setUpdate(true)
  }

  const breadcrumbMakeRoute: any = (tab: string, subsidiaryId?: string, companyId?:string) => {
    if (subsidiaryId || companyId) {
      const path = subsidiaryId
        ? makeRoute({ path: routePaths.SUBSIDIARY, params: { slug: subsidiaryId } })
        : makeRoute({ path: routePaths.COMPANY, params: { slug: companyId } })
      const route = routeTab({ path, tab })
      return route
    }
    return ''
  }

  const clearBreadcrumb: any = () => {
    sessionStorage.removeItem('breadcrumb')
  }

  const breadcrumb = Array.isArray(newBreadcrumb)
    ? newBreadcrumb?.filter(({ title }: any) => !!title)
      .map((item: any) => {
        const to = item.to === undefined && item.id && item.type ? `/${item.type}/${item.id}` : ''
        return {
          ...item,
          title: item?.title?.toUpperCase(),
          to: item.to ? item?.to : to,
          onClick: (e: any) => {
            if (!e.ctrlKey) {
              const index = currentBreadcrumb.findIndex(({ id }: any) => id === item.id)
              const breadcrumbSliced = currentBreadcrumb.slice(0, index + 1)
              addBreadcrumb(breadcrumbSliced)
            }
          }
        }
      }) : []

  return {
    breadcrumb,
    addBreadcrumb,
    addBreadcrumbSubsidiary,
    breadcrumbMakeRoute,
    clearBreadcrumb }
}

export default useBreadcrumb
