import isCurrencyFormat from '../../commons/isCurrencyFormat'
import isString from '../../commons/isString'
import removeCurrencyFormat from '../../commons/formatApiCurency'

const checkAllValues = (data: any): any => {
  return (isString(data) && (isCurrencyFormat(data) ? removeCurrencyFormat(data) : data))
    || (Array.isArray(data) && data.reduce((acc, value) => {
      if (value === null || value === 'description') return acc
      return [
        ...acc,
        checkAllValues(value)
      ]
    }, []))
    || (typeof data === 'object' && Object.keys(data).reduce((acc, key) => {
      if (data[key] === null || key === 'description') return acc
      if (key === 'content') return data[key]
      if (key === 'det') {
        return {
          ...acc,
          [key]: [checkAllValues(data[key])]
        }
      }
      return {
        ...acc,
        [key]: checkAllValues(data[key])
      }
    }, {}))
    || data
}

const rulesEngineEvaluateFormAdapter = (data: IRulesEngineEvaluateForm): any => {
  return checkAllValues(data)
}

export default rulesEngineEvaluateFormAdapter
