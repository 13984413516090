import parseApiDate from '../../../commons/parseApiDate'

const deviceFactoryAdapter = ({ createdAt, updatedAt, ...rest }: any): IDeviceFactory => {
  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest,
  }
}

export default deviceFactoryAdapter
