const formatNumberMask = (
  value: any, mask: string | Array<{ mask: string }>, end?: boolean
): string => {
  if (Number.isNaN(parseInt((value)))) return value

  if (Array.isArray(mask)) {
    const theMask = mask.find(({ mask }): any => (
      (`${value}`.length === mask.replace(/[^\d]+/g, '').length)
    ))?.mask

    return (theMask)
      ? formatNumberMask(value, theMask, false)
      : value
  }

  const maskLength = mask.replace(/[^\d]+/g, '').length

  value = end ? String(value).padEnd(maskLength, '0') : String(value).padStart(maskLength, '0')

  const v = value.match(/\d+/g).join('')
  let i = 0

  // eslint-disable-next-line
  return mask.replace(/0/g, (_) => v[i++]).replace('undefined', '')
}

export default formatNumberMask
