import React, { memo } from 'react'
import { CLink } from '@coreui/react'

import useSettings from 'templates/context/session-state/useSettings'
import CardHeader from 'components/card/CardHeader'

const CardHeaderLogo: React.FC = () => {
  const { settings: { whitelabelSettings } } = useSettings()

  const { darkLogoUrl, name } = whitelabelSettings

  return (
    <CardHeader noCardHeaderClassName>
      <div className="p-0 cone-brand-logo text-center">
        <CLink to="/">
          {!darkLogoUrl && (
          <h1>{name}</h1>
          )}
          {darkLogoUrl && (
            <img
              src={`${darkLogoUrl}?
              ${(new Date()).getTime()}`}
              className="cone-brand-logo"
              alt={name || 'Contabilone Logo'}
            />
          )}
        </CLink>
      </div>
    </CardHeader>
  )
}

export default memo(CardHeaderLogo)
