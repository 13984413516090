import config from '../../@config'
import RequestApi from '../../services/api'
import subsidiaryAdapter from './subsidiaryAdapter'
import subsidiaryFormAdapter from './subsidiaryFormAdapter'

const endpoints = config.ENDPOINTS.subsidiaries

const Subsidiaries = (api: IAPI): ISubsidiariesAPI => {
  function adapter(response: any): ISubsidiary {
    return subsidiaryAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ISubsidiary>> {
    const subParams = { ...params, sort: 'asc', orderBy: ['cnpj', 'ie'] }

    return RequestApi.listAdapter<ISubsidiary>(
      await api.doRequest('get', endpoints.subsidiaries, subParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ISubsidiary> {
    return RequestApi.itemAdapter<ISubsidiary>(
      await api.doRequest('get', `${endpoints.subsidiaries}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: ISubsidiaryForm): Promise<{message: string, id: string}> {
    delete data.tmp
    const result = (await api.doRequest('post', endpoints.subsidiaries, subsidiaryFormAdapter(data)))
    return { message: result.message, id: result.id }
  }

  async function update(id: string, data: ISubsidiaryForm): Promise<ISubsidiary> {
    const payload = subsidiaryFormAdapter(data)

    delete payload.companyId
    return RequestApi.itemAdapter<ISubsidiary>(
      await api.doRequest('patch', `${endpoints.subsidiaries}/${id}`, payload),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ISubsidiary> {
    return RequestApi.itemAdapter<ISubsidiary>(
      await api.doRequest('patch', `${endpoints.subsidiaries}/${id}`, { identification: { enabled } }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.subsidiaries}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Subsidiaries
