import config from '../../@config'
import RequestApi from '../../services/api'
import taxMenuAdapter from './taxMenuAdapter'

const endpoints = config.ENDPOINTS.taxMenu

const TaxMenu = (api: IAPI): ITaxMenuAPI => {
  function adapter(response: any): any {
    return taxMenuAdapter(response)
  }

  async function generate(data: any): Promise<string> {
    return (await api.doRequest('post', endpoints.generate, data)).message
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ITaxMenu>> {
    return RequestApi.listAdapter<ITaxMenu>(
      await api.doRequest('get', endpoints.list, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  return {
    getList,
    generate,
  }
}

export default TaxMenu
