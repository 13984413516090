import cleanNumber from '../../commons/cleanNumber'

const subsidiaryCountyTaxFormAdapter = (
  data: ISubsidiaryCountyTaxForm
): ISubsidiaryCountyTaxForm => {
  const {
    countyTaxes,
    ...rest
  } = data

  return {
    countyTaxes: countyTaxes?.map(({ tmp, im, ...rest }) => (
      { im: cleanNumber(im), ...rest }
    )),
    ...rest
  }
}

export default subsidiaryCountyTaxFormAdapter
