import { createContext } from 'react'

const props: IToasterStateContext = {
  toaster: [],
  addToasts: () => null,
  clean: () => null
}

const ToasterStateContext = createContext(props)

export default ToasterStateContext
