import config from '../../@config'
import RequestApi from '../../services/api'
import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'
import companyAdapter from './companyAdapter'
import companyFormAdapter from './companyFormAdapter'
import companyFormUpdateAdapter from './companyFormUpdateAdapter'

const endpoints = config.ENDPOINTS.companies

const Companies = (api: IAPI): ICompaniesAPI => {
  function adapter(response: any): ICompany {
    return companyAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ICompany>> {
    const companyParams = { ...params, sort: 'asc', orderBy: ['cnpjBase'] }
    return RequestApi.listAdapter<ICompany>(
      await api.doRequest('get', endpoints.companies, companyParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ICompany> {
    return RequestApi.itemAdapter<ICompany>(
      await api.doRequest('get', `${endpoints.companies}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function getSubsidiaryHeadquarter(id: string): Promise<ISubsidiary> {
    return RequestApi.itemAdapter<ISubsidiary>(
      await api.doRequest('get', `${endpoints.companyGetHeadquarter}`.replace('{COMPANY_ID}', id)),
      (data: any) => subsidiaryAdapter(data)
    )
  }

  async function create(data: ICompanyForm): Promise<string> {
    return (await api.doRequest('post', endpoints.companies, companyFormAdapter(data))).message
  }

  async function update(id: string, data: ICompanyForm): Promise<ICompany> {
    return RequestApi.itemAdapter<ICompany>(
      await api.doRequest('patch', `${endpoints.companies}/${id}`, companyFormUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ICompany> {
    return RequestApi.itemAdapter<ICompany>(
      await api.doRequest('patch', `${endpoints.companies}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.companies}/${id}`)).message
  }

  return {
    getList,
    getById,
    getSubsidiaryHeadquarter,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Companies
