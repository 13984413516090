import parseApiDate from '../../../commons/parseApiDate'

const addressAdapter = ({
  createdAt, updatedAt,
  startDate, endDate,
  ...rest
}: any): IAddress => {
  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    ...rest,
  }
}

export default addressAdapter
