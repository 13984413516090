import config from '../../@config'
import RequestApi from '../../services/api'
import webhookAdapter from './webhookAdapter'
import webhookCreateAdapter from './webhookCreateAdapter'
import webhookDeliveriesAdapter from './webhookDeliveriesAdapter'
import webhookUpdateAdapter from './webhookUpdateAdapter'

const endpoints = config.ENDPOINTS.webhooks

const Webhooks = (api: IAPI): IWebhooksAPI => {
  function adapter(response: any): IWebhook {
    return webhookAdapter(response)
  }

  function adapterDeliveries(response: any): IWebhookDeliveriesList {
    return webhookDeliveriesAdapter(response)
  }

  async function create(data: IWebhookForm): Promise<string> {
    return (await api.doRequest('post', endpoints.webhooks, webhookCreateAdapter(data))).message
  }

  async function update(id: string, data: IWebhookForm): Promise<IWebhook> {
    return RequestApi.itemAdapter<IWebhook>(
      await api.doRequest('patch', `${endpoints.webhooks}/${id}`, webhookUpdateAdapter(data)),
      (data: any) => webhookUpdateAdapter(data)
    )
  }
  async function updateEnabled(id: string, enabled: boolean): Promise<IWebhook> {
    return RequestApi.itemAdapter<IWebhook>(
      await api.doRequest('patch', `${endpoints.webhooks}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.webhooks}/${id}`)).message
  }

  async function getById(id: string): Promise<IWebhook> {
    return RequestApi.itemAdapter<IWebhook>(
      await api.doRequest('get', `${endpoints.webhooks}/${id}`)
    )
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IWebhook>> {
    return RequestApi.listAdapter<IWebhook>(
      await api.doRequest('get', endpoints.webhooks, params) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getDeliveries(params: IPagedFilterParams): Promise<IList<IWebhookDeliveriesList>> {
    return RequestApi.listAdapter<IWebhookDeliveriesList>(
      await api.doRequest('get', endpoints.deliveries, params) as IAPIResponseList,
      (data: any) => adapterDeliveries(data)
    )
  }

  async function getDeliveryById(id: string): Promise<IWebhookDelivery> {
    return RequestApi.itemAdapter<IWebhookDelivery>(
      await api.doRequest('get', `${endpoints.deliveries}/${id}`)
    )
  }

  async function resend(id: string): Promise<string> {
    return (await api.doRequest('post', `${endpoints.resend.replace('{ID}', id)}`)).message
  }

  return {
    create,
    update,
    updateEnabled,
    remove,
    getById,
    getList,
    getDeliveries,
    getDeliveryById,
    resend
  }
}

export default Webhooks
