import parseApiDate from '@api/src/commons/parseApiDate'

const entriesAdapter = (data: any): IEntry => {
  const {
    createdAt, updatedAt,
    extemporaneousEntryDate,
    entryDate,
    ...rest
  } = data

  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    entryDate: parseApiDate(entryDate),
    extemporaneousEntryDate: parseApiDate(extemporaneousEntryDate),
    ...rest,
  }
}

export default entriesAdapter
