import React, { useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { CCard, CAlert } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import useSession from 'templates/context/session-state/useSession'
import useSettings from 'templates/context/session-state/useSettings'
import { Spinner } from 'components/spinner'
import CardBody from 'components/card/CardBody'
import CardHeaderLogo from './CardHeaderLogo'

const SignInConfirmation: React.FC = () => {
  const { service }: any = useParams()
  const { setTitle } = useContext(HeaderStateContext)
  const { setSession, clean } = useSession()
  const { settings } = useSettings()
  const api = useApi()
  const history = useHistory()

  const queryParams = new URLSearchParams(history.location.search)
  const code = queryParams.get('code')
  const state = queryParams.get('state')
  const signInPath = makeRoute({ path: routePaths.SIGN_IN })

  if (state) {
    document.location.href = `${state}?code=${code}`
    return null
  }

  const signInConfirmation: any = () => api.auth.doLoginByOauth(service, code as string)

  const { mutate, data, isLoading, isSuccess, isError, error } = useMutation(signInConfirmation)

  const subTitle = (service === 'nexaas-id' && 'Nexaas-Id')
    || (service === 'google' && 'Google')
    || (service === 'linkedin' && 'Linkedin')
    || ''

  useEffect((): void => {
    if (data) {
      const typedData = data as IAuth

      setSession({
        session: typedData?.session,
        user: typedData?.user,
        settings: {
          ...settings,
          whitelabelSettings: typedData.settings || {}
        }
      })

      setTimeout(() => history.push(makeRoute({ path: routePaths.MAIN })), 2000)
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      history.push(signInPath)
    }
  }, [isError])

  useEffect((): void => {
    setTitle(`Autorização ${subTitle}`)
    clean()
    mutate()
  }, [])

  const errorMessage = (error as IAPIException)?.message || ''

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />
      <CardBody>
        {!isSuccess && (
          <h5 className="text-center mb-3">
            <strong>{subTitle}</strong>
          </h5>
        )}

        {isLoading && (
          <CAlert fade show color="secondary" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Buscando seus dados
            </h4>
            <Spinner className="m-auto" />
            <p>Validando acesso</p>
          </CAlert>
        )}

        {isSuccess && (
          <CAlert fade show color="success" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Pronto
            </h4>
            <Spinner className="m-auto" />
            <p>Redirecionando para o sistema</p>
          </CAlert>
        )}

        {isError && (
          <CAlert fade show color="warning" className="text-center m-0">
            <h4 className="alert-heading mb-4">
              Ocorreu algum erro
            </h4>
            <p>{errorMessage}</p>
          </CAlert>
        )}
      </CardBody>
    </CCard>
  )
}

export default SignInConfirmation
