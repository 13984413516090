import removeEmptyValue from '@api/src/commons/removeEmptyValue'
import rastroFormAdapter from './helpers/rastroFormAdapter'
import armaFormAdapter from './helpers/armaFormAdapter'
import imageFormAdapter from './helpers/imageFormAdapter'

const productUpdateAdapter = (product: IProductForm): any => {
  const {
    veicProd, med, arma, comb, papel,
    rastro, productImages, productParticipants,
    CNPJFab, NVE, highlighted, ...data
  } = product

  const specificProdProps = comb
    || med || papel || veicProd || armaFormAdapter(product)

  const rastroFormatted = rastroFormAdapter(rastro)
  const imagesFormatted = imageFormAdapter(productImages)
  const formattedQuantityProps = data?.quantity

  return {
    ...data,
    ...((rastroFormatted?.length === 0) ? { rastro: null } : { rastro: rastroFormatted }),
    ...(CNPJFab ? { CNPJFab: CNPJFab?.replace(/[^0-9]/g, '') } : { CNPJFab }),
    ...(CNPJFab === null && { CNPJFab }),
    ...((NVE?.length === 0) ? { NVE: null } : { NVE }),
    ...(formattedQuantityProps && removeEmptyValue(formattedQuantityProps)),
    ...(specificProdProps && removeEmptyValue(specificProdProps)),
    ...((imagesFormatted?.length === 0) ? { productImages: null }
      : { productImages: imagesFormatted }),
    ...((productParticipants?.length === 0) ? { productParticipants: null }
      : { productParticipants })
  }
}

export default productUpdateAdapter
