import { useEffect, useState } from 'react'

import COneAPIClient from '@api/src'

import ApiContext from './ApiContext'
import useApiErrorHandler from './api-handler/useApiErrorHandler'
import useApiSuccessHandler from './api-handler/useApiSuccessHandler'
import useSession from '../session-state/useSession'

const apiKey = process.env.REACT_APP_CONE_API_KEY || ''

const ApiProvider: any = ({ children }: any) => {
  const { session } = useSession()
  const onError = useApiErrorHandler()
  const onSuccess = useApiSuccessHandler()

  const api = new COneAPIClient(
    apiKey,
    {
      authorization: session?.token,
      onError,
      onSuccess
    }
  )

  useEffect(() => {
    api.auth.setAuthorization(session?.token)
  }, [session?.token])

  const [state] = useState(api)

  return (
    <ApiContext.Provider value={state}>
      {children}
    </ApiContext.Provider>
  )
}

export default ApiProvider
