import { Suspense, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import generalFullsizeRoutes from 'routes/routes-general-fullsize'
import generalRoutes from 'routes/routes-general'
import routePaths from 'routes/routes-paths'
import Styles from 'templates/styles'
import TemplateAuthenticated from 'templates/TemplateAuthenticated'
import TemplateAuthenticatedFullsize from 'templates/TemplateAuthenticatedFullsize'
import TemplateGeneral from 'templates/TemplateGeneral'
import Toaster from 'templates/toaster'
import useApi from 'templates/context/api-state/useApi'
import useSettings from 'templates/context/session-state/useSettings'
import LoadingApplication from 'components/loading/LoadingApplication'
import Main from 'pages/Main'
import NotFound from 'pages/not-found/NotFound'
import SignInConfirmationRedirect from 'pages/auth/SignInConfirmationRedirect'

import './assets/icons'
import './styles'

const AppRouter: React.FC = () => {
  const api = useApi()

  const { settings, setSettings } = useSettings()
  const getThemeSettings = (): Promise<IWhitelabelSettings> => api.whitelabels.getThemeSettings()
  const query = useQuery(['whitelabels.getThemeSettings'], getThemeSettings, { retry: false })
  const { isLoading, isSuccess, data } = query

  useEffect(() => {
    const { name } = data || {}
    if (data?.backgroundUrl) {
      localStorage.setItem('backgroundUrl', data?.backgroundUrl)
    }

    if (data?.darkLogoUrl) {
      localStorage.setItem('darkLogoUrl', data?.darkLogoUrl)
    }

    if (data?.name === 'Contabilone') {
      localStorage.setItem('isContabilone', 'true')
    }

    if (data?.whitelabelId === '227475bf-9a0b-453f-8b33-1afa6e52240b') {
      localStorage.setItem('isWhitelabelContabilone', 'true')
    }

    if (settings?.whitelabelSettings?.name !== name) {
      setSettings({
        ...settings,
        whitelabelSettings: data
      })
    }

    if (data?.darkIconUrl) {
      const links = [...document.getElementsByTagName('link') as any]
      links.forEach((link: any) => {
        if (['apple-touch-icon', 'icon'].includes(link.rel)) {
          link.href = data?.darkIconUrl
        }
      })
    }
  }, [isSuccess])

  const backgroundUrl = localStorage.getItem('backgroundUrl') || settings?.whitelabelSettings?.backgroundUrl
  const backgroundStyle = useMemo(() => {
    return (backgroundUrl && { backgroundImage: `url('${backgroundUrl}?${(new Date()).getTime()}')` })
    || { background: 'linear-gradient(to bottom,  #444964, #808194, #bebdc8, #fefefe)' }
  }, [backgroundUrl])

  return (
    <Router>
      <Styles />
      <Toaster />

      {isLoading && (<LoadingApplication />)}
      {!isLoading && (
        <Suspense fallback={<LoadingApplication />}>
          <Switch>
            <Route exact path={routePaths.MAIN} component={Main} />
            <Route
              exact
              path={routePaths.SIGN_IN_CONFIRMATION_REDIRECT}
              component={SignInConfirmationRedirect}
            />

            {generalRoutes.map(({ path, component: Component }) => (
              <Route exact path={path} key={`${path}`}>
                <TemplateGeneral backgroundStyle={backgroundStyle}>
                  <Component />
                </TemplateGeneral>
              </Route>
            ))}

            {generalFullsizeRoutes.map(({ exact, path, component }) => (
              <TemplateAuthenticatedFullsize
                key={`${path}`}
                exact={exact}
                path={path}
                component={component}
              />
            ))}

            <TemplateAuthenticated />

            <Route exact path="*">
              <TemplateGeneral>
                <NotFound />
              </TemplateGeneral>
            </Route>
          </Switch>
        </Suspense>
      )}
    </Router>
  )
}

export default AppRouter
