import cleanNumber from '../../commons/cleanNumber'

const subsidiaryStateTaxFormAdapter = (data: ISubsidiaryStateTaxForm): ISubsidiaryStateTaxForm => {
  const {
    stateTaxes,
    ...rest
  } = data

  return {
    stateTaxes: stateTaxes?.map(({ ie, crtEspeciais, ...rest }) => (
      { ie: cleanNumber(ie), crtEspecial: crtEspeciais, ...rest }
    )),
    ...rest
  }
}

export default subsidiaryStateTaxFormAdapter
