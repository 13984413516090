import { useContext } from 'react'
import { useLocation } from 'react-router'

import profileTypes from '@config/profile-types'
import userAdapter from '@api/src/domains/users/userAdapter'

import SessionStateContext from './SessionStateContext'
import useSettings from './useSettings'

export default function useProfile(): IUseProfile {
  const { settings } = useSettings()
  const location = useLocation()
  const { data } = useContext<ISessionStateContext>(SessionStateContext)

  const searchParams = new URLSearchParams(location.search)
  const whitelabelId = searchParams.get('whitelabelId') || ''
  const accountId = searchParams.get('accountId') || ''
  const companyId = searchParams.get('companyId') || ''
  const subsidiaryId = searchParams.get('subsidiaryId') || ''

  const loggedUser = data?.user && userAdapter(data?.user)

  const userProfileByCurrentDomain = loggedUser?.profiles?.filter(
    ({ whitelabel, profile }) => profile === profileTypes.STAFF.value
    || whitelabel?.id === settings?.whitelabelSettings?.whitelabelId
  )

  const currentProfile = (user?: IUser): IUserProfile | null => {
    const profiles = user ? user?.profiles : userProfileByCurrentDomain

    if (user) {
      return (profiles?.find(({ profile }) => (
        profile === profileTypes.STAFF.value
      ))) || (profiles?.find(({ profile }) => (
        profile === profileTypes.WHITELABEL_OWNER.value
      ))) || (profiles?.find(({ profile }) => (
        profile === profileTypes.ACCOUNT_OWNER.value
      ))) || (profiles?.find(({ profile }) => (
        profile === profileTypes.COMPANY_MANAGER.value
      ))) || (profiles?.find(({ profile }) => (
        profile === profileTypes.SUBSIDIARY_MEMBER.value
      ))) || (profiles?.find(({ profile }) => (
        profile === profileTypes.SUBSIDIARY_VIEWER.value
      ))) || null
    }

    // REFACTOR
    return (profiles?.find(({ profile }) => (
      profile === profileTypes.STAFF.value
    ))) || (profiles?.find(({ profile, whitelabel }) => (
      profile === profileTypes.WHITELABEL_OWNER.value && (
        !whitelabelId || whitelabel?.id === whitelabelId
      )
    ))) || (profiles?.find(({ profile, account }) => (
      profile === profileTypes.ACCOUNT_OWNER.value && (
        !accountId || account?.id === accountId
      )
    ))) || (profiles?.find(({ profile, company }) => (
      profile === profileTypes.COMPANY_MANAGER.value && (
        !companyId || company?.id === companyId
      )
    ))) || (profiles?.find(({ profile, subsidiary }) => (
      profile === profileTypes.SUBSIDIARY_MEMBER.value && (
        !subsidiaryId || subsidiary?.id === subsidiaryId
      )
    ))) || (profiles?.find(({ profile, subsidiary }) => (
      profile === profileTypes.SUBSIDIARY_VIEWER.value && (
        !subsidiaryId || subsidiary?.id === subsidiaryId
      )
    ))) || null
  }

  const isStaff = (): boolean => (
    currentProfile()?.profile === profileTypes.STAFF.value
  )

  const whitelabelOwnerValidation = (): boolean => (
    currentProfile()?.profile === profileTypes.WHITELABEL_OWNER.value || isStaff()
  )

  const accountOwnerValidation = (): boolean => (
    currentProfile()?.profile === profileTypes.ACCOUNT_OWNER.value || whitelabelOwnerValidation()
  )

  const companyManagerValidation = (profilesFind?: Array<IProfileType>): boolean => {
    if (!profilesFind?.length) {
      return currentProfile()?.profile === profileTypes.COMPANY_MANAGER.value
      || accountOwnerValidation()
    }
    return !(profilesFind.includes('SUBSIDIARY_MEMBER') || profilesFind.includes('SUBSIDIARY_VIEWER'))
  }

  const subsidiaryMemberValidation = (profilesFind?: Array<IProfileType>): boolean => {
    if (!profilesFind?.length) {
      return currentProfile()?.profile === profileTypes.SUBSIDIARY_MEMBER.value
       || companyManagerValidation()
    }

    return !(profilesFind.includes('SUBSIDIARY_VIEWER'))
  }

  const isGestao = (user: IUserView): boolean => {
    return user?.email === 'gestao@contabilone.com'
  }

  const isMember = (): boolean => {
    return isStaff() || (loggedUser?.profiles?.filter((data: any) => (
      data.whitelabel?.domain === window.location.host
    )) || []).length > 0
  }

  const isAllowed = (user?: IUserView): boolean => {
    return profileTypes[currentProfile()?.profile || '']?.levelNumber <= profileTypes[currentProfile(user)?.profile || '']?.levelNumber
  }

  const isWhitelabelOwner = (id?: string): boolean => {
    const profilesFind = id ? userProfileByCurrentDomain
      ?.filter((item) => item?.whitelabel?.id === id)
      ?.map((value) => value.profile) : []

    if (!profilesFind?.length) {
      return whitelabelOwnerValidation()
    }

    return !!(profilesFind.includes('WHITELABEL_OWNER') || profilesFind.includes('STAFF'))
  }

  const isAccountOwner = (id?: string): boolean => {
    const profilesFind = id ? userProfileByCurrentDomain
      ?.filter((item) => item?.account?.id === id)
      ?.map((value) => value.profile) : []

    if (!profilesFind?.length) {
      return accountOwnerValidation()
    }

    return !!(profilesFind.includes('ACCOUNT_OWNER'))
  }

  const isCompanyManager = (id?: string): boolean => {
    const profilesFind = id ? userProfileByCurrentDomain
      ?.filter((item) => item?.company?.id === id)
      ?.map((value) => value.profile) : []

    if (!profilesFind?.length) {
      return companyManagerValidation()
    }

    return !!(profilesFind.includes('COMPANY_MANAGER'))
  }

  const isCompanyManagerWithAccountId = (accountId?: string): boolean => {
    const profilesFind = userProfileByCurrentDomain
      ?.filter((item) => item?.account?.id === accountId)
      ?.map((value) => value.profile)

    return companyManagerValidation(profilesFind)
  }

  const isSubsidiaryMember = (id?: string): boolean => {
    const profilesFind = id ? userProfileByCurrentDomain
      ?.filter((item) => item?.subsidiary?.id === id)
      ?.map((value) => value.profile) : []

    return subsidiaryMemberValidation(profilesFind)
  }

  const isSubsidiaryMemberWithAccountId = (accountId?: string): boolean => {
    const profilesFind = userProfileByCurrentDomain
      ?.filter((item) => item?.account?.id === accountId)
      ?.map((value) => value.profile)

    return subsidiaryMemberValidation(profilesFind)
  }

  const isSubsidiaryMemberWithCompanyId = (company?: string): boolean => {
    const profilesFind = userProfileByCurrentDomain
      ?.filter((item) => item?.company?.id === company)
      ?.map((value) => value.profile)

    return subsidiaryMemberValidation(profilesFind)
  }

  const isSameEntity = (profile: IUserProfile): boolean => {
    const loggedProfiles = loggedUser?.profiles
    const { profile: currentProfile, whitelabel } = profile
    const currentProfileValue = profileTypes[currentProfile].levelNumber
    const sameEntity = loggedProfiles
      ?.find((profile) => profile.whitelabel?.id === whitelabel?.id)
    const loggedProfile = sameEntity?.profile
    const loggedValue = loggedProfile ? profileTypes[loggedProfile].levelNumber : undefined

    return loggedValue ? currentProfileValue >= loggedValue : false
  }

  return {
    isStaff,
    isWhitelabelOwner,
    isAccountOwner,
    isCompanyManager,
    isCompanyManagerWithAccountId,
    isSubsidiaryMember,
    isSubsidiaryMemberWithAccountId,
    isSubsidiaryMemberWithCompanyId,
    currentProfile,
    isGestao,
    isMember,
    isAllowed,
    isSameEntity
  }
}
