import { useState } from 'react'
import HeaderStateContext from './HeaderStateContext'

const HeaderStateProvider: any = ({ children }: any) => {
  const [breadcrumb, setBreadcrumb] = useState({})
  const [newBreadcrumb, setNewBreadcrumb] = useState({})
  const [tab, setTab] = useState('')
  const [title, setStateTitle] = useState('')
  const [header, setHeader] = useState(null)

  const setTitle = (title: string): void => {
    setStateTitle(title)
    document.title = title ? `${title} - Contabilone` : 'Contabilone'
  }

  const contextValue: IHeaderStateContext = {
    tab,
    title,
    header,
    breadcrumb,
    newBreadcrumb,
    setTab,
    setTitle,
    setBreadcrumb,
    setNewBreadcrumb,
    setHeader,
  }

  return (
    <HeaderStateContext.Provider value={contextValue}>
      {children}
    </HeaderStateContext.Provider>
  )
}

export default HeaderStateProvider
