import isCheckDigitValid from './isCheckDigitValid'

export default function validateIEAP (document) {
  const digit = document.replace(/[^\d]+/g, '')

  if (digit.length !== 9) return false
  if (digit.substring(0, 2) !== '03') return false

  return isCheckDigitValid(digit)
}
