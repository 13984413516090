import cleanNumber from '../../commons/cleanNumber'

interface IParticipantAddressRequest {
  address: Omit<IAddressForm, 'stateId'>
}

const participantAddressFormAdapter = (
  data: IParticipantAddressForm
): IParticipantAddressRequest => {
  const {
    address: {
      cep,
      stateId,
      ...rest
    }
  } = data

  return {
    address: {
      cep: cleanNumber(cep, /\d+/g),
      ...rest
    }
  }
}

export default participantAddressFormAdapter
