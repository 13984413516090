import participantAddressFormAdapter from './participantAddressFormAdapter'
import participantCountyTaxFormAdapter from './participantCountyTaxFormAdapter'
import participantFormIdentificationAdapter from './participantIdentificationFormAdapter'
import participantStateTaxFormAdapter from './participantStateTaxFormAdapter'

const participantFormAdapter = (data: IParticipantForm, isUpdate?: boolean): IParticipantForm => {
  const {
    identification,
    address,
    stateTaxes,
    countyTaxes,
    model,
    documentType,
    ...rest
  } = data

  const stateTaxesAdapted = participantStateTaxFormAdapter(data as IParticipantStateTaxForm)
  const countyTaxesAdapted = participantCountyTaxFormAdapter(data as IParticipantCountyTaxForm)

  const formatIdentification = participantFormIdentificationAdapter(
    { identification }, isUpdate
  ).identification

  const formatStateTax = stateTaxesAdapted.stateTaxes
  const formatCountyTax = countyTaxesAdapted.countyTaxes
  const hasIdentification = Object.values(formatIdentification)?.length

  const formatAddress = address ? participantAddressFormAdapter({ address: data?.address })?.address as any : ''

  isUpdate && formatAddress && !formatAddress?.cep && delete formatAddress.cep

  if (model === 'person' || identification?.documentType === 'CPF' || identification?.documentType === 'ID') {
    const { accountId, companyId } = data || {}
    const entity = (): any => {
      if (accountId) {
        return { accountId }
      }

      if (companyId) {
        return { companyId }
      }

      return {}
    }

    return {
      ...entity(),
      ...(hasIdentification ? { identification: formatIdentification } : {}),
      ...(address ? {
        address: formatAddress
      } : {}),
    } as any
  }

  return {
    ...rest,
    ...(hasIdentification ? { identification: formatIdentification } : {}),
    ...(address ? {
      address: formatAddress } : {}),
    ...(formatStateTax ? { stateTaxes: formatStateTax } : {}),
    ...(formatCountyTax ? { countyTaxes: formatCountyTax } : {}),
  } as any
}

export default participantFormAdapter
