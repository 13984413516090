import formatDate from 'commons/helpers/formatter/formatDate';
import { removeEmptyValue } from './functionsInvoiceAdapter';

const ideInvoiceFormAdapter = (
  data: IIdeForm
): any => {
  const { NFref, serie, dhSaiEnt, dhCont, xJust, ...rest } = data || {}

  const refNF = NFref?.refNF?.map((item : any): any => {
    return {
      refNF: {
        ...item?.refNF,
        CNPJ: item?.refNF?.CNPJ?.replace(/[^0-9]/g, '') || '',
      }
    }
  });

  const refNFP = NFref?.refNFP?.map((item: any): any => {
    return {
      refNFP: {
        ...item?.refNFP,
        CNPJ: item?.refNFP?.CNPJ?.replace(/[^0-9]/g, '') || '',
      }
    }
  });

  const NFrefFormatted = NFref ? [
    ...refNF,
    ...refNFP,
    ...(NFref?.refNFe || []),
    ...(NFref?.refECF || []),
    ...(NFref?.refNFeSig || [])
  ] : []

  const avoidedFields = ['cDV', 'cMunFG', 'cNF', 'cUF', 'procEmi', 'tpAmb', 'verProc', 'dhEmi']
  const restWithoutEmptyFields = removeEmptyValue(rest)

  const restWithoutAvoidedFields = Object.entries(restWithoutEmptyFields)?.reduce((acc, item) => {
    const [key, value] = item
    if (avoidedFields.includes(key)) {
      return acc
    }
    return { ...acc, [key]: value }
  }, {})

  return {
    ...restWithoutAvoidedFields,
    ...(serie !== '' && serie !== undefined ? { serie: serie?.toString() } : {}),
    ...(data.mod === '55' && NFrefFormatted?.length > 0 ? { NFref: NFrefFormatted } : {}),
    ...((data.tpEmis === '9' || data.tpEmis === '6' || data.tpEmis === '7')
      ? { dhCont: formatDate(new Date(), 'iso'), xJust } : {}),
  }
}

export default ideInvoiceFormAdapter
