import accountcompanyFormAdapter from '../companies/companyFormAdapter'

const accountFormAdapter = (data: IAccountForm): any => {
  const {
    companies,
    headquarterAccountId,
    currentRulesBaseId,
    ...rest
  } = data

  return {
    headquarterAccountId: headquarterAccountId || null,
    companies: companies && companies.map(accountcompanyFormAdapter),
    ...rest
  }
}

export default accountFormAdapter
