import React from 'react'
import { CSidebar, CSidebarBrand, CSidebarMinimizer } from '@coreui/react'

import useSettings from 'templates/context/session-state/useSettings'
import logo from 'assets/images/logo-contabilone-white.svg'
import logoIcon from 'assets/images/logo-contabilone-white-icon.svg'

import { get } from 'commons/helpers/store'
import MainNav from './MainNav'

const Sidebar: React.FC = () => {
  const { settings, isLocalhost, setSettings } = useSettings()
  const storageData = get('cone-data', {})

  const handleMinimizeChange = ():any => {
    const newIsMinimize = settings.sidebar === 'minimize' ? 'openned' : 'minimize'
    setSettings({ ...settings, sidebar: newIsMinimize })
  }

  const toggleSidebarMobile = (): void => {
    const val = [false, 'responsive'].includes(settings.sidebarShow) ? true : 'responsive'
    setSettings({ ...settings, sidebarShow: val })
  }
  const lightLogo = settings?.whitelabelSettings?.lightLogoUrl
  || storageData?.settings?.whitelabelSettings?.lightLogoUrl
  const lightIcon = settings?.whitelabelSettings?.lightIconUrl
  || storageData?.settings?.whitelabelSettings?.lightIconUrl
  return (
    <CSidebar
      onShowChange={toggleSidebarMobile}
      show={settings.sidebarShow || 'responsive'}
      minimize={settings.sidebar === 'minimize'}
      unfoldable
      onMinimizeChange={handleMinimizeChange}
    >
      <CSidebarBrand className="header-logo" to="/">
        {!isLocalhost && !lightLogo && (<h1 className="c-sidebar-brand-full">{settings.whitelabelSettings.name}</h1>)}
        {!isLocalhost && !lightIcon && (
          <h1 className="c-sidebar-brand-minimized text-center">
            {(settings.whitelabelSettings?.name?.length && settings.whitelabelSettings.name[0]) || ''}
          </h1>
        )}

        {(isLocalhost && <img src={logo} alt={settings.whitelabelSettings.name} className="c-sidebar-brand-full" />)
        || (lightLogo && (
          <img src={`${lightLogo}?${(new Date()).getTime()}`} alt={settings.whitelabelSettings.name} className="c-sidebar-brand-full" />
        )) || ''}

        {(isLocalhost && <img src={logoIcon} alt={settings.whitelabelSettings.name} className="c-sidebar-brand-minimized" />)
        || (lightIcon && (
          <img src={`${lightIcon}?${(new Date()).getTime()}`} alt={settings.whitelabelSettings.name} className="c-sidebar-brand-minimized" />
        )) || ''}
      </CSidebarBrand>

      <MainNav />

      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(Sidebar)
