import React from 'react'
import { Redirect } from 'react-router-dom'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import useSession from 'templates/context/session-state/useSession'

const Main: React.FC = () => {
  const { hasExpired } = useSession()
  const prodEnviroment = process.env.REACT_APP_CONE_API_ENVIRONMENT === 'production'

  const homePath = prodEnviroment ? routePaths.INVOICES : routePaths.DASHBOARD

  return (
    <Redirect
      to={makeRoute({ path: hasExpired() ? routePaths.SIGN_IN : homePath })}
    />
  )
}

export default Main
