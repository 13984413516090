const userFormAdapter = (data: IUserForm): any => {
  const {
    document,
    ...rest
  } = data

  return {
    ...rest,
    document: document && (document.match(/\d+/g) || []).join('')
  }
}

export default userFormAdapter
