function get(key: string, defaults: any): any {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : defaults
}

function remove(key: string): void {
  localStorage.removeItem(key)
}

function update(key: string, data: any): any {
  localStorage.setItem(key, JSON.stringify(data))
  return data
}

export { get, remove, update }
