import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import { CCard, CAlert } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import { emailValidationSignIn } from 'commons/helpers/validations/validators-rules'
import makeRoute from 'commons/helpers/route/makeRoute'
import validators from 'commons/helpers/validations'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import useSettings from 'templates/context/session-state/useSettings'
import Button from 'components/form/button'
import CardBody from 'components/card/CardBody'

import logoNexaasId from 'assets/images/logo-nexaas-id-icon.png'
import InputEmail from 'components/form/InputEmail'
import InputPassword from 'components/form/InputPassword'
import O_AUTH_URL from './oAuth'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const Register: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const api = useApi()
  const history = useHistory()
  const { settings, isContabilone } = useSettings()

  const [password, setPassword] = useState('')

  const queryParams = new URLSearchParams(history.location.search)
  const email = queryParams.get('email') || ''

  const register: any = ({ email, password }: IRegisterInForm) => (
    api.auth.register(email, password)
  )

  const { mutate, isSuccess, isLoading: isSending, isError, error } = useMutation(register)

  const defaultValues = { email }
  const { control, handleSubmit, setError, getValues
  } = useForm<IRegisterInForm>({
    defaultValues
  })

  const onSubmit: SubmitHandler<IRegisterInForm> = (data: IRegisterInForm) => {
    mutate(data as any)
  }

  const { payload } = error ? error as IAPIException : {} as any

  const whitelabelName = settings?.whitelabelSettings?.name
  const signin = makeRoute({ path: routePaths.SIGN_IN })

  useEffect(() => {
    setTitle('Registrar')
  }, [])

  useEffect(() => {
    if (whitelabelName && !isContabilone) {
      history.push(signin)
    }
  }, [isContabilone, settings])

  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    isError && payload?.errors?.forEach((error: any, i: number): void => {
      const { field, message, messages } = error || {}

      !field && message && setMessageError(message)
      !field && message && setMessageError(messages[0])

      field && message && setError(field, { message }, { shouldFocus: i === 0 })
      field && messages && setError(field, { message: messages[0] }, { shouldFocus: i === 0 })
    })
  }, [isError])

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />

      <CardBody>
        {!isSuccess && (
        <>
          <h5 className="text-center">Registre-se para continuar</h5>
          <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              control={control}
              rules={emailValidationSignIn}
            />
            <InputPassword
              name="password"
              autoComplete="on"
              placeholder="Senha"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: {
                  validations: (password: string) => (
                    validators.minSize(password, 6)
                    || null
                  )
                }
              }}
              onChange={(e: any) => setPassword(e.target.value)}
              messages={[(
                <PasswordStrengthBar
                  password={password}
                  minLength={6}
                  scoreWords={['Fraca', 'Razoável', 'Boa', 'Forte', 'Muito forte']}
                  shortScoreWord={(password && 'Senha curta') || ''}
                />
              )]}
            />

            {isError && (
            <p className="text-danger text-center">{messageError}</p>
            )}

            <InputPassword
              name="confirmPassword"
              autoComplete="on"
              placeholder="Confirmar senha"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: {
                  validations: (password: string) => (
                    validators.confirm(password, getValues('password'))
                    || null
                  )
                }
              }}
            />

            {!isSuccess && (
            <div style={{ fontSize: '12px' }}>
              <p className="text-center mt-0 mb-3">
                Ao continuar com o cadastro, você cria uma conta e concorda com os nossos
                {' '}
                <a style={{ color: 'blue' }} href="https://contabilone.com/document-use-terms-cone.pdf" target="_blank" rel="noreferrer">Termos de Uso</a> e
                {' '}
                <a style={{ color: 'blue' }} href="https://contabilone.com/document-privacity-policy-cone.pdf" target="_blank" rel="noreferrer">Política de Privacidade</a>.
              </p>
            </div>
            )}

            <Button
              id="btn-register-account"
              title="Registrar"
              size="sm"
              color="info"
              type="submit"
              block
              isLoading={isSending}
            />
          </form>
        </>
        )}

        {isSuccess && (
          <CAlert fade color="success" className="text-center mb-0">
            <h4 className="alert-heading mb-1">Parabéns!</h4>
            <p className="mb-0">
              Seu registro foi realizado.
            </p>
            <p className="mb-0">
              Você receberá um e-mail com as instruções para acessar a plataforma.
            </p>
          </CAlert>
        )}
      </CardBody>

      {!isSuccess && (
        <>
          <div className="d-flex justify-content-center">
            <p className="text-center mt-1 mx-1 mb-2">
              Ou continue com
              {' '}
            </p>
            <Button
              id="btn-signin-google"
              tooltip="Google"
              icon="cib-google"
              iconSize="sm"
              color="light"
              size="sm"
              href={O_AUTH_URL.GOOGLE}
              disabled={isSending}
              className="mx-1"
            />
            <Button
              id="btn-signin-nexaas"
              tooltip="Nexaas ID"
              color="light"
              size="sm"
              href={O_AUTH_URL.NEXXAS_ID}
              disabled={isSending}
              className="mx-1"
            >
              <img className="c-icon" src={logoNexaasId} alt="Logo Nexxas ID" />
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              to={routePaths.SIGN_IN}
              color="link"
              size="md"
            >
              Já possui registro? Entrar
            </Button>
          </div>
        </>
      )}
      <CardFooterLink />
    </CCard>
  )
}

export default Register
