import { removeEmptyValue } from './functionsInvoiceAdapter';

const infAdicInvoiceFormAdapter = (
  data: IInfAdicForm
): any => {
  const { obsCont, obsFisco, infAdFisco, infCpl, procRef } = data || {}

  const obsContFormatted = obsCont?.map((item: any): any => {
    return removeEmptyValue(item)
  });
  const obsFiscoFormatted = obsFisco?.map((item: any): any => {
    return removeEmptyValue(item)
  });

  const procRefFormatted = procRef?.map((item: any): any => {
    return removeEmptyValue(item)
  });

  return {
    ...(obsCont?.length !== 0 ? { obsCont: obsContFormatted } : {}),
    ...(obsFisco?.length !== 0 ? { obsFisco: obsFiscoFormatted } : {}),
    ...(infAdFisco !== '' ? { infAdFisco } : {}),
    ...(infCpl !== '' ? { infCpl } : {}),
    ...(procRef?.length !== 0 ? { procRef: procRefFormatted } : {}),
  }
}

export default infAdicInvoiceFormAdapter
