const configurationFormAdapter = (data: any): any => {
  const { accountId: account, ...rest } = data
  const accountId = account && Array.isArray(account) ? account[0] : account

  return {
    ...rest,
    ...(account?.length ? { accountId } : {})
  }
}

export default configurationFormAdapter

export const configurationActionGroupFormAdapter = (data: any): any => {
  const { enabled, subsidiaries, accountId: account, ...rest } = data
  const booleanEnabledValue = enabled !== 'disabled'
  const subsidiaryId = Array.isArray(subsidiaries) ? subsidiaries[0] : subsidiaries
  const accountId = account && Array.isArray(account) ? account[0] : account

  return {
    ...rest,
    ...(enabled ? { enabled: booleanEnabledValue } : {}),
    ...(subsidiaryId ? { subsidiaryId } : {}),
    ...(account?.length ? { accountId } : {})
  }
}
