import React, { useEffect } from 'react'
import updateThemeColor from 'styles/updateThemeColor'
import useSettings from 'templates/context/session-state/useSettings'

const Styles: React.FC = () => {
  const { settings } = useSettings()

  useEffect(() => {
    updateThemeColor(settings.whitelabelSettings?.themeColor)
  }, [])

  return null
}

export default Styles
