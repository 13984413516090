import parseApiDate from '../../commons/parseApiDate'

const rulesEngineAdapter = (data: any): IRulesEngine => {
  const { startDate, endDate, createdAt, updatedAt, ...rest } = data

  return {
    startDate: startDate || {},
    endDate: endDate || {},
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default rulesEngineAdapter
