import parseApiDate from '../../commons/parseApiDate'

const sessionAdapter = (data: any): ISession => {
  const { expires, startedAt, ...rest } = data

  return {
    // @todo verify correct return to Date with API
    expires: parseApiDate(expires),
    startedAt: parseApiDate(startedAt),
    ...rest
  }
}

export default sessionAdapter
