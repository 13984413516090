const cancelFormAdapter = (data: any): ICancelForm => {
  const { chNFeRef, chNFe, subsidiary, ie, cnpj, ...rest } = data || {}
  if (chNFeRef) {
    return ({ ...rest, chNFeRef, chNFe: chNFe[0] })
  }

  return ({ ...rest, chNFe })
}

export default cancelFormAdapter
