import config from '../../@config'
import APIException from '../../services/api/APIException'
import RequestException from '../../services/request/RequestException'
import accountFormAdapter from '../accounts/accountFormAdapter'
import authResponseAdapter from './authResponseAdapter'

const endpoints = config.ENDPOINTS.auth

const Auth = (api: IAPI): IAuthAPI => {
  function adapter(response: IAPIResponse): IAuth {
    return authResponseAdapter(response)
  }

  function setAuthorization(authorization: string): void {
    api.setAuthorization(authorization)
  }

  async function doLogin(email: string, password: string): Promise<IAuth> {
    const auth = adapter(
      await api.doRequest('post', endpoints.login, { email, password }, { noAuthorization: true })
    )

    api.setAuthorization(auth.session.token)

    return auth
  }

  async function doLoginByToken(token: string): Promise<IAuth> {
    const auth = adapter(
      await api.doRequest('post', endpoints.login, { token }, { noAuthorization: true })
    )

    api.setAuthorization(auth.session.token)

    return auth
  }
  async function doLoginByOauth(service: 'google' | 'linkedin' | 'nexaas-id', code: string): Promise<IAuth> {
    const endpoint = (service === 'google' && endpoints.loginGoogle)
      || (service === 'linkedin' && endpoints.loginLinkedin)
      || (service === 'nexaas-id' && endpoints.loginNexaasId)
      || null

    if (endpoint === null) throw new APIException(new RequestException(404, 'OAuth Endpoint not found', {}))

    const auth = adapter(await api.doRequest('get', endpoint, { code }, { noAuthorization: true }))

    api.setAuthorization(auth.session.token)

    return auth
  }
  async function refreshToken(): Promise<IAuth | null> {
    if (!api.authorization) {
      return null
    }

    const auth = adapter(
      await api.doRequest('post', endpoints.refreshToken, { token: api.authorization })
    )

    api.setAuthorization(auth.session.token)

    return auth
  }

  async function register(email: string, password: string): Promise<string> {
    const response = await api.doRequest(
      'post',
      endpoints.register,
      { email, password },
      { noAuthorization: true }
    )

    return response.message
  }

  async function confirmRegister(token: string): Promise<IAuth> {
    const auth = adapter(
      await api.doRequest('post', endpoints.login, { token }, { noAuthorization: true })
    )

    api.setAuthorization(auth.session.token)

    return auth
  }

  async function onboard({ type, body }: IOnboardForm): Promise<string> {
    const bodyContent = (type === 'account')
      ? accountFormAdapter(body as IAccountForm)
      : body
    return (await api.doRequest('post', '/onboard', { [type]: bodyContent })).message
  }

  async function recoverPassword(email: string): Promise<string> {
    // @todo the api always return "The specified key does not exist."
    const response = await api.doRequest('post', endpoints.recoverPassword, { email })
    return response.message
  }
  async function emailConfirmation(email: string): Promise<string> {
    // @todo the api always return "The specified key does not exist."
    const response = await api.doRequest('post', endpoints.emailConfirmation, { email })
    return response.message
  }

  async function createPassword(token: string, newPassword: string): Promise<string> {
    return (
      await api.doRequest('patch', `${endpoints.createPassword}`, {
        token,
        newPassword
      })
    ).message
  }

  async function doLogout(): Promise<string> {
    const response = await api.doRequest('post', endpoints.logout)

    api.setAuthorization('')

    return response.message
  }

  return {
    setAuthorization,
    doLogin,
    doLoginByToken,
    doLoginByOauth,
    refreshToken,
    register,
    confirmRegister,
    onboard,
    recoverPassword,
    emailConfirmation,
    createPassword,
    doLogout,
    endpoints
  }
}

export default Auth
