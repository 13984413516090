import config from '@api/src/@config'
import RequestApi from '../../services/api'
import productAdapter from './productAdapter'
import productFormAdapter from './productFormAdapter'
import productUpdateAdapter from './productUpdateAdapter'

const endpoints = config.ENDPOINTS.products

const Products = (api: IAPI): IProductsAPI => {
  function adapter(response: any): IProduct {
    return productAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IProduct>> {
    return RequestApi.listAdapter<IProduct>(
      (
        await api.doRequest('get', endpoints.products, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IProduct> {
    return RequestApi.itemAdapter<IProduct>(
      await api.doRequest('get', `${endpoints.products}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IProductForm, specificProd: any): Promise<string> {
    return (await api.doRequest('post', endpoints.products, productFormAdapter(data, specificProd))).message
  }
  async function update(id: string, data: IProductForm): Promise<IProduct> {
    return RequestApi.itemAdapter<IProduct>(
      await api.doRequest('patch', `${endpoints.products}/${id}`, productUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IProduct> {
    return RequestApi.itemAdapter<IProduct>(
      await api.doRequest('patch', `${endpoints.products}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.products}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove
  }
}

export default Products
