import userAdapter from '../users/userAdapter'
import sessionAdapter from './sessionAdapter'

const authResponseAdapter = (response: IAPIResponse): IAuth => {
  const { session, user, ...rest } = response.message

  return {
    session: sessionAdapter(session),
    user: userAdapter(user),
    ...rest
  }
}

export default authResponseAdapter
