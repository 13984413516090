import React from 'react'
import { CSidebarNav } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import useBreadcrumb from 'templates/breadcrumb/useBreadcrumb'
import Menuitems from './MenuItem'

const useItems: any = () => {
  const prodEnviroment = process.env.REACT_APP_CONE_API_ENVIRONMENT === 'production'

  return [
    {
      name: 'Dashboard',
      to: prodEnviroment ? {} : makeRoute({ path: routePaths.DASHBOARD }),
      icon: 'cil-speedometer',
      badge: {
        size: 'sm',
        color: 'transparent',
        text: <small className="text-warning">SOON</small>,
      }
    },

    { name: 'gerenciamento' },
    {
      name: 'Domínios',
      to: makeRoute({ path: routePaths.WHITELABELS }),
      icon: 'cil-blur',
    },
    {
      name: 'Contas',
      to: makeRoute({ path: routePaths.ACCOUNTS }),
      icon: 'cil-library-building'
    },
    {
      name: 'Empresas',
      to: makeRoute({ path: routePaths.COMPANIES }),
      icon: 'cil-factory'
    },
    {
      name: 'Estabelecimentos',
      to: makeRoute({ path: routePaths.SUBSIDIARIES }),
      icon: 'cil-building-business',
    },
    {
      name: 'Usuários',
      to: makeRoute({ path: routePaths.USERS }),
      icon: 'cil-user'
    },

    { name: 'Apps' },
    {
      name: 'Motor de Regras',
      to: makeRoute({ path: routePaths.RULES_ENGINES }),
      icon: 'cil-calculator'
    },
    {
      name: 'Notas Fiscais',
      to: makeRoute({ path: routePaths.INVOICES }),
      icon: 'cil-folder-open',
    },
    {
      name: 'Financeiro ',
      to: prodEnviroment ? {} : makeRoute({ path: routePaths.FINANCIAL }),
      icon: 'cil-chart-line',
      badge: {
        size: 'sm',
        color: 'transparent',
        text: <small className="text-warning">SOON</small>,
      },
    },

    {
      name: 'Tributos',
      icon: 'cil-donate',
      childrens: [
        ...(!prodEnviroment ? [{
          name: 'Apuração',
          to: makeRoute({ path: routePaths.ASCERTAINMENTS }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>,
          },
        },
        {
          name: 'EFD ICMS IPI',
          to: makeRoute({ path: routePaths.TAXS_EFD_ICMS_IPI }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>,
          }
        },
        {
          name: 'EFD Contribuições',
          to: makeRoute({ path: routePaths.TAXS_EFD_CONTRIBUTIONS }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>,
          }
        },
        {
          name: 'ECF',
          to: makeRoute({ path: routePaths.ACCOUNTINGS_ECF }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>
          }
        }] : []),
        {
          name: 'Menu Fiscal',
          to: makeRoute({ path: routePaths.TAX_MENU })
        }]
    },

    {
      name: 'Contabilidade',
      icon: 'cil-book',
      childrens: [
        {
          name: 'Razão',
          to: makeRoute({ path: routePaths.LEDGERS }),
        },
        {
          name: 'Diário',
          to: makeRoute({ path: routePaths.DIARIES }),
        },
        {
          name: 'Balancete',
          to: makeRoute({ path: routePaths.BALANCES_SHEETS }),
        },
        {
          name: 'Balanço Patrimonial',
          to: makeRoute({ path: routePaths.BALANCES }),
        },
        {
          name: 'Mutação Patrimonial',
          to: makeRoute({ path: routePaths.HERITAGE_MUTATIONS }),
        },
        {
          name: 'Resultado Exercício',
          to: makeRoute({ path: routePaths.INCOME_STATEMENTS }),
        },
        {
          name: 'Resultado Abrangente',
          to: makeRoute({ path: routePaths.COMPREHENSIVE_RESULTS }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>
          },
        },
        {
          name: 'Valor Adicionado',
          to: makeRoute({ path: routePaths.ADDED_VALUES }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>
          },
        },
        {
          name: 'Fluxo Caixa',
          to: makeRoute({ path: routePaths.CASH_FLOWS }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>
          },
        },
        {
          name: 'ECD',
          to: prodEnviroment ? {} : makeRoute({ path: routePaths.ACCOUNTINGS_ECD }),
          badge: {
            size: 'sm',
            color: 'transparent',
            text: <small className="text-warning">SOON</small>
          }
        },
      ]
    },

    // {
    //   name: 'Certidões',
    //   to: prodEnviroment ? {} : makeRoute({ path: routePaths.CERTIFICATES }),
    //   icon: 'cil-task',
    //   badge: {
    //     size: 'sm',
    //     color: 'transparent',
    //     text: <small className="text-warning">SOON</small>,
    //   }
    // },
    // {
    //   name: 'Caixa Postal',
    //   to: prodEnviroment ? {} : makeRoute({ path: routePaths.MAILBOXS }),
    //   icon: 'cil-mail',
    //   badge: {
    //     size: 'sm',
    //     color: 'transparent',
    //     text: <small className="text-warning">SOON</small>,
    //   }
    // },
  ]
}

const MainNav: React.FC = () => {
  const items = useItems()
  const { clearBreadcrumb } = useBreadcrumb()
  return (
    <CSidebarNav>
      <Menuitems
        items={items}
        handleClick={clearBreadcrumb}
      />
    </CSidebarNav>
  )
}

export default MainNav
