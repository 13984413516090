import { checkEmptyObject, DocumentFormatted, removeEmptyValue } from './functionsInvoiceAdapter'

const emitInvoiceFormAdapter = (
  data: IEmit
): any => {
  const { CNPJ, CPF, IE, enderEmit, xNome,
    xFant,
    CRT,
    IM,
    IEST,
    CNAE
  } = data || {}

  const Document = CNPJ !== '' ? CNPJ : CPF

  const EmitFormatted = {
    ...DocumentFormatted(Document),
    IE,
    xNome,
    xFant,
    CRT,
    IM,
    IEST,
    CNAE
  }

  return {
    ...removeEmptyValue(EmitFormatted),
    ...(!checkEmptyObject(removeEmptyValue(enderEmit))
      ? { enderEmit: removeEmptyValue(enderEmit) } : {}), }
}

export default emitInvoiceFormAdapter
