import React, { useState } from 'react'
import { CFormGroup, CInputGroup } from '@coreui/react'
import Icon from 'components/icon'
import Label from '../Label'
import Message from '../Message'

const PasswordInputField: React.FC<IInputPasswordField> = ({
  name, value, innerRef, label, valid, invalid, className: classNameProps,
  classNameFormGroup, noMargin, messages, error, disabled, ...inputProps
}) => {
  const customClassName = 'form-control password'
  const className = `${customClassName} ${(valid && 'is-valid') || ''} ${(invalid && 'is-invalid') || ''} ${classNameProps || ''}`

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  return (
    <CFormGroup className={`${noMargin ? 'm-0' : ''} ${classNameFormGroup || ''}`}>
      <Label text={label} htmlFor={name}>
        <CInputGroup>
          <div className="posiion-relative w-100 border-box">
            <input
              type={showPassword ? 'text' : 'password'}
              {...{ className, disabled, value, ...inputProps }}
              className={className}
            />
            <Icon
              name={showPassword ? 'cil-eye' : 'cil-low-vision'}
              onClick={togglePasswordVisibility}
              size="sm"
              className="icon-style"
            />
          </div>
        </CInputGroup>
      </Label>
      <Message messages={messages} />
      {error && <p className="text-danger font-error">{error}</p>}
    </CFormGroup>
  )
}

export default PasswordInputField
