import parseApiDate from '../../commons/parseApiDate'

import accountAdapter from '../accounts/accountAdapter'

const companyAdapter = (data: any): ICompany => {
  const {
    createdAt, updatedAt, certificateExpiration, account,
    subsidiariesTotal, ...rest
  } = data

  return {
    ...rest,
    account: account && accountAdapter(account),
    subsidiariesTotal,
    // @todo verify if certificateExpiration can be null or if always will be Date
    certificateExpiration: parseApiDate(certificateExpiration),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default companyAdapter
