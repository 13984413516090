import React from 'react'
import { CBadge, CButton, CSpinner, CTooltip } from '@coreui/react'

import Icon from 'components/icon'

const Button: React.FC<IButton> = ({
  badgeColor,
  noTruncate,
  children,
  noShadow,
  color,
  disabled,
  icon,
  iconClassName,
  iconBadge,
  iconSize,
  iconColor,
  size,
  title,
  titleBefore,
  tooltip,
  variant,
  isLoading,
  breakLine,
  className,
  ...props
}) => {
  const parent = tooltip ? { content: tooltip } : {}

  const classLoading = isLoading ? 'd-flex justify-content-center align-items-center' : ''

  // To define default 'md' size, value is defined with => undefined
  const theSize = (size === undefined && 'sm') || (size === '' ? undefined : size)

  const cButton = {
    color: (isLoading && 'secondary') || (disabled && 'dark') || color,
    disabled: isLoading || disabled,
    size: theSize,
    variant,
    className: `${className || ''}`
      .concat(`${noTruncate ? '' : ' text-truncate'}`)
      .concat(`${breakLine ? ' flex-column' : ''}`)
      .concat(`${noShadow ? ' shadow-none' : ''}`)
      .concat(`${isLoading ? ' d-flex' : ''}`)
      .concat(classLoading),
    ...props
  }

  const cIcon = {
    size: iconSize || theSize,
    name: icon,
    className: `${iconClassName || ''} ${title ? 'ml-1' : ''} ${titleBefore ? 'ml-1' : ''}`,
  }

  const cBadge = {
    shape: 'pill' as any,
    color: badgeColor || 'danger',
    style: {
      margin: '11px 0px 0 -7px',
      position: 'absolute' as any
    }
  }

  const Parent = tooltip ? CTooltip : React.Fragment

  return (
    <Parent {...parent}>
      <CButton {...cButton}>
        {titleBefore}
        {title}
        {!isLoading && icon && <Icon {...cIcon} color={iconColor || 'dark'} />}
        {children}
        {iconBadge && <CBadge {...cBadge}>{iconBadge}</CBadge>}
        {isLoading && <CSpinner size="sm" className="m-1 float-right " color="dark" />}
      </CButton>
    </Parent>
  )
}

export default Button
