import parseApiDate from '../../commons/parseApiDate'

const whitelabelAdapter = (data: any): IWhitelabel => {
  const { createdAt, updatedAt, ...whitelabel } = data

  return {
    ...whitelabel,
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default whitelabelAdapter
