const reactQuery = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      forceFetchOnMount: false,
      // suspense: true
      // staleTime: 1000 * 60 * 5,
      // cacheTime: 1000 * 60 * 5,
      retry: 0 // 0 = No retry on server error
    }
  }
}

export default reactQuery
