import config from '../../@config'
import RequestApi from '../../services/api'
import accountPlanAdapter from './accountPlanAdapter'
import accountPlanFormAdapter from './accountPlanFormAdapter'
import accountFormUpdateAdapter from './accountPlanFormUpdateAdapter'

const endpoints = config.ENDPOINTS.accountingCharts

const AccountsPlans = (api: IAPI): IAccountsPlansAPI => {
  function adapter(response: any): IAccountingChart {
    return accountPlanAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IAccountingChart>> {
    return RequestApi.listAdapter<IAccountingChart>(
      (
        await api.doRequest('get', endpoints.accountingCharts, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IAccountingChart> {
    return RequestApi.itemAdapter<IAccountingChart>(
      await api.doRequest('get', `${endpoints.accountingCharts}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function generateAccountsPlan(companyId: string): Promise<IAccountingChart> {
    return RequestApi.itemAdapter<IAccountingChart>(
      await api.doRequest('post', endpoints.generate, { companyId }),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IAccountingChartForm): Promise<string> {
    return (await api.doRequest('post', endpoints.accountingCharts, accountPlanFormAdapter(data))).message
  }

  async function update(id: string, data: IAccountingChartForm): Promise<IAccountingChart> {
    return RequestApi.itemAdapter<IAccountingChart>(
      await api.doRequest('patch', `${endpoints.accountingCharts}/${id}`, accountFormUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IAccountingChart> {
    return RequestApi.itemAdapter<IAccountingChart>(
      await api.doRequest('patch', `${endpoints.accountingCharts}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.accountingCharts}/${id}`)).message
  }

  async function removeByCompany(companyId: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.accountingCharts}/${companyId}`)).message
  }

  async function getReferentialAccounts(params: IPagedFilterParams): Promise<IAccountingChart> {
    return RequestApi.itemAdapter<IAccountingChart>(
      await api.doRequest('get', `${endpoints.referentialsAccounts}`, params),
      (data: any) => adapter(data)
    )
  }

  return {
    getList,
    getById,
    generateAccountsPlan,
    create,
    update,
    updateEnabled,
    remove,
    removeByCompany,
    getReferentialAccounts
  }
}

export default AccountsPlans
