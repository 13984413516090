import parseApiDate from '../../commons/parseApiDate'

const productAdapter = (data: any): IProduct => {
  const {
    createdAt, updatedAt, ...rest
  } = data

  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default productAdapter
