import removeCurrencyFormat from '../../../commons/formatApiCurency'
import { checkEmptyObject, removeEmptyValue } from './functionsInvoiceAdapter'

const totalInvoiceFormAdapter = (
  data: ITotal
): any => {
  const { ICMSTot, ISSQNtot, retTrib, ...rest } = data || {}
  const ICMSTotOBj = {
    ...ICMSTot,
    vBC: removeCurrencyFormat(ICMSTot?.vBC),
    vICMS: removeCurrencyFormat(ICMSTot?.vICMS),
    vICMSDeson: removeCurrencyFormat(ICMSTot?.vICMSDeson),
    vFCPUFDest: removeCurrencyFormat(ICMSTot?.vFCPUFDest),
    vICMSUFDest: removeCurrencyFormat(ICMSTot?.vICMSUFDest),
    vICMSUFRemet: removeCurrencyFormat(ICMSTot?.vICMSUFRemet),
    vFCP: removeCurrencyFormat(ICMSTot?.vFCP),
    vBCST: removeCurrencyFormat(ICMSTot?.vBCST),
    vST: removeCurrencyFormat(ICMSTot?.vST),
    vFCPST: removeCurrencyFormat(ICMSTot?.vFCPST),
    vFCPSTRet: removeCurrencyFormat(ICMSTot?.vFCPST),
    vProd: removeCurrencyFormat(ICMSTot?.vProd),
    vFrete: removeCurrencyFormat(ICMSTot?.vFrete),
    vSeg: removeCurrencyFormat(ICMSTot?.vSeg),
    vDesc: removeCurrencyFormat(ICMSTot?.vDesc),
    vII: removeCurrencyFormat(ICMSTot?.vII),
    vIPI: removeCurrencyFormat(ICMSTot?.vIPI),
    vIPIDevol: removeCurrencyFormat(ICMSTot?.vIPIDevol),
    vPIS: removeCurrencyFormat(ICMSTot?.vPIS),
    vCOFINS: removeCurrencyFormat(ICMSTot?.vCOFINS),
    vOutro: removeCurrencyFormat(ICMSTot?.vOutro),
    vTotTrib: removeCurrencyFormat(ICMSTot?.vTotTrib),
    vNF: removeCurrencyFormat(ICMSTot?.vNF),
    qBCMono: removeCurrencyFormat(ICMSTot?.qBCMono),
    vICMSMono: removeCurrencyFormat(ICMSTot?.vICMSMono),
    qBCMonoReten: removeCurrencyFormat(ICMSTot?.qBCMonoReten),
    vICMSMonoReten: removeCurrencyFormat(ICMSTot?.vICMSMonoReten),
    qBCMonoRet: removeCurrencyFormat(ICMSTot?.qBCMonoRet),
    vICMSMonoRet: removeCurrencyFormat(ICMSTot?.vICMSMonoRet)
  }

  const dCompetDate = ISSQNtot?.dCompet ? { dCompet: new Date().toISOString() } : {}

  const ISSQNtotObj = {
    ...ISSQNtot,
    ...dCompetDate,
    vServ: removeCurrencyFormat(ISSQNtot?.vServ),
    vBC: removeCurrencyFormat(ISSQNtot?.vBC),
    vISS: removeCurrencyFormat(ISSQNtot?.vISS),
    vPIS: removeCurrencyFormat(ISSQNtot?.vPIS),
    vCOFINS: removeCurrencyFormat(ISSQNtot?.vCOFINS),
    vDeducao: removeCurrencyFormat(ISSQNtot?.vDeducao),
    vOutro: removeCurrencyFormat(ISSQNtot?.vOutro),
    vDescIncond: removeCurrencyFormat(ISSQNtot?.vDescIncond),
    vDescCond: removeCurrencyFormat(ISSQNtot?.vDescCond),
    vISSRet: removeCurrencyFormat(ISSQNtot?.vISSRet),
    cRegTrib: removeCurrencyFormat(ISSQNtot?.cRegTrib),
    dCompet: ISSQNtot?.dCompet
  }

  const retTribObj = {
    ...retTrib,
    vRetPIS: removeCurrencyFormat(retTrib?.vRetPIS),
    vRetCOFINS: removeCurrencyFormat(retTrib?.vRetCOFINS),
    vRetCSLL: removeCurrencyFormat(retTrib?.vRetCSLL),
    vBCIRRF: removeCurrencyFormat(retTrib?.vBCIRRF),
    vIRRF: removeCurrencyFormat(retTrib?.vIRRF),
    vBCRetPrev: removeCurrencyFormat(retTrib?.vBCRetPrev),
    vRetPrev: removeCurrencyFormat(retTrib?.vRetPrev),
  }
  const totalFormatted = {
    ...removeEmptyValue(rest),
    ...(!checkEmptyObject(removeEmptyValue(ICMSTotOBj))
      ? { ICMSTot: removeEmptyValue(ICMSTotOBj) } : {}),
    ...(!checkEmptyObject(removeEmptyValue(ISSQNtotObj))
      ? { ISSQNtot: removeEmptyValue(ISSQNtotObj) } : {}),
    ...(!checkEmptyObject(removeEmptyValue(retTribObj))
      ? { retTrib: removeEmptyValue(retTribObj) } : {}),
  }

  return totalFormatted
}

export default totalInvoiceFormAdapter
