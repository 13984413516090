import React from 'react'
import { CFormGroup } from '@coreui/react'

import IconWithTooltip from 'components/icon-with-tooltip'
import Label from '../Label'
import Message from '../Message'
import Text from '../Text'

const InputField: React.FC<IInputField> = ({
  name, value, innerRef, label, valid, invalid, className: classNameProps,
  classNameFormGroup, noMargin, messages, error,
  disabled, inputMask, tooltipContent, append, ...inputProps
}) => {
  const className = `form-control ${(valid && 'is-valid') || ''} ${(invalid && 'is-invalid') || ''} ${classNameProps || ''}`

  return (
    <CFormGroup className={`${noMargin ? 'm-0' : ''} ${classNameFormGroup || ''}`}>
      <Label text={label} htmlFor={name}>
        {append ? (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text pr-1" id="basiaddon1">{append}</span>
            </div>
            <input
              className={`${className} pl-1 rounded-right`}
              {...{ disabled, value, ...inputProps }}
            />
            <Message messages={messages} error={error} />
          </div>
        )
          : (
            <>
              {tooltipContent && <IconWithTooltip content={tooltipContent} />}
              {disabled && (
                <>
                  <Text noMargin value={value} />
                  {error && <small className="text-danger">{error}</small>}
                </>
              )}
              {inputMask && (
                <input
                  {...{ className, disabled, value, ...inputProps }}
                />
              )}
              {!disabled && (
                <input
                  {...{ className, disabled, value, ...inputProps }}
                />
              )}
            </>
          )}
      </Label>
      {!append && <Message messages={messages} error={error} />}
    </CFormGroup>
  )
}

export default InputField
