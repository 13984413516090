import React from 'react'
import { CCard, CAlert } from '@coreui/react'

import CardBody from 'components/card/CardBody'
import CardHeaderLogo from './CardHeaderLogo'

const OnboardWhitelabelFinish: React.FC = () => {
  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />
      <CardBody>
        <CAlert fade color="success" className="text-center">
          <h4 className="alert-heading mb-1">Parabéns!</h4>
          <p className="mb-0">
            Seu registro foi realizado.
          </p>
          <p className="mb-0">
            Você receberá um e-mail com as instruções para acessar a plataforma.
          </p>
        </CAlert>
      </CardBody>
    </CCard>
  )
}

export default OnboardWhitelabelFinish
