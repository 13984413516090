import { removeEmptyValue, DocumentFormatted } from './functionsInvoiceAdapter';

const entregaInvoiceFormAdapter = (
  entrega: any
): any => {
  const { DOCUMENT: DocumentEntrega, fone, CEP, ...restEntrega } = entrega

  const EntregaFormatted = {
    ...removeEmptyValue(restEntrega),
    ...(DocumentEntrega !== '' ? DocumentFormatted(DocumentEntrega) : {}),
    ...(fone !== '' ? { fone: entrega?.fone.replace(/[^0-9]/g, '') } : {}),
    ...(CEP !== '' ? { CEP: entrega?.CEP.replace(/[^0-9]/g, '') } : {}),
  }
  return EntregaFormatted
}

export default entregaInvoiceFormAdapter
