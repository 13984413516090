const terminalFormUpdateAdapter = ({ updateVersion, invoiceTimeoutSeconds, ...data }:
   ITerminalForm): any => {
  return {
    ...(updateVersion && { updateVersion: updateVersion === 'true' }),
    ...(invoiceTimeoutSeconds && { invoiceTimeoutSeconds: Number(invoiceTimeoutSeconds) }),
    ...data
  }
}

export default terminalFormUpdateAdapter
