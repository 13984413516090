import { useHistory, useParams, useRouteMatch } from 'react-router'

import routePaths from 'routes/routes-paths'

import makeRoute from './makeRoute'

const useRoute = (): IUseRoute => {
  const match = useRouteMatch()
  const params = useParams()
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)

  const rulesEngineRoute = ({ path }: IMakeRoute): string => {
    const whitelabelId = sessionStorage.getItem('rulesWhitelabel') || queryParams.get('whitelabelId') || ''
    const accountId = sessionStorage.getItem('rulesAccount') || queryParams.get('accountId') || ''
    const searchObject = { whitelabelId, accountId }
    const search = new URLSearchParams(searchObject).toString()

    return makeRoute({
      path,
      search
    })
  }

  const routeTab = ({ path, tab, search }: IUseRouteTab): string => {
    return (
      makeRoute({
        path: `${path || match.path}${routePaths.TAB_SUB_ROUTE}`,
        params: { ...params, tab },
        search: (new URLSearchParams(search)).toString()
      })
    )
  }

  const routeAction = (action: string, id?: string, search?: string): string => {
    const path = (match.path.indexOf(routePaths.ACTION_SUB_ROUTE) > -1)
      ? match.path
      : `${history.location.pathname.replaceAll(`/${action}`, '')}${routePaths.ACTION_SUB_ROUTE}`

    const theSearch = search || (
      history.location.search.startsWith('?')
        ? history.location.search.substring(1)
        : history.location.search
    )

    return (
      makeRoute({
        path,
        params: { ...params, action, actionId: id },
        search: theSearch
      })
    )
  }

  return {
    currentRoute: match.url,
    makeRoute,
    routeTab,
    routeAction,
    rulesEngineRoute
  }
}

export default useRoute
