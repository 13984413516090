import React, { memo } from 'react'
import { CButton } from '@coreui/react'

import useSettings from 'templates/context/session-state/useSettings'

const CardFooterLink: React.FC = () => {
  const { settings: { whitelabelSettings } } = useSettings()

  const { linkTitle, linkUrl } = whitelabelSettings

  return (
    <>
      {linkTitle && (
        <div className="p-0 text-center card-footer-link">
          <CButton
            className="button-link"
            href={linkUrl}
            target="_blank"
          >
            {linkTitle}
          </CButton>
        </div>
      )}
    </>
  )
}

export default memo(CardFooterLink)
