interface IProfileTypesValue {
  levelNumber: number
  label: string
  value: string
  level?: string
}

interface IProfileTypes {
  [key: string]: IProfileTypesValue
}

const profileTypes: IProfileTypes = {
  STAFF: {
    levelNumber: 1,
    label: 'Staff',
    value: 'STAFF',
    level: 'Domínio'
  },
  WHITELABEL_OWNER: {
    levelNumber: 2,
    label: 'Owner',
    value: 'WHITELABEL_OWNER',
    level: 'Domínio'
  },
  ACCOUNT_OWNER: {
    levelNumber: 3,
    label: 'Owner',
    value: 'ACCOUNT_OWNER',
    level: 'Conta'
  },
  OWNER: {
    levelNumber: 3,
    label: 'Owner',
    value: 'OWNER'
  },
  COMPANY_MANAGER: {
    levelNumber: 4,
    label: 'Manager',
    value: 'COMPANY_MANAGER',
    level: 'Empresa'
  },
  SUBSIDIARY_MEMBER: {
    levelNumber: 5,
    label: 'Member',
    value: 'SUBSIDIARY_MEMBER',
    level: 'Estabelecimento'
  },
  SUBSIDIARY_VIEWER: {
    levelNumber: 6,
    label: 'Viewer',
    value: 'SUBSIDIARY_VIEWER',
    level: 'Estabelecimento'
  }
}

export default profileTypes
