const acquirerFormAdapter = (data: IAcquirerForm): any => {
  const {
    subsidiaryId,
    ...rest
  } = data

  const getSubsidiary = (): any => {
    if (Array.isArray(subsidiaryId)) {
      return { subsidiaryId: subsidiaryId[0] }
    }
    if (!subsidiaryId) {
      return {}
    }
    return { subsidiaryId }
  }

  return {
    ...(getSubsidiary()),
    ...rest
  }
}

export default acquirerFormAdapter
