import config from '../../@config'
import RequestApi from '../../services/api'
import acquirersLists from '../lists/acquirersLists'
import acquirerAdapter from './acquirerAdapter'
import acquirerFormAdapter from './acquirerFormAdapter'

const endpoints = config.ENDPOINTS.acquirers

const Acquirers = (api: IAPI): IAcquirersAPI => {
  function adapter(response: any): IAcquirer {
    return acquirerAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IAcquirer>> {
    const acqParams = { ...params, sort: 'asc', orderBy: ['acquirer.cnpj', 'subsidiary.cnpj', 'subsidiary.ie'] }
    return RequestApi.listAdapter<IAcquirer>(
      await api.doRequest('get', endpoints.acquirers, acqParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IAcquirer> {
    return RequestApi.itemAdapter<IAcquirer>(
      await api.doRequest('get', `${endpoints.acquirers}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: IAcquirerForm): Promise<string> {
    return (await api.doRequest('post', endpoints.acquirers, acquirerFormAdapter(data))).message
  }

  async function update(id: string, data: IAcquirerForm): Promise<IAcquirer> {
    return RequestApi.itemAdapter<IAcquirer>(
      await api.doRequest('patch', `${endpoints.acquirers}/${id}`, acquirerFormAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IAcquirer> {
    return RequestApi.itemAdapter<IAcquirer>(
      await api.doRequest('patch', `${endpoints.acquirers}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.acquirers}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove,
    lists: acquirersLists(api)
  }
}

export default Acquirers
