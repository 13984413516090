import config from '../../@config'
import RequestApi from '../../services/api'
import acquirerParticipantAdapter from './adapters/acquirerParticipantAdapter'

const endpoints = config.ENDPOINTS.list.acquirersLists

export default (api: IAPI): IAcquirersListsAPI => {
  async function getAcquirers(queryParams?: string): Promise<IList<IAcquirerParticipant>> {
    const params = { page: 1, limit: 40, ...(queryParams ? { q: queryParams } : {}) }

    return RequestApi.listAdapter<IAcquirerParticipant>(
      await api.doRequest('get', endpoints.acquirers, params) as IAPIResponseList,
      acquirerParticipantAdapter
    )
  }

  return {
    getAcquirers
  }
}
