import subsidiaryAddressFormAdapter from './subsidiaryAddressFormAdapter'
import subsidiaryCountyTaxFormAdapter from './subsidiaryCountyTaxFormAdapter'
import subsidiaryFormIdentificationAdapter from './subsidiaryIdentificationFormAdapter'
import subsidiaryStateTaxFormAdapter from './subsidiaryStateTaxFormAdapter'

const subsidiaryFormAdapter = (data: ISubsidiaryForm): ISubsidiaryForm => {
  const {
    identification,
    address,
    stateTaxes,
    countyTaxes,
    tmp,
    ...rest
  } = data

  const stateTaxesAdapted = subsidiaryStateTaxFormAdapter(data as ISubsidiaryStateTaxForm)
  const countyTaxesAdapted = subsidiaryCountyTaxFormAdapter(data as ISubsidiaryCountyTaxForm)

  return {
    ...rest,
    identification: subsidiaryFormIdentificationAdapter({ identification }).identification,
    address: subsidiaryAddressFormAdapter({ address: data.address }).address,
    stateTaxes: stateTaxesAdapted.stateTaxes,
    countyTaxes: countyTaxesAdapted.countyTaxes,
  }
}

export default subsidiaryFormAdapter
