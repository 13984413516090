interface IDisablementForm {
  subsidiaryId: string
  accountId: string
  mod: number
  nNF: Array<number>
  serie: number
  xJust: string
}

const disablementFormAdapter = (data: any): IDisablementForm => {
  const nNF = data?.nNFs ? data?.nNFs : data?.nNF

  const nNFFormatted = nNF?.map((item: any) => parseInt(item))
    .filter((item: any) => !Number.isNaN(item))

  const payload: any = {
    subsidiaryId: data?.subsidiaryId,
    accountId: data?.accountId,
    mod: parseInt(data?.mod),
    nNF: nNFFormatted,
    serie: parseInt(data?.serie),
    xJust: data?.xJust,
  }

  return payload
}

export default disablementFormAdapter
