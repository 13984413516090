import React from 'react'
import LoadingData from './LoadingData'

export default function LoadingApplication () {
  return (
    <div className='divLoaderRoot'>
      <div className='divLoader'>
        <LoadingData/>
      </div>
    </div>
  )
}
