const reactSelectTheme = {
  light: {},
  dark: {
    danger: 'yellow',
    dangerLight: 'yellow',
    neutral0: '#2f303a', // background color
    neutral10: 'red',
    neutral20: 'rgba(255, 255, 255, 0.09)', // border arrow color
    neutral30: 'rgba(255, 255, 255, 0.08)', // hover
    neutral40: 'white', // arrow hover
    neutral5: 'red',
    neutral50: 'green',
    neutral60: 'white', // hover arrow
    neutral70: 'red',
    neutral80: 'white', // text
    neutral90: 'red',
    primary: 'var(--light-dark-theme)', // background
    primary25: 'var(--light-dark-theme)', // background item hover
    primary50: 'var(--secondary-dark-theme)', // background item click
    primary75: 'yellow'
  }
}

const getReactSelectTheme = (current) => (
  {
    ...current,
    ...document.getElementsByClassName('c-dark-theme').length
      ? reactSelectTheme.dark
      : reactSelectTheme.light
  }
)

export default getReactSelectTheme
