const serieUpdateFormAdapter = (data: ISerieForm): any => {
  const { serieNumber, lastUsedNumber } = data

  return {
    ...data,
    ...(serieNumber ? { serieNumber: parseInt(serieNumber) } : {}),
    ...(lastUsedNumber ? { lastUsedNumber: parseInt(lastUsedNumber) } : {})
  }
}

export default serieUpdateFormAdapter
