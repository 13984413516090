import React, { memo } from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'

type IProps = {
  children: React.ReactNode,
  backgroundStyle?: React.CSSProperties
};

const TemplateGeneral: React.FC<IProps> = ({
  children, backgroundStyle
}) => {
  return (
    <div className="c-app cone-containers-general" style={backgroundStyle}>
      <div className="c-wrapper">
        <div className="c-body justify-content-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol xs="10" md="7" lg="6" xl="4" className="p-0 m-0">
                {children}
              </CCol>
            </CRow>
          </CContainer>
        </div>

      </div>
    </div>
  )
}

export default memo(TemplateGeneral)
