import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CCard } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import { emailValidationSignIn } from 'commons/helpers/validations/validators-rules'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import useSession from 'templates/context/session-state/useSession'
import useSettings from 'templates/context/session-state/useSettings'
import Button from 'components/form/button'
import CardBody from 'components/card/CardBody'
import updateThemeColor from 'styles/updateThemeColor'

import logoNexaasId from 'assets/images/logo-nexaas-id-icon.png'
import InputEmail from 'components/form/InputEmail'
import Icon from 'components/icon'
import InputPassword from 'components/form/InputPassword'
import { get } from 'commons/helpers/store'
import O_AUTH_URL from './oAuth'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const SignIn: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const api = useApi()
  const history = useHistory()
  const { setSession } = useSession()
  const isContabilone = Boolean(get('isContabilone', false))
  const { settings } = useSettings()

  const queryParams = new URLSearchParams(history.location.search)
  const redirectTo = queryParams.get('redirect') || makeRoute({ path: routePaths.MAIN })
  const email = queryParams.get('email') || ''

  const { control, handleSubmit } = useForm<ISignInForm>({ defaultValues: { email } })

  const doLogin: any = ({ email, password }: ISignInForm) => api.auth.doLogin(email, password)

  const { mutate, data, isLoading: isSending, isSuccess } = useMutation(doLogin)

  const onSubmit: SubmitHandler<ISignInForm> = (data: ISignInForm): void => {
    mutate(data as any)
  }

  useEffect(() => {
    setTitle('Entrar')
    document.getElementById('emailField')?.focus()
  }, [])

  useEffect((): void => {
    if (data) {
      const typedData = data as IAuth

      setSession({
        session: typedData?.session,
        user: typedData?.user,
        settings: {
          ...settings,
          whitelabelSettings: typedData.settings
        }
      })

      updateThemeColor(typedData?.settings?.themeColor)

      history.push(redirectTo)
    }
  }, [isSuccess])

  return (
    <CCard className="p-sm-4 pb-sm-0">
      <CardHeaderLogo />

      <CardBody>
        <h5 className="text-center">Entre para continuar</h5>

        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <InputEmail
            control={control}
            rules={emailValidationSignIn}
            id="emailField"
          />
          <InputPassword
            name="password"
            placeholder="Digite sua senha"
            autoComplete="on"
            control={control}
            rules={{ required: 'Obrigatório' }}
          />
          <Button
            className="mt-0"
            size="sm"
            color="info"
            type="submit"
            title="Entrar"
            isLoading={isSending}
            block
          />
        </form>
      </CardBody>

      <div className="d-flex justify-content-center">
        <p className="text-center mt-1 mx-1 mb-2">
          Ou continue com
          {' '}
        </p>
        <Button
          id="btn-signin-google"
          tooltip="Google"
          icon="cib-google"
          iconSize="sm"
          color="light"
          size="sm"
          href={O_AUTH_URL.GOOGLE}
          disabled={isSending}
          className="mx-1"
        />

        <Button
          id="btn-signin-nexaas"
          tooltip="Nexaas ID"
          color="light"
          size="sm"
          href={O_AUTH_URL.NEXXAS_ID}
          disabled={isSending}
          className="mx-1"
        >
          <img className="c-icon" src={logoNexaasId} alt="Logo Nexxas ID" />
        </Button>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <Button
          to={routePaths.RECOVER_ACCOUNT}
          color="link"
          size="md"
        >
          Não consegue entrar?
        </Button>
        {isContabilone && (
        <>
          <Icon name="cis-circle" width="4px" />
          <Button
            to={routePaths.REGISTER}
            color="link"
            size="md"
          >
            Registrar-se
          </Button>
        </>
        )}
      </div>
      <CardFooterLink />
    </CCard>
  )
}

export default SignIn
