import React, { memo } from 'react'
import {
  Route, useHistory, useLocation
} from 'react-router-dom'
import { CContainer, CLink, CRow } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'
import useSession from 'templates/context/session-state/useSession'

import LogoContabilOneColor from 'assets/images/logo-contabilone-color.svg'

import Footer from './footer'
import useSettings from './context/session-state/useSettings'

const TemplateAuthenticatedFullsize: React.FC<IRoute> = ({
  path, component: Component, exact
}) => {
  const location = useLocation()
  const history = useHistory()
  const { hasExpired } = useSession()
  const { settings, isLocalhost } = useSettings()

  const { search: s, pathname } = location

  if (hasExpired()) {
    const redirectTo = (pathname === '') ? undefined : { redirect: `${pathname}${s}` }

    history.push(makeRoute({
      path: routePaths.SIGN_IN,
      search: redirectTo
    }))

    return null
  }

  return (
    <div className="c-app cone-containers-general">
      <div className="c-wrapper">
        <div className="c-body pt-5">
          <CContainer>
            <CRow className="justify-content-center">
              <div className="mb-2 cone-brand-logo text-center">
                <CLink to={makeRoute({ path: routePaths.MAIN })}>
                  {!settings.whitelabelSettings.darkLogoUrl && (
                    <h1>{settings.whitelabelSettings.name}</h1>
                  )}

                  {settings.whitelabelSettings.darkLogoUrl && (
                    <img
                      src={isLocalhost
                        ? LogoContabilOneColor
                        : settings.whitelabelSettings.darkLogoUrl}
                      className="cone-brand-logo"
                      alt={settings.whitelabelSettings.name}
                    />
                  )}
                </CLink>
              </div>
            </CRow>
            <div className="c-main justify-content-center">

              <Route exact={exact} path={path}>
                <Component />
              </Route>
            </div>
          </CContainer>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default memo(TemplateAuthenticatedFullsize)
