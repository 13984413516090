import LoadingData from './LoadingData'

const LoadingModalStackData: React.FC<ILoadingData> = ({ title, className }) => {
  return (
    <div className="modal-stack">
      <div className="modal-stack-container">
        <LoadingData style={{ marginTop: '48vh', marginLeft: '6vh' }} />
        <div className="modal-stack-background" />
      </div>
    </div>
  )
}

export default LoadingModalStackData
