import parseApiDate from '../../../commons/parseApiDate'

const legalNatureAdapter = (data: any): ILegalNature => {
  const { updatedAt, createdAt, startDate, endDate, ...rest } = data

  return {
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest,
  }
}

export default legalNatureAdapter
