import logger from 'commons/helpers/logger'
import capitalize from 'commons/helpers/text/capitalize'
import RequestException from '../request/RequestException'

export default class APIException extends RequestException implements IAPIException {
  errors?: Array<any>

  constructor(exception: IRequestException) {
    super(exception.code, exception.message, exception.payload)

    this.name = 'APIException'

    this.message = this.payload?.message
      || this.payload?.messages?.join(',')
      || this.message
      || 'API Unknown error'

    this.errors = this.payload?.errors || []
  }

  getErrorType(): string {
    if (this.errors?.length && this.errors[0].fields) {
      return 'field'
    }

    return 'none'
  }

  getMessages(): Array<string> {
    if (typeof this.errors === 'string' || this.errors instanceof String || !Array.isArray(this.errors)) {
      logger.error('==> API RESPONSE ERROR MUST BE REVIEWED')
      return [JSON.stringify(this.errors)]
    }

    return this.errors?.reduce((acc, error) => {
      const { messages } = error || {}
      return !messages ? acc : [...acc, ...messages]
    }, []) || []
  }

  getFields(): Array<IAPIExceptionFields> {
    const errors = this?.payload?.errors || this?.payload?.message

    if (!errors) return []

    return (!Array.isArray(errors)) ? [] : (
      errors.filter(({ field, messages }: any) => !!field || !!messages)
        .map((data: any) => {
          if (typeof data?.messages[0] === 'object') {
            return {
              field: capitalize(data.messages[0].field),
              messages: data.messages?.[0]?.messages
            }
          }

          return {
            ...data,
            getMessage: (): string => {
              const msg = data.message || data.messages || data.messages?.[0]?.messages
              return Array.isArray(msg) ? msg.join(', ') : msg
            }
          }
        })
    )
  }
}
