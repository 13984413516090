import config from '../../@config'
import deviceFactoryAdapter from './adapters/deviceFactoryAdapter'
import RequestApi from '../../services/api'

const endpoints = config.ENDPOINTS.list.devicesLists

export default (api: IAPI): IDevicesListsAPI => {
  async function getFactories(queryParams?: any): Promise<IList<IDeviceFactory>> {
    const params = { page: 1, limit: 20, sort: 'asc', orderBy: ['name'], ...(queryParams || {}) }
    return RequestApi.listAdapter<IDeviceFactory>(
      await api.doRequest('get', endpoints.factories, params) as IAPIResponseList,
      deviceFactoryAdapter
    )
  }

  return {
    getFactories
  }
}
