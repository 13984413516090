import accountcompanyFormAdapter from '../companies/companyFormAdapter'

const accountFormUpdateAdapter = (data: IAccountForm): any => {
  const {
    companies,
    headquarterAccountId,
    currentRulesBaseId,
    ...rest
  } = data

  return {
    ...(currentRulesBaseId
      && { currentRulesBaseId: currentRulesBaseId ? Number(currentRulesBaseId) : 0 }),
    ...((headquarterAccountId || headquarterAccountId === null)
        && { headquarterAccountId: headquarterAccountId || null }),
    companies: companies && companies.map(accountcompanyFormAdapter),
    ...rest
  }
}

export default accountFormUpdateAdapter
