import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CAlert, CCard } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import { emailValidation } from 'commons/helpers/validations/validators-rules'
import makeRoute from 'commons/helpers/route/makeRoute'
import { HeaderStateContext } from 'templates/context/header-state'
import useApi from 'templates/context/api-state/useApi'
import Button from 'components/form/button'
import InputEmail from 'components/form/InputEmail'
import CardBody from 'components/card/CardBody'
import CardFooterLink from './CardFooterLink'
import CardHeaderLogo from './CardHeaderLogo'

const EmailConfirmation: React.FC = () => {
  const { setTitle } = useContext(HeaderStateContext)
  const api = useApi()
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)

  const { control, handleSubmit, getValues } = useForm<IRecoverPasswordForm>({
    defaultValues: { email: queryParams.get('email') || '' }
  })

  const emailConfirmation: any = (email: string) => api.auth.emailConfirmation(email)

  const {
    mutate, isLoading, isSuccess, isError, error
  } = useMutation(emailConfirmation)

  const onSubmit: SubmitHandler<IRecoverPasswordForm> = async (data: IRecoverPasswordForm) => {
    mutate(data.email as any)
  }

  const routeTo = (path: string): void => {
    const email = getValues('email')
    history.push(makeRoute({ path, search: email ? { email } : {} as any }))
  }

  useEffect(() => {
    setTitle('Confirmar e-mail')
  }, [])

  const [messageError, setMessageError] = useState('')

  useEffect(() => {
    const { message, messages, errors, payload } = error ? error as IAPIException : {} as any

    if (message) {
      setMessageError(message)
    } else if (messages) {
      messages && setMessageError(messages[0])
    } else if (errors) {
      errors && setMessageError(errors[0])
    }

    isError && payload?.errors?.forEach((error: any, i: number): void => {
      const { messages } = error || {}

      if (messages) {
        setMessageError(messages[0])
      }
    })
  }, [isError])

  return (
    <CCard className="p-sm-4">
      <CardHeaderLogo />
      <CardBody>
        {isError && (
          <CAlert fade color="danger" className="text-center mt-2">
            <p className="mb-0">
              {messageError}
            </p>
          </CAlert>
        )}
        {(!isSuccess) && (
        <>
          <h6 className="mb-3">Enviaremos um link de confirmação para o seu e-mail.</h6>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              control={control}
              rules={emailValidation}
            />
            <Button
              isLoading={isLoading}
              className="d-flex align-items-center justify-content-center mt-2"
              icon="cil-envelope-closed"
              iconSize="sm"
              color="info"
              type="submit"
              block
            >
              <span className="ml-2">Confirmar e-mail</span>
            </Button>
          </form>
        </>
        )}

        {isSuccess && (
          <CAlert fade color="success" className="text-center mb-0">
            <h4 className="alert-heading mb-1">Pronto!</h4>
            <p className="mb-0">
              Você receberá um e-mail com as instruções para confirmação de e-mail.
            </p>
          </CAlert>
        )}

      </CardBody>

      <Button
        id="btn-recover-password"
        size="md"
        color="link"
        className="d-flex justify-content-center"
        title="Voltar para a página anterior"
        onClick={() => routeTo(routePaths.RECOVER_ACCOUNT)}
        block
      />
      <CardFooterLink />
    </CCard>
  )
}

export default EmailConfirmation
