import removeEmptyValue from '@api/src/commons/removeEmptyValue'
import rastroFormAdapter from './helpers/rastroFormAdapter'
import armaFormAdapter from './helpers/armaFormAdapter'
import imageFormAdapter from './helpers/imageFormAdapter'

const productFormAdapter = (product: IProductForm, specificProd?: string): IProductRegister => {
  const {
    CNPJFab,
    veicProd, med, arma, comb, papel, rastro,
    productImages, productParticipants, highlighted,
    ...data
  } = product

  const handleProd = (): any => {
    switch (specificProd) {
      case ('1'):
        return {
          ...veicProd
        }
      case ('2'):
        return {
          ...med,
          vPMC: med?.vPMC || ''
        }
      case ('3'):
        return armaFormAdapter(product)
      case ('4'):
        return {
          ...comb,
          vPart: comb?.vPart || ''
        }
      case ('5'):
        return {
          ...papel
        }

      default: return null
    }
  }

  const imagesFormatted = imageFormAdapter(productImages)

  const rastroFormatted = rastro ? rastroFormAdapter(rastro) : []
  const specificProdProps = handleProd()

  const formattedQuantityProps = data

  const servicePayload: IServiceForm = {
    companyId: data?.companyId,
    tpProd: data?.tpProd,
    specificProd: '0',
    finalidade: data?.finalidade,
    orig: data?.orig,
    cProd: data?.cProd,
    cProdPreceding: data?.cProdPreceding,
    indProp: data?.indProp,
    cEAN: data?.cEAN,
    cEANTrib: data?.cEANTrib,
    xProd: data?.xProd,
    NBS: data?.NBS,
    EXNBS: data?.EXNBS,
    cListServ: data?.cListServ,
    cServico: data?.cServico,
    qCom: '1.0000',
    qTrib: '1.0000',
    uCom: 'UN',
    uTrib: 'UN',
    vUnCom: formattedQuantityProps?.vUnCom,
    vUnTrib: formattedQuantityProps?.vUnTrib
  }

  const merchandisePayload: any = {
    companyId: data?.companyId,
    tpProd: data?.tpProd,
    specificProd: data?.specificProd,
    finalidade: data?.finalidade,
    orig: data?.orig,
    cProd: data?.cProd,
    cProdPreceding: data?.cProdPreceding,
    indProp: data?.indProp,
    cEAN: data?.cEAN,
    cEANTrib: data?.cEANTrib,
    xProd: data?.xProd,
    NCM: data?.NCM,
    EXTIPI: data?.EXTIPI,
    CEST: data?.CEST,
    ...(data?.NVE?.length && { NVE: data?.NVE }),
    indEscala: data?.indEscala,
    ...(CNPJFab && { CNPJFab: CNPJFab?.replace(/[^0-9]/g, '') }),
    ...(rastroFormatted?.length > 0 && { rastro: rastroFormatted }),
    ...(productParticipants?.length && { productParticipants }),
    ...removeEmptyValue(specificProdProps),
    ...removeEmptyValue(formattedQuantityProps),
    ...(imagesFormatted?.length > 0 && { productImages: imagesFormatted })
  }

  return data?.tpProd === '1' ? removeEmptyValue(servicePayload) : removeEmptyValue(merchandisePayload)
}

export default productFormAdapter
