import RequestException from './RequestException'

function performRequest(url: RequestInfo, options: RequestInit): Promise<any> {
  return fetch(url, options)
    .then(async (response) => {
      const contentType = response.headers.get('content-type')
      const content = (contentType && contentType.indexOf('application/json') !== -1)
        ? 'json'
        : 'text'

      if (!response.ok) {
        const payload = response.body ? await response[content]() : null
        throw new RequestException(response.status, 'Request General Error', payload)
      }

      return response[content]()
    })
}

export default performRequest
