import config from '../../@config'
import RequestApi from '../../services/api'
import terminalAdapter from './terminalAdapter'
import terminalFormAdapter from './terminalFormAdapter'
import terminalFormUpdateAdapter from './terminalFormUpdateAdapter'

const endpoints = config.ENDPOINTS.terminals

const Terminals = (api: IAPI): ITerminalsAPI => {
  function adapter(response: any): ITerminal {
    return terminalAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ITerminal>> {
    const tParams = { ...params, sort: 'asc', orderBy: ['cashierNumber'] }
    return RequestApi.listAdapter<ITerminal>(
      await api.doRequest('get', endpoints.terminals, tParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ITerminal> {
    return RequestApi.itemAdapter<ITerminal>(
      await api.doRequest('get', `${endpoints.terminals}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: ITerminalForm): Promise<string> {
    return (await api.doRequest('post', endpoints.terminals, terminalFormAdapter(data))).message
  }

  async function update(id: string, data: ITerminalForm): Promise<ITerminal> {
    return RequestApi.itemAdapter<ITerminal>(
      await api.doRequest('patch', `${endpoints.terminals}/${id}`, terminalFormUpdateAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ITerminal> {
    return RequestApi.itemAdapter<ITerminal>(
      await api.doRequest('patch', `${endpoints.terminals}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.terminals}/${id}`)).message
  }

  async function getHistory(
    terminalId: string,
  ): Promise<any> {
    return (await api.doRequest('get', `${endpoints.history}/${terminalId}`)).message
  }

  async function getHistoryDetail(
    terminalId: string,
    timeStamp: number,
    params: IPagedFilterParams
  ): Promise<any> {
    return (await api.doRequest('get', `${endpoints.history}/${terminalId}/${timeStamp}`, params)).message
  }

  return {
    getList,
    getById,
    create,
    update,
    updateEnabled,
    remove,
    getHistory,
    getHistoryDetail
  }
}

export default Terminals
