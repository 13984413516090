import config from '../../@config'
import RequestApi from '../../services/api'
import countryAdapter from './adapters/countryAdapter'
import currencyAdapter from './adapters/currencyAdapter'
import stateAdapter from './adapters/stateAdapter'

const endpoints = config.ENDPOINTS.list.locationLists

export default (api: IAPI): ILocationListsAPI => {
  async function getAddressByCep(cep: string): Promise<IAddress> {
    const cepParam = (cep.match(/\d+/g) || []).join('')

    return RequestApi.itemAdapter<IAddress>(
      await api.doRequest('get', `${endpoints.address}/${cepParam}`) as IAPIResponse,
      (data: any): IAddress => data
    )
  }

  async function getStates(params: IGetStates): Promise<IList<IState>> {
    const paramsMerge = { limit: 50, page: 1, ...params }

    return RequestApi.listAdapter<IState>(
      await api.doRequest('get', endpoints.states, paramsMerge) as IAPIResponseList,
      stateAdapter
    )
  }

  async function getUFs(): Promise<IList<IState>> {
    return RequestApi.listAdapter<IState>(
      await api.doRequest('get', endpoints.states) as IAPIResponseList,
      stateAdapter
    )
  }

  async function getCounties(params: IGetCounties): Promise<IList<ICounty>> {
    const countiesParams = { limit: 50, ...params }
    return RequestApi.listAdapter<ICounty>(
      await api.doRequest('get', endpoints.counties, countiesParams) as IAPIResponseList,
      (data: any) => data
    )
  }

  async function getCountyByCMun(cMun: string): Promise<IList<ICounty>> {
    return RequestApi.listAdapter<ICounty>(
      await api.doRequest('get', endpoints.counties, { q: cMun, limit: 10, page: 1 }) as IAPIResponseList,
      (data: any) => data
    )
  }

  async function getCountries(): Promise<IList<ICountry>> {
    return RequestApi.listAdapter<ICountry>(
      await api.doRequest('get', endpoints.countries, { limit: 243, page: 1 }) as IAPIResponseList,
      countryAdapter
    )
  }

  async function getCurrency(searchParams?: string): Promise<IList<ICurrency>> {
    return RequestApi.listAdapter<ICurrency>(
      await api.doRequest('get', endpoints.currencies, {
        limit: 20,
        page: 1,
        ...(searchParams ? { q: searchParams } : {})
      }) as IAPIResponseList,
      currencyAdapter

    )
  }

  return {
    getAddressByCep,
    getStates,
    getUFs,
    getCounties,
    getCountries,
    getCurrencies: getCurrency,
    getCountyByCMun
  }
}
