import CIcon from '@coreui/icons-react'
import React from 'react'

const Icon: React.FC<CIcon> = ({
  className,
  name,
  size,
  customClasses,
  color,
  content,
  ...rest
}) => <CIcon {...{ className, customClasses, name, color, size, ...rest }} />

export default Icon
