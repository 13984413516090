import config from '../../@config'
import RequestApi from '../../services/api'
import whitelabelAdapter from './whitelabelAdapter'

const endpoints = config.ENDPOINTS.whitelabels

const Whitelabels = (api: IAPI): IWhitelabelsAPI => {
  function adapter(response: any): IWhitelabel {
    return whitelabelAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<IWhitelabel>> {
    const whiteParams = { ...params, sort: 'asc', orderBy: ['name'] }
    return RequestApi.listAdapter<IWhitelabel>(
      await api.doRequest('get', endpoints.whitelabels, whiteParams) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<IWhitelabel> {
    return RequestApi.itemAdapter<IWhitelabel>(
      await api.doRequest('get', `${endpoints.whitelabels}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function getContabilone(): Promise<IWhitelabel> {
    return RequestApi.itemAdapter<IWhitelabel>(
      await api.doRequest('get', endpoints.contabilone),
      (data: any) => adapter(data)
    )
  }

  async function getRoot(): Promise<IWhitelabel | undefined> {
    return (await getList({ limit: 10000, page: 1 }))
      .items
      .find(({ root }) => root)
  }

  async function create(data: IWhitelabelForm): Promise<string> {
    return (await api.doRequest('post', endpoints.whitelabels, data)).message
  }

  async function update(id: string, data: IWhitelabelForm): Promise<IWhitelabel> {
    return RequestApi.itemAdapter<IWhitelabel>(
      await api.doRequest('patch', `${endpoints.whitelabels}/${id}`, data),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<IWhitelabel> {
    return RequestApi.itemAdapter<IWhitelabel>(
      await api.doRequest('patch', `${endpoints.whitelabels}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.whitelabels}/${id}`)).message
  }

  async function getThemeSettings(): Promise<IWhitelabelSettings> {
    return RequestApi.itemAdapter<IWhitelabelSettings>(
      await api.doRequest('get', endpoints.themeSettings, undefined, { noAuthorization: true }) as IAPIResponseList,
      (data: any) => data
    )
  }

  async function generateToken(data: {whitelabelId: string}): Promise<string> {
    return (await api.doRequest('post', endpoints.generateToken, data)).message
  }

  async function activateToken(id: string, token: string): Promise<string> {
    return (await api.doRequest('patch', `${endpoints.activateToken.replace('{ID}', id)}`, ({ newToken: token }))).message
  }

  return {
    getList,
    getById,
    getContabilone,
    getRoot,
    create,
    update,
    updateEnabled,
    remove,
    getThemeSettings,
    generateToken,
    activateToken
  }
}

export default Whitelabels
