import React, { useCallback } from 'react'
import { useController } from 'react-hook-form'

import InputField from './fields/InputField'

const Input: React.FC<IController & IInputField> = ({
  control,
  rules,
  name,
  onChange,
  onPaste,
  fractionDigits,
  maskRegex,
  defaultValue,
  wrapperStyle,
  ...props
}) => {
  const { field: { ref, ...field }, fieldState, formState } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || ''
  })

  const handleChange = useCallback((event: any): void => {
    maskRegex ? field.onChange(
      { target: { ...event.target,
        value: fractionDigits
          ? maskRegex(event.target.value, fractionDigits) : maskRegex(event.target.value) } }
    ) : field.onChange(event)
    onChange && onChange(event)
  }, [field, onChange])

  return (
    <div style={wrapperStyle}>
      <InputField
        valid={formState.isValid}
        invalid={fieldState.invalid}
        error={fieldState.error?.message}
        innerRef={ref}
        {...field}
        {...props}
        onChange={handleChange}
      />
    </div>
  )
}

export default Input
