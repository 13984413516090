import React from 'react'
import { CFormGroup } from '@coreui/react'

import IconWithTooltip from 'components/icon-with-tooltip'
import Label from './Label'
import Message from './Message'

const Text: React.FC<IText> = ({
  value, color, bgColor, label, strong, className,
  noMargin, messages, error, children, classNameFormGroup, tooltipContent
}) => {
  const text = (value === null || value === undefined || value === '') && !children ? '-' : value

  const backgroundColor = bgColor ? `bg-${bgColor}` : 'bg-light'
  const textColor = color ? `text-${color}` : ''

  return (
    <CFormGroup className={`${noMargin ? 'm-0' : ''} ${classNameFormGroup || ''} `}>
      <Label text={label}>
        {tooltipContent && <IconWithTooltip content={tooltipContent} />}
        <div className={`form-control form-control-text text-overflow ${backgroundColor} ${textColor} ${className || ''}`}>
          <span>{strong ? <strong>{text}</strong> : text}</span>
          {children}
        </div>
      </Label>
      <Message messages={messages} error={error} />
    </CFormGroup>
  )
}

export default Text
