import removeCurrencyFormat from '../../../commons/formatApiCurency'
import { checkEmptyObject, removeEmptyValue, removeSpecificProdEmptyValue } from './functionsInvoiceAdapter'

const ICMSUFDestValues = [
  'vBCFCPUFDest',
  'vBCUFDest',
  'pFCPUFDest',
  'pICMSUFDest',
  'pICMSInter',
  'pICMSInterPart',
  'vFCPUFDest',
  'vICMSUFDest',
  'vICMSUFRemet'
]

const formatObject = (item: any): any => {
  const items = Array.isArray(item) && item?.map((obj: any) => {
    const objValues = Object.values(obj)
    const objChild = objValues.find((value) => typeof value !== 'string') as any
    if (objChild) {
      return Object.entries(objChild).reduce((acc, item) => {
        const [key, value] = item as any
        if (value?.code) {
          return {
            ...acc,
            [key]: value?.code
          }
        }
        return {
          ...acc,
          [key]: value
        }
      }, {})
    }
    return Object.fromEntries([objValues])
  })
  return items ? Object.assign({}, ...items) : {}
}

const productInvoiceFormAdapter = (
  det: any
): any => {
  const detFormatted = det?.map((item: any) => {
    const { imposto, prod, infAdProd } = item
    const { vFrete, vSeg, vOutro, vDesc,
      vProd, vUnTrib, vUnCom, CNPJFab, DI, NVE, detExport,
      papel, rastro, cProdPreceding, indProp, openingValue,
      finalBalance, openingBalance,
      veicProd, med, comb, bebida, fumo,
      nRECOPI, cEnq, arma, orig, ...rest } = prod || {}
    const rastroData = (): any[] => {
      if (rastro?.length) {
        const gtmRegex = /T.+/g
        return rastro?.map(({ dFab, dVal, ...rest }: any) => {
          const formattedDFab = dFab.replace(gtmRegex, '')
          const formattedDVal = dVal.replace(gtmRegex, '')

          return ({
            ...(dFab ? { dFab: formattedDFab } : {}),
            ...(dVal ? { dVal: formattedDVal } : {}),
            ...rest
          })
        })
      }
      return []
    }

    const rastroFormatted = (rastroData())?.map((item: any): any => {
      const rastro = {
        nLote: item?.nLote,
        qLote: item?.qLote?.replace(',', '.'),
        dFab: item?.dFab,
        dVal: item?.dVal,
        cAgreg: item?.cAgreg,
      }
      return rastro && removeEmptyValue(rastro)
    })

    const specificProdFunction = () : any => {
      if (rest?.specificProd) {
        return rest?.specificProd
      }
      if (!checkEmptyObject(veicProd)) {
        return '1'
      }
      if (!checkEmptyObject(med)) {
        return '2'
      }
      if (!checkEmptyObject(arma?.[0])) {
        return '3'
      }
      if (!checkEmptyObject(comb) && comb.cProdANP) {
        return '4'
      }
      if (nRECOPI) {
        return '5'
      }
      if (!checkEmptyObject(bebida)) {
        return '6'
      }
      if (!checkEmptyObject(fumo)) {
        return '7'
      }

      return ''
    }
    const specificProd = specificProdFunction()

    const getArma = (): any => {
      if (Array.isArray(arma)) {
        return arma?.map(({ tpArma, descr, nCano, nSerie }: any) => {
          return {
            tpArma: (typeof tpArma === 'string' ? tpArma : tpArma?.code) || '',
            descr,
            nCano,
            nSerie
          }
        })
      }

      return [arma]
    }

    const handleProd = (): any => {
      switch (specificProd) {
        case ('1'):
          return {
            veicProd: removeSpecificProdEmptyValue({
              ...veicProd,
            })
          }
        case ('2'):
          return {
            med: removeSpecificProdEmptyValue({
              ...med,
              cProdAnvisa: med?.cProdAnvisa?.toUpperCase(),
              vPMC: med?.vPMC
            })
          }
        case ('3'):
          return {
            arma: getArma()
          }
        case ('4'): {
          const { CIDE, encerrante, ...restComb } = comb
          const CIDEData = { ...removeSpecificProdEmptyValue({
            ...CIDE,
            vAliqProd: removeCurrencyFormat(CIDE?.vAliqProd),
            vCIDE: removeCurrencyFormat(CIDE?.vCIDE)
          }) }

          const encerranteData = removeSpecificProdEmptyValue({
            ...encerrante,
            vEncIni: removeCurrencyFormat(encerrante?.vEncIni),
            vEncFin: removeCurrencyFormat(encerrante?.vEncFin)
          })
          return {
            comb: removeSpecificProdEmptyValue({
              ...restComb,
              ...((typeof comb?.cProdANP === 'object')
                ? {
                  cProdANP: comb?.cProdANP?.[0]?.value,
                  descANP: comb?.cProdANP?.[0]?.label
                }
                : { cProdANP: comb?.cProdANP }),
              ...(!checkEmptyObject(removeSpecificProdEmptyValue(CIDEData))
                ? { CIDE: CIDEData } : {}),
              ...(!checkEmptyObject(encerranteData) ? { encerrante: encerranteData } : {}),
              vPart: comb?.vPart
            })
          } }
        case ('5'):
          return { nRECOPI }
        case ('6'):
          return {
            ...removeSpecificProdEmptyValue({
              ...bebida
            })
          }
        case ('7'):
          return {
            ...removeSpecificProdEmptyValue({
              ...fumo
            })
          }

        default: return null
      }
    }

    const specificProdProps = handleProd()
    delete rest.fat_conv
    delete rest.unid_inv
    delete rest.rastro
    delete rest.specificProd
    delete rest.tpProd

    const formattedNVE = NVE ? NVE?.reduce((acc: any, item: any) => {
      if (Array.isArray(item)) {
        return [
          ...acc,
          ...item
        ]
      }
      return [
        ...acc, item
      ]
    }, []) : []

    const getSpecificProd = (): any => {
      if (specificProd === '3') {
        return specificProdProps
      }
      return !checkEmptyObject(specificProdProps) ? specificProdProps : {}
    }

    const getDI = (): any => {
      if (!DI?.length) {
        return {}
      }
      const formatData = Array.isArray(DI) && DI?.map((item: any) => {
        const data = {
          ...item,
          ...(item?.dDI ? { dDI: item?.dDI } : {}),
          ...(item?.dDesemb ? { dDesemb: item?.dDesemb } : {}),
          ...(item?.CNPJ ? { CNPJ: item?.CNPJ?.replace(/[^0-9]/g, '') } : {}),
          ...(item?.CPF ? { CPF: item?.CPF?.replace(/[^0-9]/g, '') } : {})
        }
        return data && removeEmptyValue(data)
      })
      return formatData && { DI: formatData }
    }

    const getExportData = (): any => {
      if (!detExport) return undefined
      if (Array.isArray(detExport)) {
        return detExport?.map((item: any) => {
          const { nDraw, exportInd } = item || {}
          return ({
            nDraw,
            exportInd: {
              ...(exportInd?.nRE ? { nRE: exportInd?.nRE } : {}),
              ...(exportInd?.chNFe ? { chNFe: exportInd?.chNFe } : {}),
              ...(exportInd?.qExport ? { qExport: exportInd?.qExport } : {}),
            }
          })
        })
      }
      return [({
        nDraw: detExport?.nDraw,
        exportInd: {
          ...(detExport?.exportInd?.nRE ? { nRE: detExport?.exportInd?.nRE } : {}),
          ...(detExport?.exportInd?.chNFe ? { chNFe: detExport?.exportInd?.chNFe } : {}),
          ...(detExport?.exportInd?.qExport ? { qExport: detExport?.exportInd?.qExport } : {}),
        }
      })]
    }
    const detExportData = getExportData()

    const prodFormatted = {
      ...removeEmptyValue(rest),
      finalidadeEmit: prod?.finalidadeEmit,
      finalidadeDest: prod?.finalidadeDest,
      cProd: prod?.cProd,
      cEAN: prod?.cEAN,
      xProd: prod?.xProd,
      NCM: prod?.NCM,
      ...(NVE?.length !== 0 ? { NVE: formattedNVE } : {}),
      CEST: prod?.CEST,
      indEscala: prod?.indEscala,
      ...(prod?.indEscala === 'N' ? { CNPJFab: CNPJFab?.replace(/[^0-9]/g, '') } : {}),
      cBenef: prod?.cBenef,
      EXTIPI: prod?.EXTIPI,
      CFOP: prod?.CFOP,
      uCom: prod?.uCom,
      qCom: prod?.qCom,
      vUnCom: removeCurrencyFormat(vUnCom),
      vProd: removeCurrencyFormat(vProd),
      cEANTrib: prod?.cEANTrib,
      uTrib: prod?.uTrib,
      qTrib: prod?.qTrib,
      vUnTrib: removeCurrencyFormat(vUnTrib),
      vFrete: removeCurrencyFormat(vFrete),
      vSeg: removeCurrencyFormat(vSeg),
      vDesc: removeCurrencyFormat(vDesc),
      vOutro: removeCurrencyFormat(vOutro),
      indTot: prod?.indTot,
      ...(getDI()),
      ...(detExportData ? { detExport: detExportData } : {}),
      xPed: prod?.xPed,
      nItemPed: prod?.nItemPed,
      nFCI: prod?.nFCI,
      ...(rastroFormatted?.length !== 0 ? { rastro: rastroFormatted } : {}),
      ...(!['7', '6'].includes(specificProd) ? getSpecificProd() : {}),
      ...(removeEmptyValue(rest?.gCred)
        ? { gCred: rest?.gCred } : {}),
    }
    const filteredICMS = imposto?.ICMS?.filter(({ name }: any) => !ICMSUFDestValues?.includes(name))
    const filteredICMSUFDest = imposto?.ICMS
      ?.filter(({ name }: any) => ICMSUFDestValues?.includes(name))
    const ICMS = formatObject(filteredICMS)
    const ICMSUFDest = formatObject(filteredICMSUFDest)
    const IPI = formatObject(imposto?.IPI)
    const ISSQN = formatObject(imposto?.ISSQNList)
    const PIS = formatObject(imposto?.PIS)
    const PISST = formatObject(imposto?.PISST)
    const COFINS = formatObject(imposto?.COFINS)
    const COFINSST = formatObject(imposto?.COFINSST)
    const II = formatObject(imposto?.II)

    const ICMSObj = {
      ...ICMS,
      ...(orig || ICMS?.orig ? { orig: orig || ICMS?.orig } : {}),
      vBC: removeCurrencyFormat(ICMS.vBC),
      vICMS: removeCurrencyFormat(ICMS.vICMS),
      vBCFCP: removeCurrencyFormat(ICMS.vBCFCP),
      vFCP: removeCurrencyFormat(ICMS.vFCP),
      vBCST: removeCurrencyFormat(ICMS.vBCST),
      vICMSST: removeCurrencyFormat(ICMS.vICMSST),
      vBCFCPST: removeCurrencyFormat(ICMS.vBCFCPST),
      vFCPST: removeCurrencyFormat(ICMS.vFCPST),
      vICMSDif: removeCurrencyFormat(ICMS.vICMSDif),
      vBCSTRet: removeCurrencyFormat(ICMS.vBCSTRet),
      vICMSSubstituto: removeCurrencyFormat(ICMS.vICMSSubstituto),
      vICMSSTRet: removeCurrencyFormat(ICMS.vICMSSTRet),
      vBCFCPSTRet: removeCurrencyFormat(ICMS.vBCFCPSTRet),
      vFCPSTRet: removeCurrencyFormat(ICMS.vFCPSTRet),
      vBCEfet: removeCurrencyFormat(ICMS.vBCEfet),
      vICMSEfet: removeCurrencyFormat(ICMS.vICMSEfet),
      vICMSDeson: removeCurrencyFormat(ICMS.vICMSDeson),
    }

    const ICMSUFDestObj = {
      ...ICMSUFDest,
      vBCFCPUFDest: removeCurrencyFormat(ICMSUFDest.vBCFCPUFDest),
      vBCUFDest: removeCurrencyFormat(ICMSUFDest.vBCUFDest),
      vFCPUFDest: removeCurrencyFormat(ICMSUFDest.vFCPUFDest),
      vICMSUFDest: removeCurrencyFormat(ICMSUFDest.vICMSUFDest),
      vICMSUFRemet: removeCurrencyFormat(ICMSUFDest.vICMSUFRemet),
    }

    const getImpostDevol = (): any => {
      const { vIPIDevol, pDevol } = IPI || {}
      if (!vIPIDevol && !pDevol) return undefined
      delete IPI?.vIPIDevol
      delete IPI?.pDevol
      return {
        ...(pDevol ? { pDevol } : {}),
        ...(vIPIDevol ? { IPI: { vIPIDevol: removeCurrencyFormat(vIPIDevol) } } : {})
      }
    }

    const impostoDevol = getImpostDevol()
    const IPIObj = {
      ...IPI,
      vBC: removeCurrencyFormat(IPI.vBC),
      vUnid: removeCurrencyFormat(IPI.vUnid),
      vIPI: removeCurrencyFormat(IPI.vIPI),
      vIPIDevol: removeCurrencyFormat(IPI.vIPIDevol),
      ...(['7', '6'].includes(specificProd) ? getSpecificProd() : {})
    }

    const PISObj = {
      ...PIS,
      vBC: removeCurrencyFormat(PIS.vBC),
      vAliqProd: removeCurrencyFormat(PIS.vAliqProd),
      vPIS: removeCurrencyFormat(PIS.vPIS),
    }
    const PISSTObj = {
      ...PISST,
      vBC: removeCurrencyFormat(PISST.vBC),
      vAliqProd: removeCurrencyFormat(PISST.vAliqProd),
      vPIS: removeCurrencyFormat(PISST.vPIS),
    }
    const COFINSObj = {
      ...COFINS,
      vBC: removeCurrencyFormat(COFINS.vBC),
      vAliqProd: removeCurrencyFormat(COFINS.vAliqProd),
      vCOFINS: removeCurrencyFormat(COFINS.vCOFINS),
    }
    const COFINSSTObj = {
      ...COFINSST,
      vBC: removeCurrencyFormat(COFINSST.vBC),
      vAliqProd: removeCurrencyFormat(COFINSST.vAliqProd),
      vCOFINS: removeCurrencyFormat(COFINSST.vCOFINS),
    }

    const ISSQNObj = {
      ...ISSQN,
      vBC: removeCurrencyFormat(ISSQN.vBC),
      vISSQN: removeCurrencyFormat(ISSQN.vISSQN),
      vDeducao: removeCurrencyFormat(ISSQN.vDeducao),
      vDescCond: removeCurrencyFormat(imposto?.ISSQN?.vDescCond)
        || removeCurrencyFormat(ISSQN?.vDescCond),
      vOutro: removeCurrencyFormat(imposto?.ISSQN?.vOutro) || removeCurrencyFormat(ISSQN.vOutro)
    }
    const IIObj = {
      ...II,
      vBC: removeCurrencyFormat(imposto?.II?.vBC),
      vDespAdu: removeCurrencyFormat(imposto?.II?.vDespAdu),
      vII: removeCurrencyFormat(imposto?.II?.vII),
      vIOF: removeCurrencyFormat(imposto?.II?.vIOF),
    }

    const impostoFormatted = removeEmptyValue({

      ...(imposto?.vTotTrib !== '' && imposto?.vTotTrib !== undefined
        ? { vTotTrib: removeCurrencyFormat(imposto.vTotTrib) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(ICMSObj))
        ? { ICMS: removeEmptyValue(ICMSObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(ICMSUFDestObj))
        ? { ICMSUFDest: removeEmptyValue(ICMSUFDestObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(IPIObj))
        ? { IPI: removeEmptyValue(IPIObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(ISSQNObj))
        ? { ISSQN: removeEmptyValue(ISSQNObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(PISObj))
        ? { PIS: removeEmptyValue(PISObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(PISSTObj))
        ? { PISST: removeEmptyValue(PISSTObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(COFINSObj))
        ? { COFINS: removeEmptyValue(COFINSObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(COFINSSTObj))
        ? { COFINSST: removeEmptyValue(COFINSSTObj) } : {}),

      ...(!checkEmptyObject(removeEmptyValue(IIObj))
        ? { II: removeEmptyValue(IIObj) } : {}),
    })

    return {
      ...(!checkEmptyObject(impostoFormatted)
        ? { imposto: impostoFormatted } : {}),
      ...(!checkEmptyObject(removeEmptyValue(prodFormatted))
        ? { prod: {
          ...removeEmptyValue(prodFormatted),
        } } : {}),
      ...(infAdProd ? { infAdProd } : {}),
      ...(impostoDevol ? { impostoDevol } : {})
    }
  })
  return detFormatted
}

export default productInvoiceFormAdapter
