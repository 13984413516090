import React, { useContext } from 'react'
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react'
import { ToasterStateContext } from 'templates/context/toaster-state'

const Toaster: React.FC = () => {
  const { toaster } = useContext(ToasterStateContext)

  return (
    <CToaster position="bottom-right" className="text-break-line">
      {toaster?.map((toast: IToast) => {
        return (
          <CToast
            show
            fade
            autohide={toast?.timeout || 7000}
            color={toast.color || undefined}
            key={toast?.id}
          >
            <CToastHeader closeButton>{toast.title}</CToastHeader>
            {toast.message && <CToastBody>{toast.message}</CToastBody>}
          </CToast>
        )
      })}
    </CToaster>
  )
}

export default React.memo(Toaster)
