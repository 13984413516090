const infIntermedInvoiceFormAdapter = (
  infIntermed: any
): any => {
  return {
    ...(infIntermed?.CNPJ !== '' && infIntermed?.CNPJ !== undefined ? {
      CNPJ: infIntermed?.CNPJ?.replace(/[^0-9]/g, '') } : {}),
    ...(infIntermed?.idCadIntTran !== '' && infIntermed?.CNPJ !== undefined ? {
      idCadIntTran: infIntermed?.idCadIntTran } : {}),
  }
}

export default infIntermedInvoiceFormAdapter
