import { useCallback, useContext } from 'react'

import SessionStateContext from './SessionStateContext'

interface IUseSession {
  session: any,
  hasExpired: any,
  getTimeLeft: any,
  setSession: any,
  clean: any
}

export default function useSession(): IUseSession {
  const { data, setData } = useContext(SessionStateContext)
  const { session } = data || {}

  const setSession = useCallback(
    (session) => {
      setData({ ...data, ...session })
    },
    [session, setData]
  )

  const clean = useCallback(
    () => {
      const isSignIn = window?.location?.pathname.includes('/sign-in')
      if (!isSignIn) {
        const { user, session, ...rest } = data || {}
        setData(rest)
      }
    }, [setData]
  )

  const hasExpired = useCallback(
    () => !session?.expires || (session?.expires.getTime()) < (new Date()).getTime(),
    [session?.expires]
  )

  const getTimeLeft = useCallback(
    () => session?.expires && (session?.expires.getTime() - (new Date()).getTime()),
    [session?.expires]
  )

  return { session, hasExpired, getTimeLeft, setSession, clean }
}
