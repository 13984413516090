import masks from '@config/masks'
import mask from 'commons/helpers/formatter/formatNumberMask'

const formatCompanyLabel = (name?: string, cnpjBase?: string): string => (
  `${name} - ${mask(cnpjBase, masks.cnpjBase)}`
)

const formatSubsidiaryLabel = (cnpj?: string, ie?: string, xFant?: string): string => {
  const document = cnpj ? mask(cnpj, masks.cnpj) : '-'
  return (
    `${document ? `${document} - ` : ''}
        ${ie ? `${ie} - ` : ''}
        ${xFant?.toUpperCase()}
        `
  )
}

export { formatCompanyLabel, formatSubsidiaryLabel }
