import React, { memo, Suspense } from 'react'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router'

import routePaths from 'routes/routes-paths'
import LoadingModalStackData from 'components/loading/LoadingModalStackData'
import useRoute from 'commons/helpers/route/useRoute'

import TemplateModalsContent from './TemplateModalsContent'

const TemplateModals: React.FC<ITemplateModals> = ({ modals }) => {
  const { currentRoute } = useRoute()
  const { path } = useRouteMatch()
  const { location } = useHistory()

  return (
    <Suspense fallback={<LoadingModalStackData />}>
      <Switch>
        <Route path={`${path}${routePaths.ACTION_SUB_ROUTE}`}>
          <TemplateModalsContent goBack={currentRoute + location.search} modals={modals} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default memo(TemplateModals)
