import { DocumentFormatted, removeEmptyValue } from './functionsInvoiceAdapter'

const retiradaInvoiceFormAdapter = (
  retirada: any
): any => {
  const { DOCUMENT: DocumentRetirada, fone, CEP, ...restRetirada } = retirada

  const RetiradaFormatted = {
    ...removeEmptyValue(restRetirada),
    ...(DocumentRetirada !== '' ? DocumentFormatted(DocumentRetirada) : {}),
    ...(fone !== '' ? { fone: retirada?.fone.replace(/[^0-9]/g, '') } : {}),
    ...(CEP !== '' ? { CEP: retirada?.CEP.replace(/[^0-9]/g, '') } : {}),
  }
  return RetiradaFormatted
}

export default retiradaInvoiceFormAdapter
