import { generatePath } from 'react-router'

const makeRoute = ({ path, params, search }: IMakeRoute): string => {
  // Create a string with query params
  const queryParams = new URLSearchParams(search).toString()

  return generatePath(path || '/404', params) + (queryParams ? `?${queryParams}` : '')
}

export default makeRoute
