import parseApiDate from '../../../commons/parseApiDate'

const taxInfoAdapter = (data: any): ITaxInfo => {
  const {
    startDate, endDate, createdAt, updatedAt, ...rest
  } = data

  return {
    ...rest,
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate)
  }
}

export default taxInfoAdapter
