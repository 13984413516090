import parseApiDate from '../../commons/parseApiDate'

const departmentsAdapter = (data: any): IDepartment => {
  const {
    startDate, endDate, createdAt, updatedAt, ...rest
  } = data

  return {
    ...rest,
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default departmentsAdapter
