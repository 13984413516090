import parseApiDate from '../../../commons/parseApiDate'

const legalNatureDescAdapter = (data: any): ILegalNatureDesc => {
  const { updatedAt, createdAt, startDate, endDate, grupo, ...rest } = data

  return {
    ...rest,
    grupo: grupo && legalNatureDescAdapter(grupo),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default legalNatureDescAdapter
