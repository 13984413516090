import cleanNumber from '../../commons/cleanNumber'

const participantStateTaxFormAdapter = (
  data: IParticipantStateTaxForm
): IParticipantStateTaxForm => {
  const {
    stateTaxes,
    ...rest
  } = data

  return {
    stateTaxes: stateTaxes?.map(({ ie, ...rest }) => (
      { ie: cleanNumber(ie), ...rest }
    )),
    ...rest
  }
}

export default participantStateTaxFormAdapter
