const removeEmptyValue = (obj: any): any => {
  const objTemp: any = {}
  obj && Object.entries(obj)?.forEach((entry: any) => {
    const [key, value] = entry;
    if (value !== '' && value !== undefined && value !== null && value?.length !== 0) { objTemp[key] = value }
  });

  return objTemp
}

export default removeEmptyValue
