import parseApiDate from '../../../commons/parseApiDate'

const deviceModelAdapter = ({ createdAt, updatedAt, ...rest }: any): IDeviceModel => {
  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest,
  }
}

export default deviceModelAdapter
