import React, { memo } from 'react'
import { CToggler, CHeader, CHeaderNav } from '@coreui/react'

import useSettings from 'templates/context/session-state/useSettings'

import BreadCrumb from 'templates/breadcrumb/Breadcrumb'
import ProfileNav from './ProfileNav'
import SubHeader from './SubHeader'

const Header: React.FC = () => {
  const { settings, setSettings } = useSettings()

  const toggleSidebarMobile = (): void => {
    const val = [false, 'responsive'].includes(settings.sidebarShow) ? true : 'responsive'
    setSettings({ ...settings, sidebarShow: val })
  }

  return (
    <>
      <CHeader className="flex-nowrap shadow-none" withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-xl-none"
          onClick={toggleSidebarMobile}
        />

        <BreadCrumb />
        <CHeaderNav className="mr-auto" />

        <CHeaderNav>
          <ProfileNav />
        </CHeaderNav>

      </CHeader>
      <SubHeader />
    </>
  )
}

export default memo(Header)
