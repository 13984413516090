import { removeEmptyValue } from '../invoice/form/functionsInvoiceAdapter'

const webhookCreateAdapter = (data: any): IWebhook => {
  const { invoiceDistributionEvent, invoiceDuplicateEvent,
    invoiceDownloadPackageEvent, invoiceEvent, invoiceRecoveryEvent, ...rest } = data

  return {
    ...removeEmptyValue(rest),
    invoiceDownloadPackageEvent,
    invoiceDistributionEvent,
    invoiceDuplicateEvent,
    invoiceEvent,
    invoiceRecoveryEvent
  }
}

export default webhookCreateAdapter
