import React from 'react'
import { CButton } from '@coreui/react'

import routePaths from 'routes/routes-paths'
import makeRoute from 'commons/helpers/route/makeRoute'

const prodEnviroment = process.env.REACT_APP_CONE_API_ENVIRONMENT === 'production'

const NotFound: React.FC = () => (
  <div>
    <div className="clearfix">
      <h1 className="float-left display-3 mr-4">404</h1>
      <h3 className="pt-3">Página não encontrada</h3>
      <div>
        Não encontramos a página solicitada. Retorne a nossa página inicial
        ou acesse nossa Ajuda para informações

      </div>
    </div>
    <div className="text-right mt-3">
      <CButton
        color="success"
        size="12"
        to={makeRoute({ path: prodEnviroment ? routePaths.INVOICES : routePaths.MAIN })}
      >
        Ir para home
      </CButton>
    </div>
  </div>
)

export default NotFound
