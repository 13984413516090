const imageFormAdapter = (productImages: any) : any => {
  return productImages?.map((item: IImageProduct): any => {
    const { imageUrl, imageBase64, ...rest } = item || {}
    const imageFormatted = {
      ...rest,
      ...(imageBase64 ? { imageBase64 } : { imageUrl }),
    }
    return imageFormatted
  })
}

export default imageFormAdapter
