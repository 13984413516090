import parseApiDate from '@api/src/commons/parseApiDate'

const diaryAdapter = (data: any): IDiary => {
  const {
    createdAt, updatedAt,
    endDate,
    fiscalYearEndDate,
    startDate,
    ...rest
  } = data

  // @todo verify if API send "createdAt" and "updatedAt" by default
  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    fiscalYearEndDate: parseApiDate(fiscalYearEndDate),
    ...rest,
  }
}

export default diaryAdapter
