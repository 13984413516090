import scriptExists from './scriptExists'

export default function scriptLoad ({ id, src, defer }) {
  if (scriptExists(src)) return Promise.resolve()

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.defer = defer
    script.async = !defer
    script.src = src
    script.id = id
    document.body.appendChild(script)

    script.onload = resolve()
    script.onerror = (e) => reject(e)
  })
}
