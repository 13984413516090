import parseApiDate from '../../commons/parseApiDate'

import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'
import acquirerParticipantAdapter from '../lists/adapters/acquirerParticipantAdapter'
import merchantTypeAdapter from '../lists/adapters/merchantTypeAdapter'
import companyAdapter from '../companies/companyAdapter'

const acquirerAdapter = (data: any): IAcquirer => {
  const {
    subsidiary, company, acquirer,
    merchantIdType, updatedAt, createdAt,
    ...rest
  } = data

  return {
    ...rest,
    acquirer: acquirer && acquirerParticipantAdapter(acquirer),
    company: company && companyAdapter(company),
    subsidiary: subsidiary && subsidiaryAdapter(subsidiary),
    merchantIdType: merchantIdType && merchantTypeAdapter(merchantIdType),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt)
  }
}

export default acquirerAdapter
