import parseApiDate from '../../commons/parseApiDate'

const timelineAdapter = (data: any): ITimeline => {
  const { createdAt, updatedAt, ...rest } = data

  // @todo verify if API send "createdAt" and "updatedAt" by default
  return {
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    ...rest
  }
}

export default timelineAdapter
