import { useCallback, useState } from 'react'

import { adapters } from '@api/src'
import { get, update } from 'commons/helpers/store'
import SessionStateContext from './SessionStateContext'
import defaultValue from './defaultValue'

const SessionStateProvider: any = ({ children }: any) => {
  const coneData = get('cone-data', {})
  const dataValue = { ...defaultValue }

  if (coneData?.session) dataValue.session = adapters.sessionAdapter(coneData?.session)
  if (coneData?.user) dataValue.user = adapters.userAdapter(coneData?.user)
  if (coneData?.settings) {
    dataValue.settings = {
      ...defaultValue.settings,
      ...coneData?.settings
    }
  }

  const [data, setState] = useState(dataValue)

  const setData = useCallback((data: any): void => {
    setState(update('cone-data', data))
  }, [setState, update])

  const reset = useCallback((): void => {
    setData(defaultValue)
  }, [setData])

  const contextValue = { data, setData, reset }

  return (
    <SessionStateContext.Provider value={contextValue}>
      {children}
    </SessionStateContext.Provider>
  )
}

export default SessionStateProvider
