import ErrorServerUnavailable from 'pages/error/ErrorServerUnavailable'
import InviteConfirmation from 'pages/auth/InviteConfirmation'
import OnboardWhitelabelFinish from 'pages/auth/OnboardWhitelabelFinish'
import RecoverPassword from 'pages/auth/RecoverPassword'
import RecoverPasswordConfirmation from 'pages/auth/RecoverPasswordConfirmation'
import Register from 'pages/auth/Register'
import RegisterConfirmation from 'pages/auth/RegisterConfirmation'
import SignIn from 'pages/auth/SignIn'
import SigninConfirmation from 'pages/auth/SignInConfirmation'

import EmailConfirmation from 'pages/auth/EmailConfirmation'
import RecoverAccount from 'pages/auth/RecoverAccount'
import routePaths from './routes-paths'

const routesGeneral: Array<IRoute> = [
  {
    component: SignIn,
    path: routePaths.SIGN_IN,
    exact: true
  },
  {
    component: RecoverAccount,
    path: routePaths.RECOVER_ACCOUNT,
    exact: true
  },
  {
    component: SigninConfirmation,
    path: routePaths.SIGN_IN_CONFIRMATION_SERVICE,
    exact: true
  },
  {
    component: Register,
    path: routePaths.REGISTER,
    exact: true
  },
  {
    component: RegisterConfirmation,
    path: routePaths.REGISTER_CONFIRMATION,
    exact: true
  },
  {
    component: RecoverPassword,
    path: routePaths.RECOVER_PASSWORD,
    exact: true
  },
  {
    component: RecoverPasswordConfirmation,
    path: routePaths.RECOVER_PASSWORD_CONFIRMATION,
    exact: true
  },
  {
    component: EmailConfirmation,
    path: routePaths.EMAIL_CONFIRMATION,
    exact: true
  },
  {
    component: InviteConfirmation,
    path: routePaths.INVITE_CONFIRMATION,
    exact: true
  },
  {
    component: ErrorServerUnavailable,
    path: routePaths.SERVER_UNAVAILABLE,
    exact: true
  },
  {
    component: OnboardWhitelabelFinish,
    path: routePaths.ONBOARD_FINISH,
    exact: true
  }
]

export default routesGeneral
