import React from 'react'

import { useZendeskWidgetCustomized } from 'commons/lib/zendesk-widget'
import Button from 'components/form/button'

const zendeskKey = process.env.REACT_APP_ZENDESK_KEY

const HelpChat: React.FC = () => {
  const {
    isCompleted, isLoading, isOpen, open, hideButton
  } = useZendeskWidgetCustomized(zendeskKey)

  if (isCompleted) hideButton()

  return (
    <>
      <Button
        id="btn-chat-2"
        color="dark"
        icon={`${isLoading ? 'cil-reload' : 'cil-chat-bubble'}`}
        className={`chat-button rounded-circle p-0 d-lg-none ${isLoading ? 'loading' : ''} ${isOpen ? 'hide' : ''}`}
        style={{ width: '32px', height: '32px' }}
        onClick={open}
      />

      <Button
        id="btn-chat"
        color="dark"
        title="Ajuda"
        icon={`${isLoading ? 'cil-reload' : 'cil-chat-bubble'}`}
        className={`chat-button d-none d-lg-block ${isLoading ? 'loading' : ''} ${isOpen ? 'hide' : ''}`}
        onClick={open}
      />
    </>
  )
}

export default HelpChat
