import removeCurrencyFormat from '../../../commons/formatApiCurency';
import { checkEmptyObject, removeEmptyValue } from './functionsInvoiceAdapter';

const cobrInvoiceFormAdapter = (
  data: ICobrForm
): any => {
  const { fat, dup } = data || {}

  const fatFormatted = {
    nFat: fat?.nFat,
    vOrig: removeCurrencyFormat(fat?.vOrig),
    vDesc: removeCurrencyFormat(fat?.vDesc),
    vLiq: removeCurrencyFormat(fat?.vLiq),
  }
  const dupFormatted = dup?.map((item: any): any => {
    const vencDate = item?.dVenc && new Date(item?.dVenc).toISOString()
    const dupFormatted = {
      nDup: item?.nDup,
      dVenc: vencDate,
      vDup: removeCurrencyFormat(item?.vDup),
    }
    return dupFormatted && removeEmptyValue(dupFormatted)
  });

  return {
    ...(!checkEmptyObject(removeEmptyValue(fatFormatted))
      ? { fat: removeEmptyValue(fatFormatted) } : {}),
    ...(dupFormatted?.length > 0 ? { dup: dupFormatted } : {})
  }
}

export default cobrInvoiceFormAdapter
