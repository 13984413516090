import { useCallback, useContext } from 'react'

import { get, update } from 'commons/helpers/store'

import SessionStateContext from './SessionStateContext'

import logo from 'assets/images/logo-contabilone-color.svg'
import icon from 'assets/images/logo-contabilone-color-icon.svg'
import background from 'assets/images/background-home.jpg'

export default function useSettings () {
  const { data, setData } = useContext(SessionStateContext)

  const setSettings = useCallback(
    settings => setData({ ...data, settings }),
    [data, setData, update]
  )

  const { settings: { whitelabelSettings } } = data

  const isLocalhost = window.location.host.includes('localhost:3000')
  const isContabilone = whitelabelSettings?.name === 'Contabilone'
  const isWhitelabelContabilone = Boolean(localStorage.getItem('isWhitelabelContabilone'))
  const darkLogoPath = localStorage.getItem('darkLogoUrl') || whitelabelSettings?.darkLogoUrl
  
  const whitelabel = {
    themeColor: whitelabelSettings?.themeColor || 'STANDARD',
    name: whitelabelSettings?.name,
    linkTitle: whitelabelSettings?.linkTitle,
    linkUrl: whitelabelSettings?.linkUrl,
    darkLogoUrl: (isLocalhost || isContabilone) ? logo : darkLogoPath,
    darkIconUrl: (isLocalhost || isContabilone) ? icon : whitelabelSettings?.darkIconUrl,
    lightLogoUrl: (isLocalhost || isContabilone) ? logo : whitelabelSettings?.lightLogoUrl,
    lightIconUrl: (isLocalhost || isContabilone) ? icon : whitelabelSettings?.lightIconUrl,
    backgroundUrl: (isLocalhost || isContabilone) ? background : whitelabelSettings?.backgroundUrl,
    whitelabelId:  whitelabelSettings?.whitelabelId
  }

  return {
    settings: {
      ...data.settings,
      whitelabelSettings: whitelabel
    },
    isContabilone,
    isWhitelabelContabilone,
    isLocalhost,
    setSettings
  }
}
