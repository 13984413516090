export default function addZeroToNumber(number) {
  const countyCode = number.substring(0, 3)
  const companyOrderNumber = number.substring(3, 11)

  if (number?.length === 10) {
    const countyCode = number.substring(0, 2)
    const companyOrderNumber = number.substring(2, 11)

    return `${countyCode}0${companyOrderNumber}`
  }

  return `${countyCode}0${companyOrderNumber}`
}
