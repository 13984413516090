import parseApiDate from '../../commons/parseApiDate'

const invoiceListAdapter = (data: any): IInvoiceList => {
  const {
    ide,
    ...rest
  } = data

  return {
    ide: {
      dhEmi: ide.dhEmi && parseApiDate(ide.dhEmi),
      ...ide
    },
    ...rest
  }
}

export default invoiceListAdapter
