import config from '../../@config'
import RequestApi from '../../services/api'
import taxInfoAdapter from './adapters/taxInfoAdapter'

export interface IQueryParams {
  limit?: number
  page?: number
  q?: string
}

const endpoints = config.ENDPOINTS.list.taxinfo

export default (api: IAPI): ITaxInfoListsAPI => {
  async function getTaxInfo(
    name: TaxInfoNames,
    queryParams?: IQueryParams
  ): Promise<IList<ITaxInfo>> {
    const params = { page: 1, limit: 20, name, ...(queryParams || {}) }
    const cleanParams = Object.entries(params).filter(([_, value]) => value)
    return RequestApi.listAdapter<ITaxInfo>(
      await api.doRequest('get', endpoints.taxInfo, cleanParams) as IAPIResponseList,
      taxInfoAdapter
    )
  }

  return {
    getTaxInfo
  }
}
