import paginationDefaultValues from '@config/pagination'

const defaultValue: ISessionState = {
  settings: {
    sidebarShow: 'responsive',
    sidebar: 'minimize',
    theme: 'light',
    limit: paginationDefaultValues.limit,
    fullscreen: 'N',
    whitelabelSettings: {
      themeColor: 'STANDARD'
    }
  },
  user: null,
  session: null
}

export default defaultValue
