const paginationDefaultValues = {
  itemsPerPage: [10, 20, 30, 50],
  page: 1,
  limit: 10
}

const breadcrumbColumnAmount = 15

export default paginationDefaultValues
export {
  breadcrumbColumnAmount
}
