import parseApiDate from '../../commons/parseApiDate'

import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'

const serieAdapter = (data: any): ISerie => {
  const {
    mod, tpEmis,
    createdAt, updatedAt,
    subsidiary,
    endDate,
    startDate,
    ...rest
  } = data

  // @todo verify if API send "createdAt" and "updatedAt" by default
  return {
    mod,
    tpEmis,
    subsidiary: subsidiary && subsidiaryAdapter(subsidiary),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    startDate: parseApiDate(startDate),
    endDate: parseApiDate(endDate),
    ...rest
  }
}

export default serieAdapter
