import config from '../../@config'
import RequestApi from '../../services/api'
import resultsCenterList from '../lists/resultsCenterList'
import categoryAdapter from './categoryAdapter'
import categoryFormAdapter from './categoryFormAdapter'

const endpoints = config.ENDPOINTS.category

const Categories = (api: IAPI): ICategoryAPI => {
  function adapter(response: any): ICategory {
    return categoryAdapter(response)
  }

  async function getList(params: IPagedFilterParams): Promise<IList<ICategory>> {
    return RequestApi.listAdapter<ICategory>(
      (
        await api.doRequest('get', endpoints.category, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function getById(id: string): Promise<ICategory> {
    return RequestApi.itemAdapter<ICategory>(
      await api.doRequest('get', `${endpoints.category}/${id}`),
      (data: any) => adapter(data)
    )
  }

  async function create(data: ICategoryForm): Promise<string> {
    return (await api.doRequest('post', endpoints.category, data)).message
  }

  async function generate(companyId: string): Promise<string> {
    return (await api.doRequest('post', `${endpoints.category}/generate`, { companyId })).message
  }

  async function createBusinessAccount(id: string, data: ICategoryForm): Promise<string> {
    return (await api.doRequest('post', endpoints.referentials.replace('{ID}', id), categoryFormAdapter(data))).message
  }

  async function getBusinessAccountList(
    id: string,
    params: IPagedFilterParams
  ): Promise<IList<ICategory>> {
    return RequestApi.listAdapter<ICategory>(
      (
        await api.doRequest('get', `${endpoints.referentials.replace('{ID}', id)}`, params)
      ) as IAPIResponseList,
      (data: any) => adapter(data)
    )
  }

  async function editBusinessAccount(
    resultCenterId: string,
    businessAccountId: string,
    data: ICategoryForm
  ): Promise<string> {
    return (await api.doRequest('patch', `${endpoints.referentials.replace('{ID}', resultCenterId)}/${businessAccountId}`, categoryFormAdapter(data))).message
  }

  async function getBussinessAccountById(
    centerResultId: string,
    centerAccountId: string
  ): Promise<any> {
    return RequestApi.itemAdapter<ICategory>(
      await api.doRequest('get', `${endpoints.category}/${centerResultId}/result-codes/${centerAccountId}`),
      (data: any) => adapter(data)
    )
  }

  async function removeBusinessAccount(resultCenterId: string, id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.referentials.replace('{ID}', resultCenterId)}/${id}`)).message
  }

  async function update(id: string, data: ICategoryForm): Promise<ICategory> {
    return RequestApi.itemAdapter<ICategory>(
      await api.doRequest('patch', `${endpoints.category}/${id}`, categoryFormAdapter(data)),
      (data: any) => adapter(data)
    )
  }

  async function updateEnabled(id: string, enabled: boolean): Promise<ICategory> {
    return RequestApi.itemAdapter<ICategory>(
      await api.doRequest('patch', `${endpoints.category}/${id}`, { enabled }),
      (data: any) => adapter(data)
    )
  }

  async function remove(id: string): Promise<string> {
    return (await api.doRequest('remove', `${endpoints.category}/${id}`)).message
  }

  return {
    getList,
    getById,
    create,
    generate,
    getBusinessAccountList,
    createBusinessAccount,
    editBusinessAccount,
    update,
    updateEnabled,
    remove,
    getBussinessAccountById,
    removeBusinessAccount,
    lists: resultsCenterList(api)
  }
}

export default Categories
