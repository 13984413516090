import React from 'react'

import { CBreadcrumbItem, CLink } from '@coreui/react'

import Title from './Title'

const BreadcrumbItem: React.FC<IBreadcrumbItem> = ({
  className, to, onClick, children, ...props
}) => {
  return (
    <CBreadcrumbItem className={`${className || ''}`}>
      {to && <CLink onClick={onClick} to={to}><Title {...props} /></CLink>}
      {!to && <Title {...props} />}
      {children}
    </CBreadcrumbItem>
  )
}

export default BreadcrumbItem
