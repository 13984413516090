import parseApiDate from '../../commons/parseApiDate'

import subsidiaryAdapter from '../subsidiaries/subsidiaryAdapter'

const signatoryAdapter = (data: any): ISignatory => {
  const {
    createdAt, updatedAt,
    subsidiary,
    certificateExpiration,
    email,
    phone,
    ...rest
  } = data

  // @todo verify if API send "createdAt" and "updatedAt" by default
  return {
    subsidiary: subsidiary && subsidiaryAdapter(subsidiary),
    certificateExpiration: parseApiDate(certificateExpiration),
    createdAt: parseApiDate(createdAt),
    updatedAt: parseApiDate(updatedAt),
    email,
    phone,
    ...rest
  }
}

export default signatoryAdapter
