const config = {
  BASE_URL: process.env.REACT_APP_CONE_API,
  ENDPOINTS: {
    auth: {
      logout: '/auth/logout',
      login: '/auth/login',
      loginGoogle: '/auth/google',
      loginLinkedin: '/auth/linkedin',
      loginNexaasId: '/auth/nexaasid',
      register: '/auth/register',
      refreshToken: '/auth/refresh-token',
      recoverPassword: '/auth/recover-password',
      createPassword: '/auth/password',
      emailConfirmation: '/users/invite-resend',
    },
    invoices: {
      createinvoice: '/invoices/event/999998',
      invoices: '/invoices',
      authorization: '/invoices/authorization/{REQUEST_ID}',
      contingencyauthorization: '/invoices/contingency-authorization/{REQUEST_ID}',
      cancel: '/invoices/event/110111',
      substitutionCancel: '/invoices/event/110112',
      correction: '/invoices/event/110110',
      disablement: '/invoices/event/999999',
      manifestation: {
        aware: '/invoices/event/210210',
        realized: '/invoices/event/210200',
        unknown: '/invoices/event/210220',
        unrealized: '/invoices/event/210240'
      },
      importXML: '/invoices/send-invoice'
    },
    accountingCharts: {
      accountingCharts: '/accounting-charts',
      generate: '/accounting-charts/generate',
      referentialsAccounts: '/referential-accounting-codes'
    },
    accountingCodes: {
      accountingCodes: '/accounting-charts/{ACCOUNTING_CHART}/accounting-codes',
      accountingCode: '/accounting-charts/accounting-codes/{ACCOUNTING_CODE_ID}',
      accountingCodeReferentials: '/accounting-charts/accounting-codes/{ACCOUNTING_CODE_ID}',
      referentialAccountingCode: '/referential-accounting-codes',
    },
    accounts: {
      accounts: '/accounts',
      generateToken: '/accounts/generate-token',
      activateToken: '/accounts/{ID}/enable-token'
    },
    acquirers: {
      acquirers: '/acquirers',
    },
    businessAccounts: {
      businessAccounts: '/accounting-charts/{ACCOUNT_PLAN_ID}/accounting-codes',
      referenceAccount: '/referential-accounting-codes',
    },
    category: {
      category: '/categories',
      referentials: '/categories/{ID}/referentials',
    },
    configuration: {
      settings: '/settings',
      setting: '/settings/{ID}',
      definitions: '/settings-definitions',
      history: '/settings/history/{ID}',
      deleteGroup: '/settings/delete-bulk',
      editGroup: '/settings/update-bulk'
    },
    companies: {
      companies: '/companies',
      companyGetHeadquarter:
        '/companies/{COMPANY_ID}/get-headquarter-subsidiary',
    },
    devices: {
      devices: '/devices',
      terminals: '/terminals'
    },
    departments: {
      departments: '/departments'
    },
    diaries: {
      diaries: '/diaries',
      companyGetHeadquarter: '/companies/{COMPANY_ID}/get-headquarter-subsidiary',
      categories: '/categories-for-diary',
      accountingCodes: '/accounting-codes-for-diary',
      entries: {
        entries: '/diaries/{DIARY_ID}/entries',
        entry: '/diaries/entries'
      },
      items: {
        items: '/diaries/entries/{ENTRY_ID}/items',
        item: '/diaries/entries/items'
      },
      balance: {
        trialBalance: '/diaries/trial-balance',
        trialBalanceEntries: '/diaries/trial-balance/entries',
        trialBalanceCompanies: '/diaries/trial-balance/companies',
      },
      diary: {
        close: '/diaries/{DIARY_ID}/close',
        closeConfirmation: '/diaries/{DIARY_ID}/close-confirmation',
        reopen: '/diaries/{DIARY_ID}/reopen',
        reopenConfirmation: '/diaries/{DIARY_ID}/reopen-confirmation'
      },
      period: {
        close: '/diaries/{DIARY_ID}/period/close',
        closeConfirmation: '/diaries/{DIARY_ID}/period/close-confirmation',
        reopen: '/diaries/{DIARY_ID}/period/reopen',
        reopenConfirmation: '/diaries/{DIARY_ID}/period/reopen-confirmation'
      },
    },
    ruleBase: {
      ruleBase: '/rules-engine/rule-base'
    },

    list: {
      acquirersLists: {
        acquirers: '/acquirers/credentiators',
      },
      businessAccountsLists: {
        natures: '/account-plan/natures',
        tp_accounts: '/account-plan/tpaccounts',
        business_accounts: '/corporate-plan-accounts',
      },
      devicesLists: {
        factories: '/factories',
        cfeLayout: '/devices/cfelayouts',
      },
      fiscalLists: {
        legalNature: '/subsidiaries/legalnature',
        cnaes: '/subsidiaries/cnaes',
      },
      locationLists: {
        address: '/locations/address',
        states: '/locations/list/states',
        counties: '/locations/list/counties',
        countries: '/locations/countries',
        currencies: '/locations/countries/currency-codes',
      },
      participantsLists: {
        legalNature: '/subsidiaries/legalnature',
      },
      resultsCenterList: {
        natures: '/category/natures',
        types: '/category/types',
      },
      taxinfo: {
        taxInfo: '/tax-infos/name',
      },
    },
    participants: {
      participants: '/participants'
    },
    products: {
      products: '/products',
    },
    rulesEngine: {
      rulesEngine: '/rules-engine',
      list: '/rules-engine/list',
      attributeGroups: '/rules-engine/group',
      searchRule: '/rules-engine/search',
      subscription: '/rules-engine/add-subscription',
      removeSubscription: '/rules-engine/remove-subscription',
      evaluate: '/rules-engine/evaluate',
      history: '/rules-engine/history',
    },
    series: {
      series: '/series',
      contingency: '/series/offline-contingency',
      disabled: 'series/disabled',
    },
    subsidiaries: {
      subsidiaries: '/subsidiaries',
    },
    signatory: {
      list: '/signatories'
    },
    taxMenu: {
      list: '/paf',
      generate: '/paf/generate'
    },
    terminals: {
      terminals: '/terminals',
      history: '/history/terminals'
    },
    timelines: {
      timeline: '/timeline#TOBEDEFINE',
    },
    users: {
      users: '/users',
      invite: '/users/invite',
      updatePassword: '/users/password',
      updatePicture: '/users/picture',
      membership: '/memberships',
    },
    whitelabels: {
      whitelabels: '/whitelabels',
      contabilone: '/whitelabels/contabilone',
      themeSettings: '/whitelabels/settings',
      generateToken: '/whitelabels/generate-token',
      activateToken: '/whitelabels/{ID}/enable-token'
    },
    webhooks: {
      webhooks: '/webhooks',
      deliveries: '/webhooks/deliveries',
      resend: '/webhooks/deliveries/{ID}/redeliver'
    }
  },
}

export default config
